import styled from 'styled-components';

export const FormRow = styled.div<{ marginBottom?: string }>`
  margin-bottom: ${props => props.marginBottom || '20px'};

  .form-title {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .label {
    margin-right: 5px;
    font-weight: bold;
    opacity: 0.65;
  }

  .value {
    font-weight: bold;
  }

  .controls {
    display: flex;
    flex-direction: column;
    > * {
      margin-bottom: 10px;
      flex-grow: 1;
    }

    &.row,
    &.--inline {
      flex-direction: row;
      > * {
        flex-grow: 0;
      }
    }
  }

  &.has-error {
    .ant-select:not(.ant-select-disabled) .ant-select-selector {
      border-color: red;
    }
    .ant-input,
    .ant-upload.ant-upload-select-picture-card {
      border-color: red;
    }
  }
`;
