import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Tag, Tooltip, Skeleton } from 'antd';
import { FaTrademark, FaPlus, FaMoneyBillWaveAlt, FaToolbox, FaTag, FaBuilding } from 'react-icons/fa';
import { RowWrapper as AssetItemRowWrapper } from '../../components/AssetsSelect/styles';
import DashboardLayout from '../../layouts/Dashboard';
import { Page, Card } from '../../components';
import { useMst } from '../../store/RootStore';
import { AssetType } from '../../types';

const Assets: React.FC = () => {
  const store = useMst();

  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState<AssetType[]>([]);

  useEffect(() => {
    setLoading(true);
    store.userAccount.getAssets().then(response => {
      setAssets(response.data.data);
      setLoading(false);
    });
  }, []);

  return (
    <DashboardLayout>
      <Page>
        {loading ? (
          <Card>
            <div className="card-body">
              <Skeleton />
              <Skeleton />
            </div>
          </Card>
        ) : (
          <Card>
            <div className="card-body">
              <div className="card-title">Your Assets</div>
              {assets.map((asset, index) => {
                return (
                  <AssetItemRowWrapper
                    key={asset.assetId}
                    style={{
                      padding: 0,
                      borderRadius: 5,
                      borderBottom: '1px solid #ccc',
                      marginBottom: 10,
                    }}
                  >
                    <div className="d-flex " style={{ border: '1px solid rgba(0,0,0,0.1)', padding: 20 }}>
                      {/* <div className="image-wrapper" style={{ width: 100, marginRight: 20 }}></div> */}
                      <div style={{ flex: 1 }}>
                        <span className="asset-row title-row">
                          <div>
                            <Tooltip title="Asset No">
                              <span className="title">{asset.assetTaggingNo}</span>
                            </Tooltip>
                          </div>
                        </span>
                        <p className="asset-row">
                          <Tooltip title="Classification">
                            <span>
                              <FaToolbox className="icon" />
                              <span className="value">{asset.classification?.description}</span>
                            </span>
                          </Tooltip>
                        </p>
                        <p className="asset-row">
                          <Tooltip title="Description">
                            <span>
                              <FaTrademark className="icon" />
                              <span className="value">{asset.assetDescription}</span>
                            </span>
                          </Tooltip>
                          <Tooltip title="Brand">
                            <span className="value">{asset.brand}</span>
                          </Tooltip>
                        </p>
                        <p className="asset-row">
                          <Tooltip title="cost">
                            <span>
                              <FaMoneyBillWaveAlt className="icon" />
                              <span className="value">N/A</span>
                            </span>
                          </Tooltip>
                        </p>
                      </div>
                    </div>
                  </AssetItemRowWrapper>
                );
              })}
            </div>
          </Card>
        )}
      </Page>
    </DashboardLayout>
  );
};

export default Assets;
