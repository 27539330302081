import React from 'react';
import moment from 'moment';
import { Button, Table, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { EmptyMessage } from '../../../components';
import { AssetType, MaintenanceRequestMileageType } from '../../../types';
import config from '../../../config/config';

interface MileageTableProps {
  data: MaintenanceRequestMileageType[];
  readonly?: boolean;
  onCreateButtonClicked?: () => void;
  onEditMileageClicked?: (mileage: MaintenanceRequestMileageType) => void;
  onDeleteMileageClicked?: (mileage: MaintenanceRequestMileageType) => void;
}

const MileageTable: React.FC<MileageTableProps> = ({
  data,
  readonly,
  onCreateButtonClicked,
  onEditMileageClicked,
  onDeleteMileageClicked,
}) => {
  const columns = [
    {
      title: 'Asset',
      dataIndex: 'asset',
      render: (asset: AssetType | null) =>
        asset ? `${asset.assetTaggingNo} - ${asset.assetDescription}` : 'not specified',
    },
    {
      title: 'Type',
      dataIndex: 'mileageType',
      render: (value: number) => config.mileageTypeLabels[value],
    },
    {
      title: 'Mileage From',
      dataIndex: 'mileageFrom',
      render: (value: number) => value,
    },
    {
      title: 'Mileage To',
      dataIndex: 'mileageTo',
      render: (value: number) => value,
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render: function actions(id: string, timekeeping: MaintenanceRequestMileageType) {
        return (
          <>
            <Button
              onClick={() => {
                onEditMileageClicked && onEditMileageClicked(timekeeping);
              }}
              type="link"
            >
              Edit
            </Button>
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => onDeleteMileageClicked && onDeleteMileageClicked(timekeeping)}
            >
              <Button type="link" danger>
                Delete
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  if (readonly) {
    columns.pop();
  }

  return data.length ? (
    <Table columns={columns} dataSource={data} size="small"></Table>
  ) : (
    <EmptyMessage>
      <img
        className="image"
        src="https://staging-repairs-and-maintenance-public.s3-ap-southeast-1.amazonaws.com/static/images/empty-items.png"
      />
      <span className="text"> You have no mileage yet.</span>
      {onCreateButtonClicked && !readonly ? (
        <Button onClick={() => onCreateButtonClicked()} className="button" type="link">
          Create mileage
        </Button>
      ) : null}
    </EmptyMessage>
  );
};

MileageTable.propTypes = {
  data: PropTypes.any.isRequired,
  onCreateButtonClicked: PropTypes.func,
  onEditMileageClicked: PropTypes.func,
  onDeleteMileageClicked: PropTypes.func,
  readonly: PropTypes.bool,
};

MileageTable.defaultProps = {
  readonly: true,
};

export default MileageTable;
