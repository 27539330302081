import moment from 'moment';
import { types, flow, applySnapshot, Instance } from 'mobx-state-tree';
import { AxiosResponse } from 'axios';
import localforage from 'localforage';
import config from '../../config/config';

import { User, UserApproverMap } from './index';
import axios from '../../utils/axios';
import {
  StatusOKResponse,
  UserAccountType,
  NotificationType,
  AssetsResponse,
  LoginResponse,
  NotificationsResponse,
  MaintenanceRequestHeaderType,
  PropertyTransferRequest,
  PropertyTransferRequestsResponse,
  ProcessPropertyTransferRequestResponse,
  MaintenanceRequestsResponse,
  CreatePropertyTransferRequestResponse,
  CreatePropertyTransferRequestFormData,
  CreateMaintenanceRequestResponse,
  CreateMaintenanceRequestFormData,
  AdminCreateMaintenanceRequestFormData,
  ProcessMaintenanceRequestResponse,
  MaintenanceRequestTypesResponse,
  MaintenanceRequestTypeResponse,
  TimekeepingFormData,
  CreateUpdateTimekeepingResponse,
  MaterialUsedFormData,
  CreateUpdateMaterialUsedResponse,
  CreateUpdateMileageResponse,
  StoreApproveWorkFormData,
  MaintenanceRequestTypeComputationBreakdownResponse,
  MileageFormData,
  AssetRepairHistoryResponse,
  TotalRepairCostResponse,
  TotalRequestCountResponse,
  StoreRemarksFormData,
  StoreRemarksResponse,
  TimekeepingBreakdownResponse,
  AdminCreatePropertyTransferRequestFormData,
  PropertyTransferRequestsPaginatedResponse,
  MaintenanceRequestsPaginatedResponse,
  MaintenanceRequestTypeModelType,
  TechnicianRatingsResponse,
  MaintenanceRequestTypesPaginatedResponse,
} from './../../types';

export const Access = types.model({
  dashboard: types.boolean,
  approvals: types.model({
    any: types.boolean,
    propertyTransfer: types.boolean,
    maintenanceRequest: types.boolean,
    work: types.boolean,
    cost: types.boolean,
  }),
  admin: types.model({
    propertyTransfer: types.boolean,
    maintenanceRequest: types.boolean,
  }),
  propertyTransfer: types.boolean,
  maintenanceRequest: types.boolean,
  work: types.boolean,
  reports: types.optional(types.boolean, true),
});

export const UserAccount = types
  .model({
    token: types.maybe(types.string),
    user: types.maybe(User),
    userApproverMap: types.maybe(UserApproverMap),
    access: types.maybe(Access),
  })
  .actions(self => {
    const getUserAccountUsingToken = flow<AxiosResponse<LoginResponse>, any[]>(function*(
      token: string,
    ): Generator<Promise<AxiosResponse<LoginResponse>>> {
      const response = yield axios.get<LoginResponse>(`/api/profile/me`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response;
    });
    const save = (data: UserAccountType) => {
      self.token = data.token;
      self.user = data.user;
      if (data.userApproverMap) {
        self.userApproverMap = data.userApproverMap;
      }
      self.access = data.access;
    };
    return {
      save,

      logout() {
        self.token = '';
        self.user = undefined;
        localforage.removeItem(config.authTokenKey).then(() => console.log('logged out'));
      },

      getUserAccountUsingToken,

      getUserDetails: flow<AxiosResponse<any>, any[]>(function*(): Generator<Promise<AxiosResponse<any>>> {
        const response = yield getUserAccountUsingToken(self.token);
        save((response as AxiosResponse<LoginResponse>).data.data as UserAccountType);
        return response;
      }),

      getTotalPendingRequestsCount: flow<AxiosResponse<TotalRequestCountResponse>, any[]>(function*(): Generator<
        Promise<AxiosResponse<TotalRequestCountResponse>>
      > {
        try {
          const response = yield axios.get<TotalRequestCountResponse>(`/api/dashboard/pending_requests`, {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${self.token}`,
            },
          });
          return response;
        } catch (error) {
          console.log(error);
        }
      }),

      getTotalForApprovalCount: flow<AxiosResponse<TotalRequestCountResponse>, any[]>(function*(): Generator<
        Promise<AxiosResponse<TotalRequestCountResponse>>
      > {
        try {
          const response = yield axios.get<TotalRequestCountResponse>(`/api/dashboard/approvals`, {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${self.token}`,
            },
          });
          return response;
        } catch (error) {
          console.log(error);
        }
      }),

      getTotalRepairCosts: flow<AxiosResponse<TotalRepairCostResponse>, any[]>(function*(): Generator<
        Promise<AxiosResponse<TotalRepairCostResponse>>
      > {
        try {
          const response = yield axios.get<TotalRepairCostResponse>(`/api/dashboard/repair_costs`, {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${self.token}`,
            },
          });
          return response;
        } catch (error) {
          console.log(error);
        }
      }),

      getTechnicianRatings: flow<AxiosResponse<TechnicianRatingsResponse>, any[]>(function*(): Generator<
        Promise<AxiosResponse<TechnicianRatingsResponse>>
      > {
        try {
          const response = yield axios.get<TechnicianRatingsResponse>(`/api/dashboard/technician_ratings`, {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${self.token}`,
            },
          });
          return response;
        } catch (error) {
          console.log(error);
        }
      }),

      getNotifications: flow<AxiosResponse<NotificationsResponse>, any[]>(function*(): Generator<
        Promise<AxiosResponse<NotificationsResponse>>
      > {
        try {
          const response = yield axios.get<NotificationsResponse>(`/api/notifications`, {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${self.token}`,
            },
          });
          self.user?.setNotifications((response as AxiosResponse<NotificationsResponse>).data.data);
        } catch (error) {
          console.log(error);
        }
      }),

      getAssetRepairHistory: flow<AxiosResponse<AssetRepairHistoryResponse>, any[]>(function*(
        id: number,
      ): Generator<Promise<AxiosResponse<AssetRepairHistoryResponse>>> {
        try {
          const response = yield axios.get<AssetRepairHistoryResponse>(`/api/assets/${id}/repair_history`, {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${self.token}`,
            },
          });
          return response;
        } catch (error) {
          console.log(error);
        }
      }),

      getUnreadNotificationsCount: flow<AxiosResponse<{ data: number }>, any[]>(function*(): Generator<
        Promise<AxiosResponse<{ data: number }>>
      > {
        try {
          const response = yield axios.get<{ data: number }>(`/api/notifications/unread_count`, {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${self.token}`,
            },
          });
          self.user?.setUnreadNotificationsCount((response as AxiosResponse<{ data: number }>).data.data);
        } catch (error) {
          console.log(error);
        }
      }),

      markNotificationAsRead: flow<AxiosResponse<StatusOKResponse>, any[]>(function*(
        notification: NotificationType,
      ): Generator<Promise<AxiosResponse<StatusOKResponse>>> {
        try {
          yield axios.get<StatusOKResponse>(`/api/notifications/${notification.id}/read`, {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${self.token}`,
            },
          });
          self.user?.updateNotification({ ...notification, read: 1 });
        } catch (error) {
          console.log(error);
        }
      }),

      getAssets: flow<AxiosResponse<AssetsResponse>, any[]>(function*(): Generator<
        Promise<AxiosResponse<AssetsResponse>>
      > {
        try {
          let response = yield axios.get<AssetsResponse>(`/api/assets`, {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${self.token}`,
            },
          });
          response = response as AssetsResponse;

          return response;
        } catch (error) {
          console.log(error);
        }
      }),

      getUserAssets: flow<AxiosResponse<AssetsResponse>, any[]>(function*(
        userId: number,
      ): Generator<Promise<AxiosResponse<AssetsResponse>>> {
        try {
          let response = yield axios.get<AssetsResponse>(`/api/admin/users/${userId}/assets`, {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${self.token}`,
            },
          });
          response = response as AssetsResponse;
          return response;
        } catch (error) {
          console.log(error);
        }
      }),

      createPropertyTransferRequest: flow<AxiosResponse<CreatePropertyTransferRequestResponse>, any[]>(function*(
        data: CreatePropertyTransferRequestFormData,
      ): Generator<Promise<AxiosResponse<CreatePropertyTransferRequestResponse>>> {
        const postData = {
          ...data,
          dateIssued: moment(data.dateIssued).format('YYYY-MM-DD'),
        };
        try {
          const response = yield axios.post<CreatePropertyTransferRequestResponse>(
            `/api/assets/property_transfer_requests`,
            postData,
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${self.token}`,
              },
            },
          );
          return response;
        } catch (error) {
          console.log(error);
          return false;
        }
      }),

      adminCreatePropertyTransferRequest: flow<AxiosResponse<CreatePropertyTransferRequestResponse>, any[]>(function*(
        data: AdminCreatePropertyTransferRequestFormData,
      ): Generator<Promise<AxiosResponse<CreatePropertyTransferRequestResponse>>> {
        try {
          const postData = {
            ...data,
            dateIssued: moment(data.dateIssued).format('YYYY-MM-DD'),
          };
          const response = yield axios.post<CreatePropertyTransferRequestResponse>(
            `/api/admin/property_transfer_requests`,
            postData,
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${self.token}`,
              },
            },
          );
          return response;
        } catch (error) {
          console.log(error);
          return false;
        }
      }),

      getPropertyTransferRequest: flow<AxiosResponse<CreatePropertyTransferRequestResponse>, any[]>(function*(
        uuid: string,
      ): Generator<Promise<AxiosResponse<CreatePropertyTransferRequestResponse>>> {
        const response = yield axios.get<CreatePropertyTransferRequestResponse>(
          `/api/assets/property_transfer_requests/${uuid}`,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${self.token}`,
            },
          },
        );
        return response;
      }),

      getPropertyTransferRequestForApprovals: flow<AxiosResponse<PropertyTransferRequestsResponse>, any[]>(
        function*(): Generator<Promise<AxiosResponse<PropertyTransferRequestsResponse>>> {
          const response = yield axios.get<PropertyTransferRequestsResponse>(
            `/api/for_approvals/property_transfer_requests`,
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${self.token}`,
              },
            },
          );
          return response;
        },
      ),

      getMyPropertyTransferRequests: flow<AxiosResponse<PropertyTransferRequestsResponse>, any[]>(
        function*(): Generator<Promise<AxiosResponse<PropertyTransferRequestsResponse>>> {
          const response = yield axios.get<PropertyTransferRequestsResponse>(
            `/api/assets/property_transfer_requests/mine`,
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${self.token}`,
              },
            },
          );
          return response;
        },
      ),

      cancelPropertyTransferRequest: flow<AxiosResponse<CreatePropertyTransferRequestResponse>, any[]>(function*(
        request: PropertyTransferRequest,
      ): Generator<Promise<AxiosResponse<CreatePropertyTransferRequestResponse>>> {
        const response = yield axios.post<CreatePropertyTransferRequestResponse>(
          `/api/assets/property_transfer_requests/${request.uuid}/cancel`,
          {},
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${self.token}`,
            },
          },
        );
        return response;
      }),

      processPropertyTransferRequest: flow<AxiosResponse<ProcessPropertyTransferRequestResponse>, any[]>(function*(
        request: PropertyTransferRequest,
      ): Generator<Promise<AxiosResponse<ProcessPropertyTransferRequestResponse>>> {
        const response = yield axios.post<ProcessPropertyTransferRequestResponse>(
          `/api/assets/property_transfer_requests/${request.uuid}/process`,
          request,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${self.token}`,
            },
          },
        );
        return response;
      }),

      getAllMaintenanceRequests: flow<AxiosResponse<MaintenanceRequestsPaginatedResponse>, any[]>(function*(params: {
        page: number;
        user?: number;
        status?: number;
        referenceNumber?: string;
      }): Generator<Promise<AxiosResponse<MaintenanceRequestsPaginatedResponse>>> {
        const response = yield axios.get<MaintenanceRequestsPaginatedResponse>(`/api/admin/maintenance_requests`, {
          params,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${self.token}`,
          },
        });
        return response;
      }),

      getAllMaintenanceRequestTypes: flow<AxiosResponse<MaintenanceRequestTypesPaginatedResponse>, any[]>(
        function*(params: {
          page: number;
          user?: number;
          status?: number;
          referenceNumber?: string;
        }): Generator<Promise<AxiosResponse<MaintenanceRequestTypesPaginatedResponse>>> {
          const response = yield axios.get<MaintenanceRequestTypesPaginatedResponse>(
            `/api/admin/maintenance_request_types`,
            {
              params,
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${self.token}`,
              },
            },
          );
          return response;
        },
      ),

      getMyMaintenanceRequests: flow<AxiosResponse<MaintenanceRequestsResponse>, any[]>(function*(
        page: number,
      ): Generator<Promise<AxiosResponse<MaintenanceRequestsResponse>>> {
        const response = yield axios.get<MaintenanceRequestsResponse>(`/api/maintenance_requests/mine?page=${page}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${self.token}`,
          },
        });
        return response;
      }),

      getMaintenanceRequest: flow<AxiosResponse<CreateMaintenanceRequestResponse>, any[]>(function*(
        uuid: string,
      ): Generator<Promise<AxiosResponse<CreateMaintenanceRequestResponse>>> {
        const response = yield axios.get<CreateMaintenanceRequestResponse>(`/api/maintenance_requests/${uuid}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${self.token}`,
          },
        });
        return response;
      }),

      adminCreateMaintenanceRequest: flow<AxiosResponse<CreateMaintenanceRequestResponse>, any[]>(function*(
        data: AdminCreateMaintenanceRequestFormData,
      ): Generator<Promise<AxiosResponse<CreateMaintenanceRequestResponse>>> {
        const formData = new FormData();
        formData.append('store', data.store.toString());
        formData.append('requestorName', data.requestorName);
        data.types.forEach((type, index) => {
          formData.append(`types[${index}][type]`, type.type.toString());
          Object.keys(type.items).forEach(key => {
            const item = type.items[key];
            formData.append(`types[${index}][items][${key}][asset][id]`, item.asset.id.toString());
            formData.append(`types[${index}][items][${key}][description]`, item.description);
            formData.append(`types[${index}][items][${key}][classification]`, item.classification.toString());
            formData.append(`types[${index}][items][${key}][photo]`, item.photo as Blob);
          });
        });

        try {
          const response = yield axios.post<CreateMaintenanceRequestResponse>(
            `/api/admin/maintenance_requests`,
            formData,
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${self.token}`,
              },
            },
          );
          return response;
        } catch (error) {
          console.log(error);
          return false;
        }
      }),

      adminEditMaintenanceRequest: flow<AxiosResponse<StatusOKResponse>, any[]>(function*(
        referenceNumber: string,
      ): Generator<Promise<AxiosResponse<StatusOKResponse>>> {
        try {
          const response = yield axios.post<StatusOKResponse>(
            `/api/admin/maintenance_request_types/${referenceNumber}/edit`,
            {},
            {
              headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${self.token}`,
              },
            },
          );
          return response;
        } catch (error) {
          console.log(error);
          return false;
        }
      }),

      createMaintenanceRequest: flow<AxiosResponse<CreateMaintenanceRequestResponse>, any[]>(function*(
        data: CreateMaintenanceRequestFormData,
      ): Generator<Promise<AxiosResponse<CreateMaintenanceRequestResponse>>> {
        const formData = new FormData();
        formData.append('requestorName', data.requestorName);
        data.types.forEach((type, index) => {
          formData.append(`types[${index}][type]`, type.type.toString());
          // formData.append(`types[${index}][slaType]`, type.slaType.toString());
          Object.keys(type.items).forEach(key => {
            const item = type.items[key];
            formData.append(`types[${index}][items][${key}][asset][id]`, item.asset.id.toString());
            formData.append(`types[${index}][items][${key}][description]`, item.description);
            formData.append(`types[${index}][items][${key}][classification]`, item.classification.toString());
            formData.append(`types[${index}][items][${key}][photo]`, item.photo as Blob);
          });
        });

        try {
          const response = yield axios.post<CreateMaintenanceRequestResponse>(`/api/maintenance_requests`, formData, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${self.token}`,
            },
          });
          return response;
        } catch (error) {
          console.log(error);
          return false;
        }
      }),

      getMyMaintenanceRequestsForApproval: flow<AxiosResponse<MaintenanceRequestsResponse>, any[]>(function*(
        type: string,
      ): Generator<Promise<AxiosResponse<MaintenanceRequestsResponse>>> {
        const response = yield axios.get<MaintenanceRequestsResponse>(
          `/api/for_approvals/maintenance_requests/${type}`,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${self.token}`,
            },
          },
        );
        return response;
      }),

      arlProcessMaintenanceRequest: flow<AxiosResponse<ProcessMaintenanceRequestResponse>, any[]>(function*(
        request: MaintenanceRequestHeaderType,
      ): Generator<Promise<AxiosResponse<ProcessMaintenanceRequestResponse>>> {
        const response = yield axios.post<ProcessMaintenanceRequestResponse>(
          `/api/maintenance_requests/${request.uuid}/process`,
          request,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${self.token}`,
            },
          },
        );
        return response;
      }),

      rdoProcessMaintenanceRequest: flow<AxiosResponse<ProcessMaintenanceRequestResponse>, any[]>(function*(
        request: MaintenanceRequestHeaderType,
      ): Generator<Promise<AxiosResponse<ProcessMaintenanceRequestResponse>>> {
        const response = yield axios.post<ProcessMaintenanceRequestResponse>(
          `/api/maintenance_requests/${request.uuid}/process_by_rdo`,
          request,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${self.token}`,
            },
          },
        );
        return response;
      }),

      engineeringSupervisorProcessMaintenanceRequest: flow<AxiosResponse<ProcessMaintenanceRequestResponse>, any[]>(
        function*(
          uuid: string,
          type: MaintenanceRequestTypeModelType,
        ): Generator<Promise<AxiosResponse<ProcessMaintenanceRequestResponse>>> {
          const data = {
            types: [
              {
                ...type,
                dateOfService: type.dateOfService
                  ? moment(type.dateOfService).format('YYYY-MM-DD h:mm a')
                  : type.dateOfService,
              },
            ],
          };
          console.log(JSON.stringify(data));
          const response = yield axios.post<ProcessMaintenanceRequestResponse>(
            `/api/maintenance_requests/${uuid}/process_by_engineering_supervisor`,
            data,
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${self.token}`,
              },
            },
          );
          return response;
        },
      ),

      downloadMRForm: flow<AxiosResponse<any>, any[]>(function*(
        referenceNumber: string,
      ): Generator<Promise<AxiosResponse<any>>> {
        const response = yield axios.get(`/api/maintenance_requests/${referenceNumber}/download_form`, {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf',
            Authorization: `Bearer ${self.token}`,
          },
        });
        return response;
      }),

      submitStoreWorkRemarks: flow<AxiosResponse<StoreRemarksResponse>, any[]>(function*(
        referenceNumber: string,
        data: StoreRemarksFormData,
      ): Generator<Promise<AxiosResponse<StoreRemarksResponse>>> {
        const response = yield axios.post<StoreRemarksResponse>(
          `/api/work_management/${referenceNumber}/add_store_remarks`,
          data,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${self.token}`,
            },
          },
        );
        return response;
      }),

      getMRTypeWorkInProgress: flow<AxiosResponse<MaintenanceRequestTypesResponse>, any[]>(function*(): Generator<
        Promise<AxiosResponse<MaintenanceRequestTypesResponse>>
      > {
        const response = yield axios.get<any>(`/api/work_management/in_progress`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${self.token}`,
          },
        });
        return response;
      }),

      getMRTypeForEngineeringApproval: flow<AxiosResponse<MaintenanceRequestTypesResponse>, any[]>(
        function*(): Generator<Promise<AxiosResponse<MaintenanceRequestTypesResponse>>> {
          const response = yield axios.get<any>(`/api/for_approvals/costs`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${self.token}`,
            },
          });
          return response;
        },
      ),

      getMRTypeForFinalCosting: flow<AxiosResponse<MaintenanceRequestTypesResponse>, any[]>(function*(): Generator<
        Promise<AxiosResponse<MaintenanceRequestTypesResponse>>
      > {
        const response = yield axios.get<any>(`/api/work_management/for_final_costing`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${self.token}`,
          },
        });
        return response;
      }),

      getMaintenanceRequestTypeByReferenceNumber: flow<AxiosResponse<MaintenanceRequestTypeResponse>, any[]>(function*(
        id: string,
      ): Generator<Promise<AxiosResponse<MaintenanceRequestTypeResponse>>> {
        const response = yield axios.get<any>(`/api/maintenance_requests/type/${id}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${self.token}`,
          },
        });
        return response;
      }),

      createTimekeeping: flow<AxiosResponse<CreateUpdateTimekeepingResponse>, any[]>(function*(
        referenceNumber: string,
        data: TimekeepingFormData,
      ): Generator<Promise<AxiosResponse<CreateUpdateTimekeepingResponse>>> {
        const updatedData = {
          ...data,
          timeOutOffice: moment(data.timeOutOffice).format('YYYY-MM-DD h:mm a'),
          timeInStore: moment(data.timeInStore).format('YYYY-MM-DD h:mm a'),
          timeOutStore: moment(data.timeOutStore).format('YYYY-MM-DD h:mm a'),
          timeInOffice: moment(data.timeInOffice).format('YYYY-MM-DD h:mm a'),
        };
        const response = yield axios.post<any>(`/api/work_management/${referenceNumber}/timekeeping`, updatedData, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${self.token}`,
          },
        });
        return response;
      }),

      updateTimekeeping: flow<AxiosResponse<CreateUpdateTimekeepingResponse>, any[]>(function*(
        referenceNumber: string,
        id: number,
        data: TimekeepingFormData,
      ): Generator<Promise<AxiosResponse<CreateUpdateTimekeepingResponse>>> {
        const updatedData = {
          ...data,
          timeOutOffice: moment(data.timeOutOffice).format('YYYY-MM-DD h:mm a'),
          timeInStore: moment(data.timeInStore).format('YYYY-MM-DD h:mm a'),
          timeOutStore: moment(data.timeOutStore).format('YYYY-MM-DD h:mm a'),
          timeInOffice: moment(data.timeInOffice).format('YYYY-MM-DD h:mm a'),
        };
        const response = yield axios.put<any>(
          `/api/work_management/${referenceNumber}/timekeeping/${id}`,
          updatedData,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${self.token}`,
            },
          },
        );
        return response;
      }),

      deleteTimekeeping: flow<AxiosResponse<CreateUpdateTimekeepingResponse>, any[]>(function*(
        referenceNumber: string,
        id: number,
      ): Generator<Promise<AxiosResponse<CreateUpdateTimekeepingResponse>>> {
        const response = yield axios.delete<any>(`/api/work_management/${referenceNumber}/timekeeping/${id}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${self.token}`,
          },
        });
        return response;
      }),

      addMileage: flow<AxiosResponse<CreateUpdateMileageResponse>, any[]>(function*(
        referenceNumber: string,
        data: MileageFormData,
      ): Generator<Promise<AxiosResponse<CreateUpdateMileageResponse>>> {
        const response = yield axios.post<any>(`/api/work_management/${referenceNumber}/mileages`, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${self.token}`,
          },
        });
        return response;
      }),

      updateMileage: flow<AxiosResponse<CreateUpdateMileageResponse>, any[]>(function*(
        referenceNumber: string,
        id: number,
        data: MileageFormData,
      ): Generator<Promise<AxiosResponse<CreateUpdateMileageResponse>>> {
        const response = yield axios.put<any>(`/api/work_management/${referenceNumber}/mileages/${id}`, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${self.token}`,
          },
        });
        return response;
      }),

      deleteMileage: flow<AxiosResponse<CreateUpdateMileageResponse>, any[]>(function*(
        referenceNumber: string,
        id: number,
      ): Generator<Promise<AxiosResponse<CreateUpdateMileageResponse>>> {
        const response = yield axios.delete<any>(`/api/work_management/${referenceNumber}/mileages/${id}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${self.token}`,
          },
        });
        return response;
      }),

      addMaterialUsed: flow<AxiosResponse<CreateUpdateMaterialUsedResponse>, any[]>(function*(
        referenceNumber: string,
        data: MaterialUsedFormData,
      ): Generator<Promise<AxiosResponse<CreateUpdateMaterialUsedResponse>>> {
        const updatedData = {
          ...data,
        };
        const response = yield axios.post<any>(`/api/work_management/${referenceNumber}/materials`, updatedData, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${self.token}`,
          },
        });
        return response;
      }),

      updateMaterialUsed: flow<AxiosResponse<CreateUpdateMaterialUsedResponse>, any[]>(function*(
        referenceNumber: string,
        id: number,
        data: MaterialUsedFormData,
      ): Generator<Promise<AxiosResponse<CreateUpdateMaterialUsedResponse>>> {
        const updatedData = {
          ...data,
        };
        const response = yield axios.put<any>(`/api/work_management/${referenceNumber}/materials/${id}`, updatedData, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${self.token}`,
          },
        });
        return response;
      }),

      deleteMaterialUsed: flow<AxiosResponse<CreateUpdateMaterialUsedResponse>, any[]>(function*(
        referenceNumber: string,
        id: number,
      ): Generator<Promise<AxiosResponse<CreateUpdateMaterialUsedResponse>>> {
        const response = yield axios.delete<any>(`/api/work_management/${referenceNumber}/materials/${id}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${self.token}`,
          },
        });
        return response;
      }),

      submitWork: flow<AxiosResponse<{ data: { message: string } }>, any[]>(function*(
        referenceNumber: string,
      ): Generator<Promise<AxiosResponse<{ data: { message: string } }>>> {
        const response = yield axios.post<any>(
          `/api/work_management/${referenceNumber}/submit`,
          {},
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${self.token}`,
            },
          },
        );
        return response;
      }),

      submitCosting: flow<AxiosResponse<{ data: { message: string } }>, any[]>(function*(
        referenceNumber: string,
      ): Generator<Promise<AxiosResponse<{ data: { message: string } }>>> {
        const response = yield axios.post<any>(
          `/api/work_management/${referenceNumber}/submit_costing`,
          {},
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${self.token}`,
            },
          },
        );
        return response;
      }),

      getWorksForStoreApproval: flow<AxiosResponse<MaintenanceRequestTypesResponse>, any[]>(function*(): Generator<
        Promise<AxiosResponse<MaintenanceRequestTypesResponse>>
      > {
        const response = yield axios.get<any>(`/api/for_approvals/work_management`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${self.token}`,
          },
        });
        return response;
      }),

      storeApproveWork: flow<AxiosResponse<MaintenanceRequestTypeResponse>, any[]>(function*(
        referenceNumber: string,
        data: StoreApproveWorkFormData,
      ): Generator<Promise<AxiosResponse<MaintenanceRequestTypeResponse>>> {
        const response = yield axios.post<any>(`/api/for_approvals/work_management/${referenceNumber}/approve`, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${self.token}`,
          },
        });
        return response;
      }),

      storeRejectWork: flow<AxiosResponse<MaintenanceRequestTypeResponse>, any[]>(function*(
        referenceNumber: string,
        data: StoreApproveWorkFormData,
      ): Generator<Promise<AxiosResponse<MaintenanceRequestTypeResponse>>> {
        const response = yield axios.post<any>(`/api/for_approvals/work_management/${referenceNumber}/reject`, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${self.token}`,
          },
        });
        return response;
      }),

      adminApproveCost: flow<AxiosResponse<MaintenanceRequestTypeResponse>, any[]>(function*(
        referenceNumber: string,
        thirdPartyDetails: { cost?: number; workDone?: string } = {},
      ): Generator<Promise<AxiosResponse<MaintenanceRequestTypeResponse>>> {
        const response = yield axios.post<any>(
          `/api/for_approvals/costs/${referenceNumber}/approve`,
          thirdPartyDetails,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${self.token}`,
            },
          },
        );
        return response;
      }),

      getTimekeepingBreakdown: flow<AxiosResponse<TimekeepingBreakdownResponse>, any[]>(function*(
        referenceNumber: string,
      ): Generator<Promise<AxiosResponse<TimekeepingBreakdownResponse>>> {
        const response = yield axios.get<any>(
          `/api/maintenance_requests/type/${referenceNumber}/timekeeping_breakdown`,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${self.token}`,
            },
          },
        );
        return response;
      }),

      getMRTypeComputationBreakdown: flow<AxiosResponse<MaintenanceRequestTypeComputationBreakdownResponse>, any[]>(
        function*(
          referenceNumber: string,
        ): Generator<Promise<AxiosResponse<MaintenanceRequestTypeComputationBreakdownResponse>>> {
          const response = yield axios.get<any>(`/api/maintenance_requests/type/${referenceNumber}/computation`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${self.token}`,
            },
          });
          return response;
        },
      ),

      getAllPropertyTransferRequests: flow<AxiosResponse<PropertyTransferRequestsPaginatedResponse>, any[]>(
        function*(params: {
          page: number;
          user?: number;
          status?: number;
        }): Generator<Promise<AxiosResponse<PropertyTransferRequestsPaginatedResponse>>> {
          const response = yield axios.get<PropertyTransferRequestsPaginatedResponse>(
            `/api/admin/property_transfer_requests`,
            {
              params,
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${self.token}`,
              },
            },
          );
          return response;
        },
      ),

      cancelMaintenanceRequest: flow<AxiosResponse<CreateMaintenanceRequestResponse>, any[]>(function*(
        maintenanceRequest: MaintenanceRequestHeaderType,
      ): Generator<Promise<AxiosResponse<CreateMaintenanceRequestResponse>>> {
        try {
          const response = yield axios.post<CreateMaintenanceRequestResponse>(
            `/api/maintenance_requests/${maintenanceRequest.uuid}/cancel`,
            {},
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${self.token}`,
              },
            },
          );
          return response;
        } catch (error) {
          console.log(error);
        }
      }),

      changeAsset: flow<AxiosResponse<MaintenanceRequestTypeResponse>, any[]>(function*(
        referenceNumber: string,
        currentAssetId: number,
        newAssetId: number,
      ): Generator<Promise<AxiosResponse<MaintenanceRequestTypeResponse>>> {
        try {
          const response = yield axios.post<MaintenanceRequestTypeResponse>(
            `/api/work_management/${referenceNumber}/change_asset`,
            {
              currentAssetId,
              newAssetId,
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${self.token}`,
              },
            },
          );
          return response;
        } catch (error) {
          console.log(error);
        }
      }),

      exportMaintenanceRequests: flow<AxiosResponse<any>, any[]>(function*(
        userId: number,
        status: number,
      ): Generator<Promise<AxiosResponse<any>>> {
        try {
          const response = yield axios.get<any>(`/api/admin/maintenance_requests/export`, {
            responseType: 'arraybuffer',
            params: {
              store: userId,
              status,
            },
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/vnd.ms-excel',
              Authorization: `Bearer ${self.token}`,
            },
          });
          return response;
        } catch (error) {
          console.log(error);
        }
      }),
    };
  });
