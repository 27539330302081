import React, { useState, useEffect, useCallback } from 'react';
import * as yup from 'yup';
import moment from 'moment';
import { observer } from 'mobx-react';

import { PlusCircleFilled } from '@ant-design/icons';
import { FaTrademark, FaPlus, FaMoneyBillWaveAlt, FaToolbox, FaTag, FaBuilding } from 'react-icons/fa';
import { DatePicker, Skeleton, Table, Modal, Input, Select, Button, notification, Divider, Tooltip } from 'antd';
import { Controller, useForm } from 'react-hook-form';

import config from '../../../config/config';
import { useMst } from '../../../store/RootStore';
import DashboardLayout from '../../../layouts/Dashboard';
import { TableWrapper, ModalWrapper, Wrapper } from '../../PropertyTransfer/styles';
import {
  UserType,
  AssetType,
  CreatePropertyTransferRequestFormData,
  AdminCreatePropertyTransferRequestFormData,
} from '../../../types';
import {
  Section,
  AssetsSelect,
  HorizontalLine,
  FormSection,
  FormRow,
  Page,
  Card,
  EmptyMessage,
} from '../../../components';
import { RowWrapper as AssetItemRowWrapper } from '../../../components/AssetsSelect/styles';
import { useHistory } from 'react-router-dom';
import { PropertyTransferItemTableColumns } from '../../PropertyTransfer/common';

const schema = yup.object().shape({
  type: yup.string().required('Type is required'),
  sender: yup.number().required('Sender is required'),
  recipient: yup.number().required('Recipient is required'),
  // ptafNumber: yup.string().required('PTAF Number is required'),
  dateIssued: yup.string().required('Date issued is required'),
  items: yup.object().test('items', 'Please select item/s to transfer', value => {
    return value && Object.keys(value).length > 0;
  }),
});

const CreatePropertyTransferRequest: React.FC = observer(() => {
  const store = useMst();
  const history = useHistory();
  const references = store.references;

  const { control, handleSubmit, errors } = useForm<AdminCreatePropertyTransferRequestFormData>({
    validationSchema: schema,
  });

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [assetsModalVisible, setAssetsModalVisible] = useState(false);
  const [reviewModalVisible, setReviewModalVisible] = useState(false);
  const [assets, setAssets] = useState<AssetType[]>([]);

  const [data, setData] = useState<AdminCreatePropertyTransferRequestFormData | null>(null);

  const fetchAssets = async (userId: number) => {
    // setDataLoading(true);
    const response = await store.userAccount.getUserAssets(userId);
    const assets = response.data.data;
    setAssets(assets);
    // setDataLoading(false);
  };

  const onDisplayReview = (values: AdminCreatePropertyTransferRequestFormData) => {
    setReviewModalVisible(true);
    setData(values);
  };

  const onSubmit = async () => {
    setLoading(true);
    const response = await store.userAccount.adminCreatePropertyTransferRequest(data);
    if (response) {
      setReviewModalVisible(false);
      history.push(`/property_transfers/${response.data.data.uuid}`);
      notification['success']({
        message: 'Create Property Transfer',
        description: 'You have succesfully created an asset transfer.',
      });
    } else {
      notification['error']({
        message: 'Create Property Transfer',
        description: 'An error occurred while saving your property transfer request. Please try again.',
      });
    }
    setLoading(false);
  };

  const controlValues = control.getValues();
  const items = controlValues['items'];
  const sender = controlValues['sender'];

  return (
    <DashboardLayout>
      <Page>
        <Wrapper>
          {dataLoading ? (
            <Card>
              <div className="card-body">
                <Skeleton />
                <Skeleton />
              </div>
            </Card>
          ) : (
            <form onSubmit={handleSubmit(onDisplayReview)}>
              <Card>
                <div className="card-body">
                  <p className="card-title">Property Transfer Application Form</p>
                  <Section className="mb-3">
                    <FormSection>
                      <FormRow className={errors['sender'] ? 'has-error' : ''}>
                        <label className="form-title">Sender:</label>
                        <Controller
                          name="sender"
                          control={control}
                          onChange={data => {
                            control.setValue('items', {});
                            fetchAssets(data[0]).then(() => {
                              console.log('Load new assets');
                            });
                            return data;
                          }}
                          as={
                            <Select
                              showSearch
                              optionFilterProp="children"
                              style={{ minWidth: 300 }}
                              placeholder="Select sender"
                            >
                              <Select.OptGroup label="Users">
                                {references.normalUsers.map((user: UserType) => (
                                  <Select.Option key={user.id} value={user.id}>
                                    {user.fullName}({user.email})
                                  </Select.Option>
                                ))}
                              </Select.OptGroup>
                              <Select.OptGroup label="Engineering">
                                {references.engineeringUsers.map((user: UserType) => (
                                  <Select.Option key={user.id} value={user.id}>
                                    {user.fullName}({user.email})
                                  </Select.Option>
                                ))}
                              </Select.OptGroup>
                              <Select.OptGroup label="Stores">
                                {references.storeUsers.map((user: UserType) => (
                                  <Select.Option key={user.id} value={user.id}>
                                    {user.fullName}({user.email})
                                  </Select.Option>
                                ))}
                              </Select.OptGroup>
                            </Select>
                          }
                        />
                        {errors['sender'] ? <span className="error-message">{errors['sender'].message}</span> : null}
                      </FormRow>
                      <FormRow className={errors['type'] ? 'has-error' : ''}>
                        <label className="form-title">Transfer type:</label>
                        <Controller
                          name="type"
                          control={control}
                          as={
                            <Select
                              showSearch
                              optionFilterProp="children"
                              style={{ minWidth: 300 }}
                              placeholder="Select type"
                            >
                              {Object.keys(config.ptafTypes).map((key: string) => (
                                <Select.Option key={key} value={key}>
                                  {(config.ptafTypes as any)[key]}
                                </Select.Option>
                              ))}
                            </Select>
                          }
                        />
                        {errors['type'] ? <span className="error-message">{errors['type'].message}</span> : null}
                      </FormRow>
                      <FormRow className={errors['recipient'] ? 'has-error' : ''}>
                        <label className="form-title">Recipient:</label>
                        <Controller
                          name="recipient"
                          control={control}
                          as={
                            <Select
                              showSearch
                              optionFilterProp="children"
                              style={{ minWidth: 300 }}
                              placeholder="Select user/store"
                            >
                              <Select.OptGroup label="Users">
                                {references.normalUsers.map((user: UserType) => (
                                  <Select.Option key={user.id} value={user.id}>
                                    {user.fullName}({user.email})
                                  </Select.Option>
                                ))}
                              </Select.OptGroup>
                              <Select.OptGroup label="Engineering">
                                {references.engineeringUsers.map((user: UserType) => (
                                  <Select.Option key={user.id} value={user.id}>
                                    {user.fullName}({user.email})
                                  </Select.Option>
                                ))}
                              </Select.OptGroup>
                              <Select.OptGroup label="Stores">
                                {references.storeUsers.map((user: UserType) => (
                                  <Select.Option key={user.id} value={user.id}>
                                    {user.fullName}({user.email})
                                  </Select.Option>
                                ))}
                              </Select.OptGroup>
                            </Select>
                          }
                        />
                        {errors['recipient'] ? (
                          <span className="error-message">{errors['recipient'].message}</span>
                        ) : null}
                      </FormRow>
                      {/* <FormRow className={errors['ptafNumber'] ? 'has-error' : ''}>
                        <label className="form-title">PTAF Number:</label>
                        <Controller
                          name="ptafNumber"
                          control={control}
                          as={<Input placeholder="PTAF Number" style={{ width: 300 }} />}
                        />
                        {errors['ptafNumber'] ? (
                          <span className="error-message">{errors['ptafNumber'].message}</span>
                        ) : null}
                      </FormRow> */}
                      <FormRow className={errors['dateIssued'] ? 'has-error' : ''}>
                        <label className="form-title">Date Issued:</label>
                        <Controller
                          name="dateIssued"
                          control={control}
                          as={<DatePicker format="YYYY-MM-DD" style={{ width: 300 }} />}
                        />
                        {errors['dateIssued'] ? <span className="error-message">Date issued is required</span> : null}
                      </FormRow>
                    </FormSection>
                  </Section>
                </div>
              </Card>
              <Card>
                <div className="card-body">
                  <div className="card-title d-flex justify-content-between">
                    <span>Assets to transfer:</span>
                    {items && Object.keys(items).length ? (
                      <Button type="link" onClick={() => setAssetsModalVisible(true)}>
                        <PlusCircleFilled />
                        Add asset
                      </Button>
                    ) : null}
                  </div>
                  <Section>
                    <div className="items-section">
                      <div className="items-list">
                        {items && Object.keys(items).length ? (
                          <>
                            {Object.keys(items).map(key => {
                              const asset = items[key];
                              return (
                                <AssetItemRowWrapper key={key}>
                                  <span className="asset-row title-row">
                                    <Tooltip title="Asset No">
                                      <span className="title">{asset.assetTaggingNo}</span>
                                    </Tooltip>

                                    <Button
                                      type="primary"
                                      danger
                                      size="small"
                                      onClick={() => {
                                        const tmp = { ...items };
                                        delete tmp[asset.assetId];
                                        control.setValue('items', { ...tmp });
                                        forceUpdate();
                                      }}
                                    >
                                      Remove
                                    </Button>
                                  </span>
                                  <p className="asset-row">
                                    <Tooltip title="Type/Asset Name">
                                      <span>
                                        <FaToolbox className="icon" />
                                        <span className="value">{asset.classification.description}</span>
                                      </span>
                                    </Tooltip>
                                  </p>
                                  <p className="asset-row">
                                    <Tooltip title="Description/Brand Name">
                                      <span>
                                        <FaTrademark className="icon" />
                                        <span className="value">{asset.assetDescription}</span>
                                      </span>
                                    </Tooltip>
                                    <Tooltip title="Brand">
                                      <span className="value">{asset.brand}</span>
                                    </Tooltip>
                                  </p>
                                  <p className="asset-row">
                                    <Tooltip title="cost">
                                      <span>
                                        <FaMoneyBillWaveAlt className="icon" />
                                        <span className="value">N/A</span>
                                      </span>
                                    </Tooltip>
                                  </p>
                                </AssetItemRowWrapper>
                              );
                            })}
                          </>
                        ) : (
                          <EmptyMessage>
                            <img
                              className="image"
                              src="https://staging-repairs-and-maintenance-public.s3-ap-southeast-1.amazonaws.com/static/images/empty-items.png"
                            />
                            <span className="text"> You have no items selected yet.</span>
                            <Button onClick={() => setAssetsModalVisible(true)} className="button" type="link">
                              Select assets
                            </Button>
                          </EmptyMessage>
                        )}
                        {errors['items'] ? (
                          <span className="error-message mt-3">Please select assets to transfer</span>
                        ) : null}
                      </div>
                    </div>
                  </Section>

                  <Controller
                    name="items"
                    control={control}
                    onChange={data => {
                      setAssetsModalVisible(false);
                      const assets = data[0];
                      return { ...items, ...assets };
                    }}
                    as={
                      <AssetsSelect
                        assets={items ? assets.filter(asset => !Object.keys(items).includes(asset.assetId)) : assets}
                        visible={assetsModalVisible}
                        onCancel={() => setAssetsModalVisible(false)}
                      />
                    }
                  />
                </div>
                <div className="card-footer d-flex justify-content-end">
                  <Button htmlType="submit" type="primary" className="">
                    Create request
                  </Button>
                </div>
              </Card>
            </form>
          )}
        </Wrapper>
      </Page>

      <Modal
        title="Review assets to transfer"
        footer={null}
        width={800}
        onCancel={() => setReviewModalVisible(false)}
        visible={reviewModalVisible}
      >
        {data ? (
          <ModalWrapper>
            <p className="">
              <label className="label">Type:&nbsp;</label>
              <span className="value">{data.type}</span>
            </p>
            <p className="">
              <label className="label">Transfer to:&nbsp;</label>
              <span className="value">
                {store.references.users.filter((user: UserType) => user.id === data.recipient)[0].fullName}
              </span>
            </p>
            <TableWrapper>
              <p className="label font-weight-bold">Assets to transfer:</p>
              <Table
                size="small"
                dataSource={Object.keys(data.items).map((key: string) => data.items[key])}
                columns={PropertyTransferItemTableColumns}
                pagination={false}
              />
            </TableWrapper>

            <Button loading={loading} type="primary" className="mt-5" onClick={() => onSubmit()}>
              Submit Request
            </Button>
          </ModalWrapper>
        ) : null}
      </Modal>
    </DashboardLayout>
  );
});

export default CreatePropertyTransferRequest;
