import styled from 'styled-components';

export const Wrapper = styled.div`
  .items-section {
    .items-list {
      max-height: 600px;
      overflow-y: scroll;
    }
    .item-count {
      color: #1890ff;
      font-size: 24px;
    }
  }
  .search-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
      font-size: 20px;
      margin: 0px 10px;
    }
  }

  .action-section {
  }
`;

export const ModalWrapper = styled.div`
  /* padding: 10px; */

  .title {
    font-size: 20px;
  }

  .value {
    font-weight: bold;
  }

  .table {
    width: 100%;
    padding: 10px;
    font-size: 12px;
    border-collapse: unset;
    border: 1px solid #ccc;

    tbody tr {
      margin-bottom: 10px;
    }
  }
`;

export const TableWrapper = styled.div`
  margin-top: 10px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.05);
  font-size: 12px;

  td,
  th {
    font-size: 12px;
  }
`;

export const ListWrapper = styled.div`
  margin-top: 10px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.05);
  font-size: 12px;

  .item {
    background: white;
    padding: 15px;
  }
`;
