import styled from 'styled-components';

export const Wrapper = styled.div`
  .title {
    font-size: 24px;
    margin-bottom: 30px;
  }
  .list-item {
    padding-bottom: 30px;
    margin-bottom: 1px solid #ccc;
  }
`;
