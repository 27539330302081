import * as JsSearch from 'js-search';

export const Search = (id: string, columns: string[]) => {
  const search = new JsSearch.Search(id);
  columns.forEach(column => {
    search.addIndex(column);
  });
  console.log(search);

  return search;
};
