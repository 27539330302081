import React, { useState, useEffect } from 'react';
import { Select, Table, Skeleton, notification, Divider, Button } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { TiHome, TiInputChecked, TiArrowForward, TiSpanner } from 'react-icons/ti';

import DashboardLayout from '../../../layouts/Dashboard';
import { Page, Card, HorizontalLine } from '../../../components';
import { UserType, PropertyTransferRequest, PropertyTransferRequestItem } from '../../../types';
import { useMst } from '../../../store/RootStore';
import config from '../../../config/config';
import { PaginationProps, PaginationConfig } from 'antd/lib/pagination';
import { SorterResult } from 'antd/lib/table/interface';

const columns = [
  {
    title: 'PTAF Number',
    dataIndex: 'ptafNumber',
    render: (ptafNumber: string) => ptafNumber,
  },
  {
    title: 'Recipient',
    dataIndex: 'recipient',
    render: (user: UserType) => `${user.firstName} ${user.lastName}`,
  },
  {
    title: 'Transfer type',
    dataIndex: 'transfertype',
    render: (type: string) => config.ptafTypes[type],
  },
  {
    title: 'Date applied',
    dataIndex: 'dateApplied',
    render: (date: string) => moment(date).format('YYYY-MM-DD'),
  },
  {
    title: 'Date issued',
    dataIndex: 'dateIssued',
    render: (date: string) => moment(date).format('YYYY-MM-DD'),
  },
  {
    title: 'No. of items',
    dataIndex: 'items',
    render: (value: PropertyTransferRequestItem[]) => value.length,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (status: number) => config.propertyTransferRequestStatusReference[status].label,
  },
  {
    title: 'Actions',
    dataIndex: 'uuid',
    render: (value: string) => <Link to={`/property_transfers/${value}`}>View</Link>, // eslint-disable-line
  },
];

const Wrapper = styled.div`
  .filters {
  }
`;

const AllProperyTransferRequests: React.FC = observer(() => {
  const store = useMst();
  const references = store.references;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<PropertyTransferRequest[]>([]);
  const [pagination, setPagination] = useState<PaginationProps>({});
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);

  const fetchData = async (page = 1) => {
    try {
      setLoading(true);
      const params = {
        page,
        user: selectedUser,
        status: selectedStatus,
      };
      const response = await store.userAccount.getAllPropertyTransferRequests(params);
      setData(response.data.data);

      setPagination({
        pageSize: config.pageSize,
        current: page,
        total: response.data.meta.total,
      });
      setLoading(false);
    } catch (err) {
      notification['error']({
        message: 'Property Transfer Requests',
        description: 'An error occurred while fetching your data. Please try again later.',
      });
    }
  };

  useEffect(() => {
    fetchData().then((res: any) => {
      console.log(res);
    });
  }, []);

  const filterTable = async () => {
    await fetchData(pagination.current);
  };

  const handleChange = async (pagination: PaginationConfig) => {
    await fetchData(pagination.current);
  };

  return (
    <DashboardLayout>
      <Page>
        <Card style={{ zIndex: 2 }}>
          <div className="card-body">
            <Wrapper>
              <p className="card-title">All Property Transfer Requests</p>
              {loading ? (
                <Skeleton />
              ) : (
                <div>
                  <div className="filters">
                    <p className="title">Filters:</p>
                    <Select
                      showSearch
                      allowClear={true}
                      optionFilterProp="children"
                      style={{ minWidth: 300 }}
                      className="mr-2"
                      value={selectedUser ?? undefined}
                      onChange={(value: string) => setSelectedUser(value)}
                      placeholder="Filter by user"
                    >
                      <Select.OptGroup label="Users">
                        {references.normalUsers.map((user: UserType) => (
                          <Select.Option key={user.id} value={user.id}>
                            {user.fullName}({user.email})
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                      <Select.OptGroup label="Engineering">
                        {references.engineeringUsers.map((user: UserType) => (
                          <Select.Option key={user.id} value={user.id}>
                            {user.fullName}({user.email})
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                      <Select.OptGroup label="Stores">
                        {references.storeUsers.map((user: UserType) => (
                          <Select.Option key={user.id} value={user.id}>
                            {user.fullName}({user.email})
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                    </Select>
                    <Select
                      allowClear={true}
                      className="mr-3"
                      style={{ width: 200 }}
                      placeholder="Filter by Status"
                      value={selectedStatus ?? undefined}
                      onChange={(value: string) => setSelectedStatus(value)}
                    >
                      <Select.Option value="0">Pending</Select.Option>
                      <Select.Option value="10">Approved by ARL</Select.Option>
                      <Select.Option value="15">Approved by RDO</Select.Option>
                      <Select.Option value="20">Approved</Select.Option>
                      <Select.Option value="-1">Rejected</Select.Option>
                    </Select>
                    <Button onClick={filterTable} type="primary">
                      Filter table
                    </Button>
                  </div>
                  <Divider />
                  <Table
                    columns={columns}
                    pagination={pagination}
                    dataSource={data}
                    size="small"
                    onChange={handleChange}
                  ></Table>
                </div>
              )}
            </Wrapper>
          </div>
        </Card>
      </Page>
    </DashboardLayout>
  );
});

export default AllProperyTransferRequests;
