import React, { useState, useEffect } from 'react';
import { List, Modal, Tabs } from 'antd';
import PropTypes from 'prop-types';
import accounting from 'accounting-js';

import { useMst } from '../../../store/RootStore';
import { TimekeepingBreakdown } from '../../../types';

export interface TimekeepingBreakdownModalProps {
  visible: boolean;
  referenceNumber?: string;
  onCancel?: () => void;
}

const TimekeepingBreakdownModal: React.FC<TimekeepingBreakdownModalProps> = ({
  visible,
  referenceNumber,
  onCancel,
}) => {
  const store = useMst();

  const [breakdown, setBreakdown] = useState<TimekeepingBreakdown | null>(null);
  useEffect(() => {
    if (!visible) {
      return;
    }
    store.userAccount.getTimekeepingBreakdown(referenceNumber).then(response => {
      setBreakdown(response.data.data);
    });
  }, [visible]);

  return (
    <Modal footer={null} title="Timekeeping" onCancel={() => onCancel && onCancel()} visible={visible}>
      {breakdown ? (
        <Tabs>
          {Object.keys(breakdown.perTechnician).map(key => {
            const technician = breakdown.perTechnician[key];
            const name = `${technician.user.firstName} ${technician.user.lastName}`;
            return (
              <Tabs.TabPane tab={name} key={key}>
                <List size="small">
                  <List.Item>
                    <b>Normal Working Hours</b>
                  </List.Item>
                  <List.Item>
                    <span>Count:</span>
                    <span>
                      {accounting.formatNumber(technician.computation.normal.count / 60, 2)} hours (
                      {technician.computation.normal.count} mins)
                    </span>
                  </List.Item>
                  <List.Item>
                    <span>Cost:</span>
                    <span>{accounting.formatMoney(technician.computation.normal.cost)}</span>
                  </List.Item>
                  <List.Item>
                    <b>Overtime</b>
                  </List.Item>
                  <List.Item>
                    <span>Count:</span>
                    <span>
                      {accounting.formatNumber(technician.computation.ot.count / 60, 2)} hours (
                      {technician.computation.ot.count} mins)
                    </span>
                  </List.Item>
                  <List.Item>
                    <span>Cost:</span>
                    <span>{accounting.formatMoney(technician.computation.ot.cost)}</span>
                  </List.Item>
                  <List.Item>
                    <b>Holiday</b>
                  </List.Item>
                  <List.Item>
                    <span>Count:</span>
                    <span>
                      {accounting.formatNumber(technician.computation.holiday.count / 60, 2)} hours (
                      {technician.computation.holiday.count} mins)
                    </span>
                  </List.Item>
                  <List.Item>
                    <span>Cost:</span>
                    <span>{accounting.formatMoney(technician.computation.holiday.cost)}</span>
                  </List.Item>
                </List>
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      ) : null}
    </Modal>
  );
};

TimekeepingBreakdownModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  referenceNumber: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
};

export default TimekeepingBreakdownModal;
