import { types } from 'mobx-state-tree';

export const GlobalUI = types
  .model({
    menuDrawerVisible: types.optional(types.boolean, false),
  })
  .actions(self => ({
    setMenuDrawerVisible(value: boolean) {
      self.menuDrawerVisible = value;
    },
  }));
