import React, { useState, useEffect } from 'react';
import { Table, Skeleton, Select, notification, Button } from 'antd';
import styled from 'styled-components';
import { DatePicker, Space } from 'antd';

import DashboardLayout from '../../layouts/Dashboard';
import { Page, Card } from '../../components';
import Axios from 'axios';
import { useMst } from '../../store/RootStore';

const { RangePicker } = DatePicker;

const columns = [
  {
    title: 'PTAF No',
    dataIndex: 'ptaf_no',
    render: (value: string) => value,
  },
  {
    title: 'Date',
    dataIndex: 'date',
    render: (date: string) => date,
  },
  {
    title: 'Transfer Type',
    dataIndex: 'transfer_type',
    render: (value: string) => value,
  },
  {
    title: 'Issued By',
    dataIndex: 'issued_by',
    render: (value: string) => value,
  },
  {
    title: 'Issued to',
    dataIndex: 'issued_to',
    render: (value: string) => value,
  },
  {
    title: 'Asset No',
    dataIndex: 'asset_no',
    render: (value: string) => value,
  },
  {
    title: 'Model No',
    dataIndex: 'model_no',
    render: (value: string) => value,
  },
  {
    title: 'Serial No',
    dataIndex: 'serial_no',
    render: (value: string) => value,
  },
  {
    title: 'Item Description',
    dataIndex: 'item_description',
    render: (value: string) => value,
  },
];

const Wrapper = styled.div``;

const PropertyTransfer: React.FC = () => {
  const store = useMst();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);

  const [from, setFrom] = useState<string | null>(null);
  const [to, setTo] = useState<string | null>(null);

  const handleDateRangeChange = (values: any, valuesStr: any) => {
    console.log(valuesStr);
    setFrom(valuesStr[0]);
    setTo(valuesStr[1]);
  };

  const handleSearch = async () => {
    const token = store.userAccount.token;
    const response = await Axios.get('/api/reports/property_transfers/summary', {
      params: { from, to },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log('report', response);
    setData(response.data.data);
  };

  const handleExport = async () => {
    const token = store.userAccount.token;
    const response = await Axios.get('/api/reports/property_transfers/export', {
      responseType: 'blob',
      params: { from, to },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'ptaf-summary.csv');
    document.body.appendChild(link);
    link.click();
  };

  return (
    <DashboardLayout>
      <Page>
        <Card style={{ zIndex: 2 }}>
          <div className="card-body">
            <Wrapper>
              <p className="card-title">PTAF Summary</p>
              <div className="mt-3">
                <label className="form-title mr-3">Date Range:</label>
                <RangePicker onChange={handleDateRangeChange} />
              </div>
              <div className="mt-3">
                <Button className="mr-5" type="primary" onClick={handleSearch}>
                  Search
                </Button>
                {data.length > 0 && (
                  <Button type="primary" onClick={handleExport}>
                    Export
                  </Button>
                )}
              </div>
            </Wrapper>
          </div>
        </Card>
        <Card style={{ zIndex: 2 }}>
          <div className="card-body">
            <Wrapper>
              {loading ? (
                <Skeleton />
              ) : (
                <Table columns={columns} rowKey={v => v.id} dataSource={data} size="small"></Table>
              )}
            </Wrapper>
          </div>
        </Card>
      </Page>
    </DashboardLayout>
  );
};

export default PropertyTransfer;
