import { types, applySnapshot, cast } from 'mobx-state-tree';
import * as JsSearch from 'js-search';

import { Asset } from './Asset';
import { Notification } from './Notification';
import { MaintenanceRequestHeader } from './MaintenanceRequest';
import { NotificationType, AssetType, UserType } from './../../types';

const search = new JsSearch.Search('assetId');
search.addIndex('assetId');
search.addIndex('assetName');
search.addIndex('assetDescription');

export const User = types
  .model({
    id: types.number,
    email: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    middleName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    role: types.optional(types.number, 0),
    unreadNotificationsCount: types.optional(types.number, 0),
    notifications: types.optional(types.array(Notification), []),
    assets: types.optional(types.array(Asset), []),
  })
  .views(self => ({
    get fullName() {
      return `${self.firstName} ${self.lastName ?? ''}`;
    },
  }))
  .actions(self => ({
    save(data: UserType) {
      applySnapshot(self, data);
    },
    updateNotification(notification: NotificationType) {
      self.notifications = cast(self.notifications.map(notif => (notif.id === notification.id ? notification : notif)));
    },
    setNotifications(notifications: NotificationType[]) {
      self.notifications = cast(notifications);
    },
    setUnreadNotificationsCount(count: number) {
      self.unreadNotificationsCount = count;
    },
    setAssets(assets: AssetType[]) {
      self.assets = cast(assets);
      search.addDocuments(assets);
    },
    searchAssets(keyword: string): AssetType[] {
      return search.search(keyword) as AssetType[];
    },
  }));

export const UserApproverMap = types.model({
  user: User,
  ptafApprover: User,
  ptafApprover2: User,
  ptafApprover3: User,
  mrApprover1: User,
  mrApprover2: User,
  mrApprover3: User,
});
