import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const C = styled.div<{ flat?: boolean; noPadding?: boolean }>`
  border-radius: 5px;
  background: white;
  margin-bottom: 20px;
  ${props =>
    !props.flat &&
    css`
      box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12);
      border-top: 20px solid #5a5c69;
    `}

  .card-title {
    font-size: 14px;
    margin-bottom: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    display: block;
    font-weight: bold;
  }

  .card-body {
    ${props =>
      !props.noPadding &&
      css`
        padding: 15px 18px;
        min-height: 80px;
      `}
  }

  .card-footer {
    padding: 20px 10px;
    display: flex;
    align-items: center;
    /* border-top: 1px solid rgba(0, 0, 0, 0.07); */
    margin-top: 30px;
    background: rgba(0, 0, 0, 0.05);
  }
`;

C.propTypes = {
  flat: PropTypes.bool,
  noPadding: PropTypes.bool,
};

export const Card = C;
