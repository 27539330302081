import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import moment from 'moment';
import { Select, Input, InputNumber, Modal, DatePicker } from 'antd';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';

import { FormSection, FormRow } from '../../../components';
import { MileageFormData, MileageModalProps } from '../../../types';

const schema = yup.object().shape({
  assetId: yup.number().required('Asset is required'),
  mileageType: yup
    .number()
    .required('Type is required')
    .test('mileageType', 'Must be greater than or equal to zero', value => value >= 0),
  mileageFrom: yup
    .number()
    .required('Mileage from is required')
    .test('mileageFrom ', 'Must be greater than or equal to zero', value => value >= 0),
  mileageTo: yup
    .number()
    .required('Mileage to is required')
    .test('mileageTo', 'Must be greater than or equal to zero', value => value >= 0)
    .moreThan(yup.ref('mileageFrom'), 'Mileage To must be greater than mileage from value'),
});

const MileageModal: React.FC<MileageModalProps> = ({ visible, mileage, items, onSubmit, onCancel }) => {
  const { control, handleSubmit, errors, reset } = useForm<MileageFormData>({
    validationSchema: schema,
  });

  const [submitLoading, setSubmitLoading] = useState(false);

  const onInnerSubmit = (values: MileageFormData) => {
    if (mileage) {
      values.id = mileage.id;
    }

    reset();
    onSubmit && onSubmit(values);
  };

  useEffect(() => {
    control.setValue('assetId', mileage && mileage.asset ? mileage.asset.id : 0);
    control.setValue('mileageType', mileage ? mileage.mileageType : 0);
    control.setValue('mileageFrom', mileage ? mileage.mileageFrom : 0);
    control.setValue('mileageTo', mileage ? mileage.mileageTo : 0);
  }, [mileage]);

  const handleSubmitHof = () => {
    const h = handleSubmit(onInnerSubmit);
    return async () => {
      setSubmitLoading(true);
      setTimeout(async () => {
        await h();
        setSubmitLoading(false);
      }, 200);
    };
  };

  return (
    <Modal
      title="Mileage"
      okButtonProps={{ loading: submitLoading }}
      cancelButtonProps={{ loading: submitLoading }}
      onOk={handleSubmitHof()}
      onCancel={() => onCancel && onCancel()}
      visible={visible}
    >
      <form autoComplete="off">
        <FormSection>
          <FormRow className={errors['assetId'] ? 'has-error' : ''}>
            <label className="form-title">Asset:</label>
            <Controller
              name="assetId"
              defaultValue={mileage?.asset?.id}
              control={control}
              as={
                <Select allowClear style={{ width: 230 }}>
                  {items.map(item => (
                    <Select.Option key={item.asset.id} value={item.asset.id}>
                      {item.asset.assetTaggingNo} - {item.asset.assetDescription}
                    </Select.Option>
                  ))}
                </Select>
              }
            />
            {errors['assetId'] ? <span className="error-message mt-2">{errors['assetId'].message}</span> : null}
          </FormRow>

          <FormRow className={errors['mileageType'] ? 'has-error' : ''}>
            <label className="form-title">Type:</label>
            <Controller
              name="mileageType"
              defaultValue={mileage?.mileageType}
              control={control}
              as={
                <Select style={{ width: 230 }}>
                  <Select.Option value={0}>From office to store</Select.Option>
                  <Select.Option value={1}>From store to office</Select.Option>
                </Select>
              }
            />
            {errors['mileageType'] ? <span className="error-message mt-2">{errors['mileageType'].message}</span> : null}
          </FormRow>

          <FormRow className={errors['mileageFrom'] ? 'has-error' : ''}>
            <label className="form-title">Mileage from:</label>
            <Controller
              name="mileageFrom"
              defaultValue={mileage?.mileageFrom}
              control={control}
              as={<InputNumber style={{ width: 150 }} />}
            />
            {errors['mileageFrom'] ? <span className="error-message mt-2">{errors['mileageFrom'].message}</span> : null}
          </FormRow>
          <FormRow className={errors['mileageTo'] ? 'has-error' : ''}>
            <label className="form-title">Mileage to:</label>
            <Controller
              name="mileageTo"
              defaultValue={mileage?.mileageTo}
              control={control}
              as={<InputNumber style={{ width: 150 }} />}
            />
            {errors['mileageTo'] ? <span className="error-message mt-2">{errors['mileageTo'].message}</span> : null}
          </FormRow>
        </FormSection>
      </form>
    </Modal>
  );
};

MileageModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  mileage: PropTypes.any,
  items: PropTypes.any.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default MileageModal;
