import { AssetClassificationType } from './../../types';
export const PropertyTransferItemTableColumns = [
  {
    title: 'Asset Tagging No.',
    dataIndex: 'assetTaggingNo',
    key: 'assetTaggingNo',
  },
  {
    title: 'Classification',
    dataIndex: 'classification',
    key: 'classification',
    render: (classification: AssetClassificationType) => classification.description,
  },
  {
    title: 'Description',
    dataIndex: 'assetDescription',
    key: 'assetDescription',
  },
];
