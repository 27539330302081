import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import moment from 'moment';
import { Input, InputNumber, Modal, DatePicker, Select } from 'antd';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';

import { FormSection, FormRow } from '../../../components';
import { MaterialUsedFormData, MaterialUsedModalProps } from '../../../types';

const schema = yup.object().shape({
  materialName: yup.string().required('Material name is required'),
  quantity: yup
    .number()
    .required('Quantity is required')
    .test('mileage', 'Must be greater than zero', value => value > 0),
  pricePerItem: yup
    .number()
    .required('Price per item is required')
    .test('mileage', 'Must be greater than zero', value => value > 0),
});

const MaterialUsedModal: React.FC<MaterialUsedModalProps> = ({
  visible,
  material,
  materials,
  items,
  onSubmit,
  onCancel,
}) => {
  const { control, handleSubmit, errors, reset } = useForm<MaterialUsedFormData>({
    validationSchema: schema,
  });

  const [submitLoading, setSubmitLoading] = useState(false);

  const onInnerSubmit = (values: MaterialUsedFormData) => {
    if (material) {
      values.id = material.id;
    }
    reset();
    onSubmit && onSubmit(values);
  };

  useEffect(() => {
    control.setValue('materialName', material ? material.materialName : '');
    control.setValue('assetId', material && material.asset ? material.asset.id : 0);
    control.setValue('quantity', material ? material.quantity : 0);
    control.setValue('pricePerItem', material ? material.pricePerItem : 0);
  }, [material]);

  const handleSubmitHof = () => {
    const h = handleSubmit(onInnerSubmit);
    return async () => {
      setSubmitLoading(true);
      setTimeout(async () => {
        await h();
        setSubmitLoading(false);
      }, 200);
    };
  };

  return (
    <Modal
      title="Material Used"
      okButtonProps={{ loading: submitLoading }}
      cancelButtonProps={{ loading: submitLoading }}
      onOk={handleSubmitHof()}
      onCancel={() => onCancel && onCancel()}
      visible={visible}
    >
      <FormSection>
        <FormRow className={errors['materialName'] ? 'has-error' : ''}>
          <label className="form-title">Material:</label>
          <Controller name="materialName" defaultValue={material?.materialName} control={control} as={<Input />} />
          {errors['materialName'] ? <span className="error-message mt-2">{errors['materialName'].message}</span> : null}
        </FormRow>
        <FormRow className={errors['assetId'] ? 'has-error' : ''}>
          <label className="form-title">Asset:</label>
          <Controller
            name="assetId"
            defaultValue={material?.asset?.id}
            control={control}
            as={
              <Select allowClear style={{ width: 300 }}>
                {items.map(item => (
                  <Select.Option key={item.asset.id} value={item.asset.id}>
                    {item.asset.assetTaggingNo} - {item.asset.assetDescription}
                  </Select.Option>
                ))}
              </Select>
            }
          />
          {errors['assetId'] ? <span className="error-message mt-2">{errors['assetId'].message}</span> : null}
        </FormRow>
        <FormRow className={errors['quantity'] ? 'has-error' : ''}>
          <label className="form-title">Quantity:</label>
          <Controller
            name="quantity"
            defaultValue={material?.quantity}
            control={control}
            as={<InputNumber precision={0} />}
          />
          {errors['quantity'] ? <span className="error-message mt-2">{errors['quantity'].message}</span> : null}
        </FormRow>
        <FormRow className={errors['pricePerItem'] ? 'has-error' : ''}>
          <label className="form-title">Price per Item:</label>
          <Controller
            name="pricePerItem"
            defaultValue={material?.pricePerItem}
            control={control}
            as={<InputNumber precision={2} />}
          />
          {errors['pricePerItem'] ? <span className="error-message mt-2">{errors['pricePerItem'].message}</span> : null}
        </FormRow>
      </FormSection>
    </Modal>
  );
};

MaterialUsedModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  material: PropTypes.any,
  materials: PropTypes.any.isRequired,
  items: PropTypes.any.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default MaterialUsedModal;
