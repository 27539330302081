import React, { useEffect } from 'react';
import * as yup from 'yup';
import moment from 'moment';
import { Divider, Rate, Input, InputNumber, Modal, DatePicker, Select } from 'antd';
import PropTypes from 'prop-types';
import { useForm, Controller, useFieldArray } from 'react-hook-form';

import { FormSection, FormRow } from '../../../components';
import { StoreApproveWorkFormData, StoreApproveWorkModalProps } from '../../../types';

const schema = yup.object().shape({
  ratings: yup.array().of(
    yup.object().shape({
      technicianId: yup.number(),
      rating: yup
        .number()
        .required('Please rate the technician')
        .test('rating', 'Please rate the technician', value => value > 0),
      remarks: yup.string().required('Please leave a comment'),
    }),
  ),
});

const StoreApproveWorkModal: React.FC<StoreApproveWorkModalProps> = ({ technicians, visible, onSubmit, onCancel }) => {
  const { control, handleSubmit, errors, reset } = useForm<StoreApproveWorkFormData>({
    validationSchema: schema,
  });

  const { fields, append } = useFieldArray({ control, name: 'ratings' });

  useEffect(() => {
    const ratings = technicians.map(technician => ({
      technician,
      rating: 0,
      remarks: '',
    }));
    console.log('RATINGS', ratings);
    ratings.forEach(d => {
      setTimeout(() => {
        append(d);
      });
    });
  }, [technicians]);

  const onInnerSubmit = (values: StoreApproveWorkFormData) => {
    onSubmit && onSubmit(values);
  };

  console.log('Errors', errors);

  return (
    <Modal
      title="Close Service Request"
      onOk={handleSubmit(onInnerSubmit)}
      onCancel={() => onCancel && onCancel()}
      visible={visible}
    >
      <p>
        You are about to close this service request. Please rate the technicians and leave a comment before you close
        the request.
      </p>
      <Divider />
      {fields.map((item, index) => (
        <FormSection key={item.id} style={{ padding: 10, border: '1px solid #eee', marginBottom: 10 }}>
          <Controller
            name={`ratings[${index}].technicianId`}
            control={control}
            defaultValue={item.technician?.id}
            as={<input type="hidden" />}
          />
          <p style={{ fontSize: 14, fontWeight: 'bold' }}>
            Enter your ratings for {item.technician.firstName} {item.technician.lastName}
          </p>
          <FormRow
            className={errors.ratings && errors.ratings[index] && errors.ratings[index].rating ? 'has-error' : ''}
          >
            <label className="form-title">Rate:</label>
            <Controller
              name={`ratings[${index}].rating`}
              control={control}
              defaultValue={item.rating}
              as={<Rate tooltips={['terrible', 'bad', 'normal', 'good', 'wonderful']} />}
            />
            {errors.ratings && errors.ratings[index] && errors.ratings[index].rating ? (
              <span className="error-message mt-2">{errors.ratings[index].rating?.message}</span>
            ) : null}
          </FormRow>
          <FormRow
            className={errors.ratings && errors.ratings[index] && errors.ratings[index].remarks ? 'has-error' : ''}
          >
            <label className="form-title">Rate:</label>
            <label className="form-title">Remarks:</label>
            <Controller name={`ratings[${index}].remarks`} control={control} as={<Input.TextArea rows={3} />} />
            {errors.ratings && errors.ratings[index] && errors.ratings[index].remarks ? (
              <span className="error-message mt-2">{errors.ratings[index].remarks?.message}</span>
            ) : null}
          </FormRow>
          <Divider />
        </FormSection>
      ))}
    </Modal>
  );
};

StoreApproveWorkModal.propTypes = {
  technicians: PropTypes.any.isRequired,
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default StoreApproveWorkModal;
