import React, { useState, useEffect } from 'react';
import { Table, Skeleton, notification } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { TiHome, TiInputChecked, TiArrowForward, TiSpanner } from 'react-icons/ti';

import DashboardLayout from '../../layouts/Dashboard';
import { Page, Card, HorizontalLine } from '../../components';
import { UserType, PropertyTransferRequestItem, PropertyTransferRequest } from '../../types';
import { useMst } from '../../store/RootStore';
import config from '../../config/config';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

const columns = [
  {
    title: 'PTAF Number',
    dataIndex: 'ptafNumber',
    render: (ptafNumber: string) => ptafNumber,
  },
  {
    title: 'Recipient',
    dataIndex: 'recipient',
    render: (user: UserType) => `${user.firstName} ${user.lastName}`,
  },
  {
    title: 'Transfer type',
    dataIndex: 'transfertype',
    render: (type: string) => config.ptafTypes[type],
  },
  {
    title: 'Date issued',
    dataIndex: 'dateIssued',
    render: (date: string) => moment(date).format('YYYY-MM-DD'),
    responsive: ['md'] as Breakpoint[],
  },
  {
    title: 'Date applied',
    dataIndex: 'dateApplied',
    render: (date: string) => moment(date).format('YYYY-MM-DD'),
    responsive: ['md'] as Breakpoint[],
  },
  {
    title: 'No. of items',
    dataIndex: 'items',
    render: (value: PropertyTransferRequestItem[]) => value.length,
    responsive: ['md'] as Breakpoint[],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (status: number) => config.propertyTransferRequestStatusReference[status].label,
  },
  {
    title: 'Actions',
    dataIndex: 'uuid',
    render: (value: string) => <Link to={`/property_transfers/${value}`}>View</Link>, // eslint-disable-line
  },
];

const Wrapper = styled.div``;

const MyProperyTransferRequests: React.FC = () => {
  const store = useMst();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<PropertyTransferRequest[]>([]);

  useEffect(() => {
    setLoading(true);
    store.userAccount
      .getMyPropertyTransferRequests()
      .then(response => {
        setData(response.data.data);
      })
      .catch(err => {
        notification['error']({
          message: 'My Property Transfer Requests',
          description: 'An error occurred while fetching your data. Please try again later.',
        });
      })
      .then(() => {
        setLoading(false);
      });
  }, []);

  return (
    <DashboardLayout>
      <Page>
        <Card style={{ zIndex: 2 }}>
          <div className="card-body">
            <Wrapper>
              <p className="card-title">My Property Transfer Requests</p>
              {loading ? (
                <Skeleton />
              ) : (
                <Table columns={columns} rowKey={v => v.id} dataSource={data} size="small"></Table>
              )}
            </Wrapper>
          </div>
        </Card>
      </Page>
    </DashboardLayout>
  );
};

export default MyProperyTransferRequests;
