import React from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';
import config from '../../config/config';

interface StatusLabelProps {
  status: number;
}

const StatusLabel: React.FC<StatusLabelProps> = ({ status }) => {
  if (status === config.propertyTransferItemStatus.PENDING) {
    return <Tag color="#87d068">Pending</Tag>;
  }
  if (status === config.propertyTransferItemStatus.APPROVED) {
    return <Tag color="#108ee9">Approved</Tag>;
  }
  if (status === config.propertyTransferItemStatus.REJECTED) {
    return <Tag color="#f50">Rejected</Tag>;
  }

  return <Tag color="#87d068">Pending</Tag>;
};

StatusLabel.propTypes = {
  status: PropTypes.number.isRequired,
};

export default StatusLabel;
