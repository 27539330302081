import React, { useState, useEffect } from 'react';
import FileSaver from 'file-saver';
import { Popconfirm, Button, Divider, Select, Table, Skeleton, notification } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { TiHome, TiInputChecked, TiArrowForward, TiSpanner } from 'react-icons/ti';

import DashboardLayout from '../../../layouts/Dashboard';
import { Page, Card, HorizontalLine } from '../../../components';
import {
  UserType,
  MaintenanceRequestHeaderType,
  MaintenanceRequestTypeModelType,
  UserApproverMap,
} from '../../../types';
import { useMst } from '../../../store/RootStore';
import config from '../../../config/config';
import { PaginationProps, PaginationConfig } from 'antd/lib/pagination';

const Wrapper = styled.div``;

const AllMaintenanceRequestTypes: React.FC = () => {
  const store = useMst();
  const history = useHistory();
  const references = store.references;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<MaintenanceRequestTypeModelType[]>([]);
  const [pagination, setPagination] = useState<PaginationProps>({});
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);

  const handleEdit = async (referenceNumber: string) => {
    const response = await store.userAccount.adminEditMaintenanceRequest(referenceNumber);

    if (response.data.status === 200) {
      history.push(`/work_management/${referenceNumber}`);
      return;
    }
  };

  const columns = [
    {
      title: 'Store',
      dataIndex: 'userApproverMap',
      render: (userApproverMap: UserApproverMap) =>
        `${userApproverMap.user.firstName} ${userApproverMap.user.lastName}`,
    },
    {
      title: 'Reference number',
      dataIndex: 'referenceNumber',
      render: (referenceNumber: string) => referenceNumber,
    },
    {
      title: 'Requestor Name',
      dataIndex: 'header',
      render: (header: any) => header.requestorName,
    },
    {
      title: 'Date applied',
      dataIndex: 'header',
      render: (header: any) => {
        return moment(header.createdAt).format('YYYY-MM-DD');
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: number) => config.maintenanceRequestTypeStatusLabels[status].label,
    },
    {
      title: 'Actions',
      dataIndex: 'referenceNumber',
      render: function actions(value: string, rowData: MaintenanceRequestTypeModelType) {
        return (
          <>
            <Link to={`/work_management/${value}`}>View</Link>
            {rowData.status === config.maintenanceRequestTypeStatuses.APPROVED ? (
              <Popconfirm onConfirm={() => handleEdit(value)} title="Are you sure？" okText="Yes" cancelText="No">
                <Button type="link" style={{ marginLeft: 20 }}>
                  Edit
                </Button>
              </Popconfirm>
            ) : null}
          </>
        );
      },
    },
  ];

  const fetchData = async (page = 1) => {
    try {
      setLoading(true);
      const params = {
        page,
        user: selectedUser,
        status: selectedStatus,
      };
      const response = await store.userAccount.getAllMaintenanceRequestTypes(params);

      setPagination({
        pageSize: config.pageSize,
        current: page,
        total: response.data.meta.total,
      });

      setData(response.data.data);
      setLoading(false);
    } catch (e) {
      notification['error']({
        message: 'My Maintenance Requests',
        description: 'An error occurred while fetching your data. Please try again later.',
      });
    }
  };

  const filterTable = async () => {
    await fetchData(pagination.current);
  };

  const handleChange = async (pagination: PaginationConfig) => {
    await fetchData(pagination.current);
  };

  const exportData = async () => {
    const response = await store.userAccount.exportMaintenanceRequests(selectedUser, selectedStatus);

    const blob = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    });
    FileSaver.saveAs(blob, 'maintenance_requests.xls');
  };

  useEffect(() => {
    fetchData().then(() => {
      console.log('data loaded.');
    });
  }, []);

  return (
    <DashboardLayout>
      <Page>
        <Card style={{ zIndex: 2 }}>
          <div className="card-body">
            <Wrapper>
              <p className="card-title">My Maintenance Requests</p>
              <div>
                <div className="filters">
                  <p className="title">Filters:</p>
                  <Select
                    showSearch
                    allowClear={true}
                    optionFilterProp="children"
                    style={{ minWidth: 300 }}
                    className="mr-2"
                    value={selectedUser ?? undefined}
                    onChange={(value: string) => setSelectedUser(value)}
                    placeholder="Filter by user"
                  >
                    <Select.OptGroup label="Users">
                      {references.normalUsers.map((user: UserType) => (
                        <Select.Option key={user.id} value={user.id}>
                          {user.fullName}({user.email})
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                    <Select.OptGroup label="Engineering">
                      {references.engineeringUsers.map((user: UserType) => (
                        <Select.Option key={user.id} value={user.id}>
                          {user.fullName}({user.email})
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                    <Select.OptGroup label="Stores">
                      {references.storeUsers.map((user: UserType) => (
                        <Select.Option key={user.id} value={user.id}>
                          {user.fullName}({user.email})
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  </Select>
                  <Select
                    allowClear={true}
                    className="mr-3"
                    style={{ width: 200 }}
                    placeholder="Filter by Status"
                    value={selectedStatus ?? undefined}
                    onChange={(value: string) => setSelectedStatus(value)}
                  >
                    <Select.Option value="0">For ARL Approval</Select.Option>
                    <Select.Option value="10">FOR RDO Approval</Select.Option>
                    <Select.Option value="20">For Eng Supervisor Approval</Select.Option>
                    <Select.Option value="30">For Store Approval</Select.Option>
                    <Select.Option value="50">For Cost Approval</Select.Option>
                    <Select.Option value="-1">Rejected</Select.Option>
                    <Select.Option value="-2">Cancelled</Select.Option>
                  </Select>
                  <Button onClick={filterTable} type="primary">
                    Filter table
                  </Button>
                </div>
                <Divider />
                {loading ? (
                  <Skeleton />
                ) : (
                  <Table
                    columns={columns}
                    pagination={pagination}
                    onChange={handleChange}
                    dataSource={data}
                    size="small"
                  ></Table>
                )}
              </div>
            </Wrapper>
          </div>
        </Card>
      </Page>
    </DashboardLayout>
  );
};

export default AllMaintenanceRequestTypes;
