import React, { useState, useEffect } from 'react';
import { Table, Skeleton, notification } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { TiHome, TiInputChecked, TiArrowForward, TiSpanner } from 'react-icons/ti';

import DashboardLayout from '../../layouts/Dashboard';
import { Page, Card, HorizontalLine } from '../../components';
import {
  UserType,
  MaintenanceRequestHeaderType,
  MaintenanceRequestTypeModelType,
  MaintenanceRequestItemType,
} from '../../types';
import { useMst } from '../../store/RootStore';
import config from '../../config/config';
import Links from './Links';
import { observer } from 'mobx-react';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

const columns = [
  {
    title: 'Reference number',
    dataIndex: 'referenceNumber',
    render: (referenceNumber: string) => referenceNumber,
  },
  {
    title: 'No. of items',
    dataIndex: 'items',
    render: (items: MaintenanceRequestItemType[]) => {
      return items ? items.length : 0;
    },
    responsive: ['md'] as Breakpoint[],
  },
  {
    title: 'Date applied',
    dataIndex: 'created_at',
    render: (date: string) => moment(date).format('YYYY-MM-DD'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (status: string) => config.maintenanceRequestTypeStatusLabels[status].label,
  },
  {
    title: 'Actions',
    dataIndex: 'referenceNumber',
    render: (value: string) => <Link to={`/work_management/${value}`}>View</Link>, // eslint-disable-line
  },
];

const Wrapper = styled.div``;

const Work: React.FC = observer(() => {
  const store = useMst();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<MaintenanceRequestTypeModelType[]>([]);

  useEffect(() => {
    setLoading(true);
    store.userAccount
      .getMRTypeForEngineeringApproval()
      .then(response => {
        setData(response.data.data);
      })
      .catch(err => {
        notification['error']({
          message: 'Work Management',
          description: 'An error occurred while fetching your data. Please try again later.',
        });
      })
      .then(() => {
        setLoading(false);
      });
  }, []);

  return (
    <DashboardLayout>
      <Page>
        <Links active="costs" access={store.userAccount.access} />
        {loading ? (
          <Card>
            <div className="card-body">
              <Skeleton />
            </div>
          </Card>
        ) : (
          <Card style={{ zIndex: 2 }}>
            <div className="card-body">
              <Wrapper>
                <p className="card-title">Approvals - Cost</p>
                {loading ? <Skeleton /> : <Table columns={columns} dataSource={data} size="small"></Table>}
              </Wrapper>
            </div>
          </Card>
        )}
      </Page>
    </DashboardLayout>
  );
});

export default Work;
