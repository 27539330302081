import React, { useEffect, useState } from 'react';
import { Skeleton, Button, List } from 'antd';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import accounting from 'accounting-js';

import { Card } from '../../../components';
import { useMst } from '../../../store/RootStore';
import { MaintenanceRequestTypeComputationBreakdown } from '../../../types';

interface ComputationBreakdownProps {
  referenceNumber: string;
  refreshCounter?: number;
}

const ComputationBreakdown: React.FC<ComputationBreakdownProps> = observer(
  ({ refreshCounter = 1, referenceNumber }) => {
    const store = useMst();

    const [loading, setLoading] = useState(false);
    const [currentDisplay, setCurrentDisplay] = useState('overall');
    const [computation, setComputation] = useState<null | MaintenanceRequestTypeComputationBreakdown>(null);

    useEffect(() => {
      setLoading(true);
      store.userAccount
        .getMRTypeComputationBreakdown(referenceNumber)
        .then(response => {
          setComputation(response.data.data);
        })
        .catch(e => {
          console.log(e);
        })
        .then(() => setLoading(false));
    }, [refreshCounter]);

    return (
      <>
        {loading ? (
          <Card>
            <Skeleton />
            <Skeleton />
          </Card>
        ) : (
          <Card>
            <div className="card-body">
              <div className="card-title">
                <div className="d-flex justify-content-between">
                  Computation
                  <span>
                    <Button
                      onClick={() => setCurrentDisplay('overall')}
                      size="small"
                      type="link"
                      style={currentDisplay == 'overall' ? { fontWeight: 'bold' } : {}}
                    >
                      Overall
                    </Button>
                    <span style={{ opacity: 0.3 }}>|</span>
                    <Button
                      onClick={() => setCurrentDisplay('classification')}
                      size="small"
                      type="link"
                      style={currentDisplay == 'classification' ? { fontWeight: 'bold' } : {}}
                    >
                      By Classification
                    </Button>
                    <span style={{ opacity: 0.3 }}>|</span>
                    <Button
                      onClick={() => setCurrentDisplay('asset')}
                      size="small"
                      type="link"
                      style={currentDisplay == 'asset' ? { fontWeight: 'bold' } : {}}
                    >
                      By Asset
                    </Button>
                  </span>
                </div>
              </div>

              {currentDisplay === 'overall' ? (
                <div className="list">
                  <List size="small">
                    <List.Item>
                      <span>Total Labor Cost</span>
                      <b>{accounting.formatMoney(computation?.labor.total)}</b>
                    </List.Item>
                    <List.Item>
                      <span>Total Mileage Cost</span>
                      <b>{accounting.formatMoney(computation?.mileage.total)}</b>
                    </List.Item>
                    <List.Item>
                      <span>Total Material Cost</span>
                      <b>{accounting.formatMoney(computation?.materials.total)}</b>
                    </List.Item>
                    <List.Item>
                      <span>Total Overall Cost</span>
                      <b>{accounting.formatMoney(computation?.overall.total)}</b>
                    </List.Item>
                  </List>
                </div>
              ) : null}
              {currentDisplay === 'classification' ? (
                <div className="list">
                  <List size="small">
                    <List.Item>
                      <span>Labor</span>
                    </List.Item>
                    <List.Item>
                      <List size="small" style={{ marginLeft: 20, width: '100%' }}>
                        {computation &&
                          Object.keys(computation.labor.byClassification).map((key: string) => {
                            const assetClassification = computation.labor.byClassification[key];
                            return (
                              <List.Item key={key}>
                                <span>{assetClassification.classification.description}</span>
                                <b>{accounting.formatMoney(assetClassification.value)}</b>
                              </List.Item>
                            );
                          })}
                      </List>
                    </List.Item>
                    <List.Item>
                      <span>Mileage</span>
                    </List.Item>
                    <List.Item>
                      <List size="small" style={{ marginLeft: 20, width: '100%' }}>
                        {computation &&
                          Object.keys(computation.mileage.byClassification).map((key: string) => {
                            const assetClassification = computation.mileage.byClassification[key];
                            return (
                              <List.Item key={key}>
                                <span>{assetClassification.classification.description}</span>
                                <b>{accounting.formatMoney(assetClassification.value)}</b>
                              </List.Item>
                            );
                          })}
                      </List>
                    </List.Item>
                    <List.Item>
                      <span>Material</span>
                    </List.Item>
                    <List.Item>
                      <List size="small" style={{ marginLeft: 20, width: '100%' }}>
                        {computation &&
                          Object.keys(computation.materials.byClassification).map((key: string) => {
                            const assetClassification = computation.materials.byClassification[key];
                            return (
                              <List.Item key={key}>
                                <span>{assetClassification.classification.description}</span>
                                <b>{accounting.formatMoney(assetClassification.value)}</b>
                              </List.Item>
                            );
                          })}
                      </List>
                    </List.Item>
                    <List.Item>
                      <span>Overall</span>
                    </List.Item>
                    <List.Item>
                      <List size="small" style={{ marginLeft: 20, width: '100%' }}>
                        {computation &&
                          Object.keys(computation.overall.byClassification).map((key: string) => {
                            const assetClassification = computation.overall.byClassification[key];
                            return (
                              <List.Item key={key}>
                                <span>{assetClassification.classification.description}</span>
                                <b>{accounting.formatMoney(assetClassification.value)}</b>
                              </List.Item>
                            );
                          })}
                      </List>
                    </List.Item>
                  </List>
                </div>
              ) : null}
              {currentDisplay === 'asset' ? (
                <div className="list">
                  <List size="small">
                    <List.Item>
                      <span>Labor</span>
                    </List.Item>
                    <List.Item>
                      <List size="small" style={{ marginLeft: 20, width: '100%' }}>
                        {computation &&
                          Object.keys(computation.labor.byAsset).map((key: string) => {
                            const asset = computation.labor.byAsset[key];
                            return (
                              <List.Item key={key}>
                                <span>
                                  {asset.asset.assetTaggingNo} - {asset.asset.assetDescription}
                                </span>
                                <b>{accounting.formatMoney(asset.value)}</b>
                              </List.Item>
                            );
                          })}
                      </List>
                    </List.Item>
                    <List.Item>
                      <span>Mileage</span>
                    </List.Item>
                    <List.Item>
                      <List size="small" style={{ marginLeft: 20, width: '100%' }}>
                        {computation &&
                          Object.keys(computation.mileage.byAsset).map((key: string) => {
                            const asset = computation.mileage.byAsset[key];
                            return (
                              <List.Item key={key}>
                                <span>
                                  {asset.asset.assetTaggingNo} - {asset.asset.assetDescription}
                                </span>
                                <b>{accounting.formatMoney(asset.value)}</b>
                              </List.Item>
                            );
                          })}
                      </List>
                    </List.Item>
                    <List.Item>
                      <span>Material</span>
                    </List.Item>
                    <List.Item>
                      <List size="small" style={{ marginLeft: 20, width: '100%' }}>
                        {computation &&
                          Object.keys(computation.materials.byAsset).map((key: string) => {
                            const asset = computation.materials.byAsset[key];
                            return (
                              <List.Item key={key}>
                                <span>
                                  {asset.asset.assetTaggingNo} - {asset.asset.assetDescription}
                                </span>
                                <b>{accounting.formatMoney(asset.value)}</b>
                              </List.Item>
                            );
                          })}
                      </List>
                    </List.Item>
                    <List.Item>
                      <span>Overall</span>
                    </List.Item>
                    <List.Item>
                      <List size="small" style={{ marginLeft: 20, width: '100%' }}>
                        {computation &&
                          Object.keys(computation.overall.byAsset).map((key: string) => {
                            const asset = computation.overall.byAsset[key];
                            return (
                              <List.Item key={key}>
                                <span>
                                  {asset.asset.assetTaggingNo} - {asset.asset.assetDescription}
                                </span>
                                <b>{accounting.formatMoney(asset.value)}</b>
                              </List.Item>
                            );
                          })}
                      </List>
                    </List.Item>
                  </List>
                </div>
              ) : null}
            </div>
          </Card>
        )}
      </>
    );
  },
);

ComputationBreakdown.propTypes = {
  referenceNumber: PropTypes.string.isRequired,
  refreshCounter: PropTypes.number,
};

export default ComputationBreakdown;
