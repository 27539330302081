import styled from 'styled-components';

export const Wrapper = styled.div`
  /* padding: 20px; */
  /* background: rgba(0, 0, 0, 0.05); */

  .items-title {
    /* margin-top: 40px; */
    /* font-weight: bold; */
    flex: 1;
    display: flex;
    /* justify-content: space-between; */
    font-size: 14px;
    /* align-items: center; */
  }
`;

export const ListWrapper = styled.div`
  margin-top: 10px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 2px;
`;

export const RowWrapper = styled.div`
  background: white;
  padding: 10px 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);

  .asset-row {
    display: flex;
    align-items: center;
    line-height: 15px;
    margin-bottom: 1px;

    &.title-row {
      /* margin-bottom: 10px; */
      justify-content: space-between;
    }

    .label {
      margin-right: 15px;
      font-weight: bold;
    }

    .value {
      font-size: 10px;
      margin-right: 20px;
      display: inline-block;
    }

    .icon {
      margin-right: 8px;
      font-size: 10px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
  .title {
    font-size: 14px;
    font-weight: bold;
    margin-right: 20px;
  }

  .image-wrapper {
    width: 150px;
    margin-right: 20px;
  }

  @media (max-width: 411px) {
    .image-wrapper {
      justify-content: center;
      margin: 10px auto 30px;
    }

    .item-details-content {
      flex-direction: column;
    }

    .ant-upload.ant-upload-select-picture-card {
      width: 150px;
      height: 150px;
    }
  }
`;
