import React, { useState, useEffect } from 'react';
import { Table, Skeleton, notification } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { TiHome, TiInputChecked, TiArrowForward, TiSpanner } from 'react-icons/ti';

import DashboardLayout from '../../layouts/Dashboard';
import { Page, Card, HorizontalLine } from '../../components';
import {
  UserType,
  MaintenanceRequestHeaderType,
  MaintenanceRequestTypeModelType,
  MaintenanceRequestsResponse,
} from '../../types';
import { useMst } from '../../store/RootStore';
import config from '../../config/config';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

const columns = [
  {
    title: 'Reference number',
    dataIndex: 'referenceNumber',
    render: (referenceNumber: string) => referenceNumber,
  },
  {
    title: 'Requestor Name',
    dataIndex: 'requestorName',
    render: (requestorName: string) => requestorName,
  },
  {
    title: 'Total Requests',
    dataIndex: 'types',
    render: (types: MaintenanceRequestTypeModelType[]) => (types ? types.length : 0),
    responsive: ['md'] as Breakpoint[],
  },
  {
    title: 'No. of items',
    dataIndex: 'types',
    render: (types: MaintenanceRequestTypeModelType[]) => {
      return types ? types.map(type => type.items.length).reduce((p, c) => p + c, 0) : 0;
    },
    responsive: ['md'] as Breakpoint[],
  },
  {
    title: 'Date applied',
    dataIndex: 'createdAt',
    render: (date: string) => moment(date).format('YYYY-MM-DD'),
    responsive: ['md'] as Breakpoint[],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (status: number) => config.maintenanceRequestStatusLabels[status],
  },
  {
    title: 'Actions',
    dataIndex: 'uuid',
    render: (value: string) => <Link to={`/maintenance_requests/${value}`}>View</Link>, // eslint-disable-line
  },
];

const Wrapper = styled.div``;

const MyProperyTransferRequests: React.FC = () => {
  const store = useMst();

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<MaintenanceRequestsResponse>({
    data: [],
    meta: {
      current_page: 1,
      from: 1,
      last_page: 1,
      path: '',
      per_page: 10,
      to: 1,
      total: 1,
    },
  });

  const onPageChange = (page: number): void => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setLoading(true);
    store.userAccount
      .getMyMaintenanceRequests(currentPage)
      .then(response => {
        setData(response.data);
      })
      .catch(err => {
        notification['error']({
          message: 'My Maintenance Requests',
          description: 'An error occurred while fetching your data. Please try again later.',
        });
      })
      .then(() => {
        setLoading(false);
      });
  }, [currentPage]);

  return (
    <DashboardLayout>
      <Page>
        <Card style={{ zIndex: 2 }}>
          <div className="card-body">
            <Wrapper>
              <p className="card-title">My Maintenance Requests</p>
              {loading ? (
                <Skeleton />
              ) : (
                <Table
                  columns={columns}
                  dataSource={data.data}
                  size="small"
                  pagination={{
                    onChange: onPageChange,
                    pageSize: 10,
                    total: data.meta.total,
                    current: data.meta.current_page,
                  }}
                ></Table>
              )}
            </Wrapper>
          </div>
        </Card>
      </Page>
    </DashboardLayout>
  );
};

export default MyProperyTransferRequests;
