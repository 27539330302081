import styled from 'styled-components';

export const Wrapper = styled.div`
  .items-title {
    margin-top: 40px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    align-items: center;
  }
`;

export const ListWrapper = styled.div`
  margin-top: 10px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 2px;
`;

export const RowWrapper = styled.div`
  padding: 20px 40px;
  border-bottom: 1px solid #ccc;

  .row {
    display: flex;
    align-items: center;
    margin-bottom: 1px;

    &.title-row {
      margin-bottom: 10px;
      justify-content: space-between;
    }

    .label {
      margin-right: 15px;
      font-weight: bold;
    }

    .value {
      font-size: 12px;
      margin-right: 20px;
      display: inline-block;
    }

    .icon {
      margin-right: 8px;
      font-size: 10px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    margin-right: 20px;
  }
`;
