import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useWindowWidth } from '@react-hook/window-size';

import { Sidebar, Header } from '../components/';
import { Drawer } from 'antd';
import { useMst } from '../store/RootStore';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background: #f0f2f5;
  position: relative;

  @media (min-width: 1300px) {
    max-width: 1200px;
    margin: 0 auto;
  }
`;

const Main = styled.div`
  flex: 1;
  margin-left: 180px;
  position: relative;

  @media (max-width: 768px) {
    margin-left: 0px;
  }
`;

const Content = styled.div`
  padding: 20px 60px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const DashboardLayout: React.FC = observer(({ children }) => {
  const store = useMst();
  const location = useLocation();
  const windowWidth = useWindowWidth();

  useEffect(() => {
    store.globalUI.setMenuDrawerVisible(false);
  }, [location.pathname]);

  return (
    <Wrapper>
      {windowWidth > 768 ? (
        <Sidebar />
      ) : (
        <Drawer
          placement="left"
          onClose={() => store.globalUI.setMenuDrawerVisible(false)}
          visible={store.globalUI.menuDrawerVisible}
          bodyStyle={{ background: '#f0f2f5', padding: 3 }}
        >
          <Sidebar />
        </Drawer>
      )}
      <Main>
        <Header />
        <Content>{children}</Content>
      </Main>
    </Wrapper>
  );
});

DashboardLayout.propTypes = {
  children: PropTypes.any,
};

export default DashboardLayout;
