import { types, flow, cast, getParent } from 'mobx-state-tree';
import { AxiosResponse } from 'axios';

import { AssetClassification } from './Asset';
import axios from '../../utils/axios';
import { User } from './index';
import {
  UserType,
  RootModelType,
  UsersResponse,
  MaterialsResponse,
  ThirdPartyTechniciansResponse,
  MaterialType,
  AssetClassificationsResponse,
} from './../../types';
import config from '../../config/config';

export const Material = types.model({
  id: types.number,
  name: types.string,
  description: types.string,
  cost: types.number,
});

export const ThirdPartyTechnician = types.model({
  id: types.number,
  name: types.string,
  email: types.maybeNull(types.string),
});

export const References = types
  .model({
    users: types.optional(types.array(User), []),
    assetClassifications: types.optional(types.array(AssetClassification), []),
    thirdPartyTechnicians: types.optional(types.array(ThirdPartyTechnician), []),
    materials: types.optional(types.array(Material), []),
  })
  .views(self => ({
    get normalUsers(): UserType[] {
      return self.users.filter((user: UserType) => user.role === config.roles.NORMAL_USERS);
    },
    get engineeringUsers(): UserType[] {
      return self.users.filter(
        (user: UserType) => user.role === config.roles.ENGINEERING || user.role === config.roles.ENGINEERING_ADMIN,
      );
    },
    get storeUsers(): UserType[] {
      return self.users.filter((user: UserType) => user.role === config.roles.STORES);
    },
    get vendorUsers(): UserType[] {
      return self.users.filter((user: UserType) => user.role === config.roles.VENDOR);
    },
  }))
  .actions(self => {
    const loadAllUsers = flow<AxiosResponse<UsersResponse>, any[]>(function*(): Generator<
      Promise<AxiosResponse<UsersResponse>>
    > {
      const rootStore = getParent<RootModelType>(self);
      try {
        let response = yield axios.get<UsersResponse>(`/api/references/users`, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${rootStore.userAccount.token}`,
          },
        });
        response = response as UsersResponse;
        return response;
      } catch (error) {
        console.log(error);
      }
    });

    const loadThirdPartyTechnicians = flow<AxiosResponse<ThirdPartyTechniciansResponse>, any[]>(function*(): Generator<
      Promise<AxiosResponse<ThirdPartyTechniciansResponse>>
    > {
      const rootStore = getParent<RootModelType>(self);
      try {
        let response = yield axios.get<ThirdPartyTechniciansResponse>(`/api/references/third_party_technicians`, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${rootStore.userAccount.token}`,
          },
        });
        response = response as ThirdPartyTechniciansResponse;
        return response;
      } catch (error) {
        console.log(error);
      }
    });

    const loadMaterials = flow<AxiosResponse<MaterialsResponse>, any[]>(function*(): Generator<
      Promise<AxiosResponse<MaterialsResponse>>
    > {
      const rootStore = getParent<RootModelType>(self);
      try {
        let response = yield axios.get<MaterialsResponse>(`/api/references/materials`, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${rootStore.userAccount.token}`,
          },
        });
        response = response as MaterialsResponse;
        return response;
      } catch (error) {
        console.log(error);
      }
    });

    const loadAssetClassifications = flow<AxiosResponse<AssetClassificationsResponse>, any[]>(function*(): Generator<
      Promise<AxiosResponse<AssetClassificationsResponse>>
    > {
      const rootStore = getParent<RootModelType>(self);
      try {
        let response = yield axios.get<AssetClassificationsResponse>(`/api/references/asset_classifications`, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${rootStore.userAccount.token}`,
          },
        });
        response = response as AssetClassificationsResponse;
        return response;
      } catch (error) {
        console.log(error);
      }
    });

    return {
      initialize: flow(function*() {
        const usersResponse: AxiosResponse<UsersResponse> = yield loadAllUsers();
        self.users = cast(usersResponse.data.data);

        const thirdPartyTechniciansResponse: AxiosResponse<ThirdPartyTechniciansResponse> = yield loadThirdPartyTechnicians();
        self.thirdPartyTechnicians = cast(thirdPartyTechniciansResponse.data.data);

        const materialsResponse: AxiosResponse<MaterialsResponse> = yield loadMaterials();
        self.materials = cast(materialsResponse.data.data);

        const assetClassificationResponse: AxiosResponse<AssetClassificationsResponse> = yield loadAssetClassifications();
        self.assetClassifications = cast(assetClassificationResponse.data.data);
      }),
      loadAllUsers,
      loadThirdPartyTechnicians,
      loadMaterials,
      loadAssetClassifications,
    };
  });
