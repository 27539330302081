import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Skeleton, Table } from 'antd';
import { Link } from 'react-router-dom';

import DashboardLayout from '../../layouts/Dashboard';
import { Page, Card } from '../../components';
import {
  UserType,
  PropertyTransferRequestItem,
  PropertyTransferRequest,
  MaintenanceRequestHeaderType,
  MaintenanceRequestTypeModelType,
} from '../../types';
import { useMst } from '../../store/RootStore';
import Links from './Links';
import { observer } from 'mobx-react';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

const columns = [
  {
    title: 'Requestor name',
    dataIndex: 'requestorName',
    render: (name: string) => name,
  },
  {
    title: 'Total Requests',
    dataIndex: 'types',
    render: (types: MaintenanceRequestTypeModelType[]) => (types ? types.length : 0),
  },
  {
    title: 'No. of items',
    dataIndex: 'types',
    render: (types: MaintenanceRequestTypeModelType[]) => {
      return types ? types.map(type => type.items.length).reduce((p, c) => p + c, 0) : 0;
    },
    responsive: ['md'] as Breakpoint[],
  },
  {
    title: 'Date applied',
    dataIndex: 'createdAt',
    render: (date: string) => moment(date).format('YYYY-MM-DD'),
    responsive: ['md'] as Breakpoint[],
  },
  {
    title: 'Actions',
    dataIndex: 'uuid',
    render: (value: string) => <Link to={`/maintenance_requests/${value}`}>View</Link>, // eslint-disable-line
  },
];

const Wrapper = styled.div``;

interface Props {
  type: string;
}

const MaintenanceRequestForApprovals: React.FC<Props> = observer(({ type }) => {
  const store = useMst();

  const [loading, setLoading] = useState(false);
  const [maintenanceRequestForApprovals, setMaintenanceRequestForApprovals] = useState<MaintenanceRequestHeaderType[]>(
    [],
  );

  useEffect(() => {
    setLoading(true);
    store.userAccount
      .getMyMaintenanceRequestsForApproval(type)
      .then(response => {
        setMaintenanceRequestForApprovals(response.data.data);
      })
      .catch(e => console.log(e))
      .then(() => setLoading(false));
  }, [type]);

  return (
    <DashboardLayout>
      <Page>
        <Links active="maintenance_requests" access={store.userAccount.access}></Links>
        {loading ? (
          <Card>
            <div className="card-body">
              <Skeleton />
            </div>
          </Card>
        ) : (
          <Card style={{ zIndex: 2 }}>
            <div className="card-body">
              <Wrapper>
                <p className="card-title">Approvals - Maintenance Requests</p>
                <div style={{ display: 'flex', marginBottom: 20 }}>
                  {type === 'pending' ? (
                    <div className="link-wrapper" style={{ marginRight: 20, fontWeight: 'bold' }}>
                      <span>For ARL Approval</span>
                    </div>
                  ) : (
                    <Link to="/approvals/maintenance_requests/pending" replace>
                      <div className="link-wrapper" style={{ marginRight: 20, fontWeight: 'bold' }}>
                        <span>For ARL Approval</span>
                      </div>
                    </Link>
                  )}
                  {type === 'arl' ? (
                    <div className="link-wrapper" style={{ marginRight: 20, fontWeight: 'bold' }}>
                      <span>For RDO Approval</span>
                    </div>
                  ) : (
                    <Link to="/approvals/maintenance_requests/arl" replace>
                      <div className="link-wrapper" style={{ marginRight: 20, fontWeight: 'bold' }}>
                        <span>For RDO Approval</span>
                      </div>
                    </Link>
                  )}
                  {type === 'rdo' ? (
                    <div className="link-wrapper" style={{ marginRight: 20, fontWeight: 'bold' }}>
                      <span>For Engineering Admin Approval</span>
                    </div>
                  ) : (
                    <Link to="/approvals/maintenance_requests/rdo" replace>
                      <div className="link-wrapper" style={{ marginRight: 20, fontWeight: 'bold' }}>
                        <span>For Engineering Admin Approval</span>
                      </div>
                    </Link>
                  )}
                </div>
                <Table columns={columns} dataSource={maintenanceRequestForApprovals} size="small"></Table>
              </Wrapper>
            </div>
          </Card>
        )}
      </Page>
    </DashboardLayout>
  );
});

export default MaintenanceRequestForApprovals;
