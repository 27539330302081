import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TiCalculator, TiTicket, TiArrowForward, TiSpanner } from 'react-icons/ti';

import { AccessType } from '../../types';

const Wrapper = styled.div`
  margin-left: 10px;
  a {
    padding: 12px 20px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.65);

    &.active,
    &:hover {
      display: inline-block;
      background: white;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      box-shadow: 0 -7px 14px 0 rgba(60, 66, 87, 0.12), 0 -3px 6px 0 rgba(0, 0, 0, 0.12);
      margin-bottom: 0px;
      z-index: 1;
    }
  }

  .icon {
    margin-right: 12px;
    font-size: 18px;
  }

  @media (max-width: 768px) {
    .label {
      display: none;
    }

    .icon {
      margin-right: 0px;
    }
  }
`;

interface LinksProps {
  active: string;
  access?: AccessType;
}

const Links: React.FC<LinksProps> = ({ active, access }) => {
  return (
    <Wrapper>
      {access && access.approvals.propertyTransfer ? (
        <Link className={active === 'property_transfers' ? 'active' : ''} to="/approvals/property_transfer_requests">
          <TiArrowForward className="icon" />
          <span className="label">Property Transfers</span>
        </Link>
      ) : null}
      {access && access.approvals.maintenanceRequest ? (
        <Link className={active === 'maintenance_requests' ? 'active' : ''} to="/approvals/maintenance_requests">
          <TiSpanner className="icon" />
          <span className="label">Maintenance Requests</span>
        </Link>
      ) : null}
      {access && access.approvals.work ? (
        <Link className={active === 'work_management' ? 'active' : ''} to="/approvals/work_management">
          <TiTicket className="icon" />
          <span className="label">Work</span>
        </Link>
      ) : null}
      {access && access.approvals.cost ? (
        <Link className={active === 'costs' ? 'active' : ''} to="/approvals/costs">
          <TiCalculator className="icon" />
          <span className="label">Costs</span>
        </Link>
      ) : null}
    </Wrapper>
  );
};

Links.propTypes = {
  active: PropTypes.string.isRequired,
  access: PropTypes.any,
};

export default Links;
