import React, { useState } from 'react';
import styled from 'styled-components';
import { getSnapshot } from 'mobx-state-tree';
import { Button, Input } from 'antd';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import humps from 'humps';
import localforage from 'localforage';

import config from '../../config/config';
import { FormRow, FormSection } from '../../components';
import { useMst } from '../../store/RootStore';
import { UserAccountType } from '../../types';

const Wrapper = styled.div`
  min-height: 100vh;
  background: white;
  /* background: rgba(0, 0, 0, 0.05); */
  /* background: #5a5c69; */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginContainer = styled.div`
  min-height: 520px;
  width: 1024px;
  padding: 40px;
  background: white;
  /* border-top: 5px solid #5a5c69;
  border-radius: 5px; */
  /* box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 4px 0px; */

  .image {
    width: 100%;
  }

  .flex-grow {
    flex: 1;
  }

  .left {
    width: 400px;
    padding: 20px;
  }

  .right {
    width: 600px;
    padding: 20px;
  }

  @media (max-width: 1024px) {
    .right {
      display: none !important;
    }

    .left {
      margin: 0px auto;
      max-width: 400px;
      width: auto;
    }
  }

  @media (max-width: 411px) {
    padding: 0px;
  }
`;

interface FormData {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email is required')
    .email('Must be a valid email'),
  password: yup.string().required('Password is required'),
});

const Login: React.FC = () => {
  const history = useHistory();
  const store = useMst();

  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState<string | null>(null);
  const { errors, handleSubmit, control } = useForm<FormData>({
    validationSchema: schema,
  });

  const onSubmit = async (data: FormData) => {
    try {
      setLoginError(null);
      setLoading(true);

      const response = await store.login(humps.decamelizeKeys(data));
      const userData = response.data.data as UserAccountType;
      store.userAccount.save(userData);
      await store.userAccount.getNotifications();
      const userDataSerialized = getSnapshot(store.userAccount);
      await localforage.setItem(config.authTokenKey, userDataSerialized);
      setLoading(false);
      history.push('/dashboard');
    } catch (error) {
      setLoading(false);
      setLoginError(error.response.data.message);
    }
  };
  return (
    <Wrapper>
      <LoginContainer>
        <div className="d-flex">
          <div className="left">
            {config.stage === 'staging' ? (
              <img
                className="image"
                src="https://repairs-and-maintenance-public.s3-ap-southeast-1.amazonaws.com/asset.png"
              />
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormSection>
                  <p className="title">Repairs & Maintenance</p>
                  <span className="d-block mb-3">Sign in to your account</span>

                  {loginError ? <span className="error-message">{loginError}</span> : null}
                  <FormRow>
                    <label className="form-title">Email</label>
                    <div className="controls">
                      <Controller name="email" control={control} as={<Input size="large" />} />
                    </div>
                    {errors['email'] ? <span className="error-message">{errors['email'].message}</span> : null}
                  </FormRow>
                  <FormRow>
                    <label className="form-title">Password</label>
                    <div className="controls">
                      <Controller name="password" control={control} as={<Input.Password size="large" />} />
                    </div>
                    {errors['password'] ? <span className="error-message">{errors['password'].message}</span> : null}
                  </FormRow>
                  <FormRow>
                    <Button loading={loading} htmlType="submit" size="large" type="primary" block>
                      Continue
                    </Button>
                  </FormRow>
                </FormSection>
              </form>
            )}
          </div>
          <div className="right d-flex align-items-center">
            {config.stage === 'staging' ? (
              <h1 style={{ background: 'rgba(0,0,0,0.05', padding: '20px' }}>
                Hello, <br />
                You are accessing the UAT version of R&M. <br />
                Please click this <a href="https://repairs.my.hiflyer.ca">link</a> to go to the live version
              </h1>
            ) : (
              <img
                className="image"
                src="https://repairs-and-maintenance-public.s3-ap-southeast-1.amazonaws.com/asset.png"
              />
            )}
          </div>
        </div>
      </LoginContainer>
    </Wrapper>
  );
};

export default Login;
