const ptafTypes: { [key: string]: string } = {
  permanent: 'Permanent Transfer',
  retirement: 'Retirement',
  issued_to_store: 'Issued to Store', // eslint-disable-line
  // repair: 'Repair',
  return: 'Return',
  safekeeping: 'Safekeeping',
  pullout: 'Pull Out',
  deliver: 'Deliver',
  others: 'Others',
};

const propertyTransferRequestStatusReference: { [key: string]: { label: string } } = {
  '-2': {
    label: 'Cancelled',
  },
  '-1': {
    label: 'Rejected',
  },
  '0': {
    label: 'Pending',
  },
  '10': {
    label: 'Approved by first approver',
  },
  '15': {
    label: 'Approved by second approver',
  },
  '20': {
    label: 'Approved',
  },
};
const rolesLabel: { [key: number]: string } = {
  0: 'User',
  1: 'Store',
  2: 'Engineering',
  3: 'Vendor',
  10: 'Engineering Admin',
};

const maintenanceRequestStatusLabels: { [key: string]: string } = {
  '-2': 'Cancelled',
  '-1': 'Rejected',
  '0': 'For ARL Approval',
  '5': 'For RDO Approval',
  '7': 'For Eng Supervisor Approval',
  '8': 'Work in Progress',
  '10': 'Work Done',
};

const maintenanceRequestTypeLabels: { [key: number]: string } = {
  0: 'In-house',
  1: 'Vendor',
};
const mrServiceRequestItemClassificationLabels: { [key: number]: string } = {
  0: 'Repair',
  1: 'Preventive Maintenance',
  2: 'Regular Maintenance',
};

const maintenanceRequestTypeStatuses = {
  CANCELLED: -2,
  REJECTED: -1,
  PENDING: 0,
  ARL_APPROVED: 10,
  RDO_APPROVED: 20,
  FOR_STORE_APPROVAL: 30,
  FOR_TECHNICIAN_COSTING: 40,
  FOR_ENGINEERING_ADMIN_COST_APPROVAL: 50,
  APPROVED: 60,
};

const maintenanceRequestTypeStatusLabels: { [key: string]: { label: string; color: string } } = {
  '-2': {
    label: 'Cancelled',
    // color: '#f50',
    color: 'error',
  },
  '-1': {
    label: 'Rejected',
    // color: '#f50',
    color: 'error',
  },
  '0': {
    label: 'For ARL approval',
    // color: '#87d068',
    color: 'processing',
  },
  '10': {
    label: 'For RDO approval',
    // color: '#87d068',
    color: 'processing',
  },
  '20': {
    label: 'For Eng. Supervisor approval',
    // color: '#108ee9',
    color: 'processing',
  },
  '30': {
    label: 'For store approval',
    // color: '#108ee9',
    color: 'processing',
  },
  '40': {
    label: 'For technician costing',
    // color: '#108ee9',
    color: 'processing',
  },
  '50': {
    label: 'For Cost Approval',
    // color: '#108ee9',
    color: 'processing',
  },
  '60': {
    label: 'Approved',
    // color: '#108ee9',
    color: 'success',
  },
};

const maintenanceRequestItemStatusLabels: { [key: string]: { label: string; color: string } } = {
  '-1': {
    label: 'Rejected',
    color: '#f50',
  },
  '0': {
    label: 'Pending',
    color: '#87d068',
  },
  '1': {
    label: 'Approved',
    color: '#108ee9',
  },
};

const mileageTypeLabels: { [key: number]: string } = {
  0: 'From office to store',
  1: 'From store to office',
};

export default {
  authTokenKey: `ASSET_MANAGEMENT_TKY_${process.env.REACT_APP_STAGE}`,
  stage: process.env.REACT_APP_STAGE,
  apiURL: process.env.REACT_APP_API_URL,

  roles: {
    NORMAL_USERS: 0,
    STORES: 1,
    ENGINEERING: 2,
    VENDOR: 3,
    ENGINEERING_ADMIN: 10,
  },

  rolesLabel,

  propertyTransferRequestStatus: {
    CANCELLED: -2,
    REJECTED: -1,
    PENDING: 0,
    APPROVED_BY_APPROVER1: 10,
    APPROVED_BY_APPROVER2: 15,
    APPROVED: 20,
  },

  propertyTransferRequestStatusReference,

  propertyTransferItemStatus: {
    REJECTED: -1,
    PENDING: 0,
    APPROVED: 1,
  },

  ptafTypes,

  pageSize: 10,
  maxFileSize: 10 * 1024 * 1024,
  supportedImageFormats: ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'],

  maintenanceRequestStatuses: {
    REJECTED: -1,
    PENDING: 0,
    ARL_APPROVED: 5,
    RDO_APPROVED: 7,
    WORK_IN_PROGRESS: 8,
    WORK_DONE: 10,
  },
  maintenanceRequestStatusLabels,

  maintenanceRequestTypes: {
    INHOUSE: 0,
    VENDOR: 1,
  },
  maintenanceRequestTypeLabels,

  maintenanceRequestTypeStatuses,
  maintenanceRequestTypeStatusLabels,

  mrServiceRequestItemClassifications: {
    REPAIR: 0,
    PREVENTIVE_MAINTENANCE: 1,
    REGULAR_MAINTENANCE: 2,
  },
  mrServiceRequestItemClassificationLabels,

  maintenanceRequestItemStatus: {
    REJECTED: -1,
    PENDING: 0,
    APPROVED: 1,
  },

  maintenanceRequestItemStatusLabels,

  mileageTypeLabels,
};
