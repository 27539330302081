import { types, applySnapshot, cast } from 'mobx-state-tree';

export const Notification = types.model({
  id: types.string,
  type: types.string,
  link: types.string,
  message: types.string,
  date: types.string,
  read: types.number,
});
