import React from 'react';
import { Tag, Badge, Avatar, Dropdown, Menu, Layout } from 'antd';
import { MenuOutlined, BellOutlined, CaretDownOutlined } from '@ant-design/icons';
import TimeAgo from 'timeago-react';
import moment from 'moment';

import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import { useMst } from '../store/RootStore';
import { NotificationType } from '../types';
import { Sidebar } from './Sidebar';
import config from '../config/config';

const Wrapper = styled.div`
  color: #333;
  /* position: fixed; */
  /* width: 100%; */

  .ant-layout-header {
    background: #f0f2f5;
    /* box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08); */
  }

  .notifications {
    color: #333;
    margin-right: 27px;
    margin-top: 4px;
  }

  .profile-link {
    font-weight: bold;
    color: #333;

    .name {
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  @media (max-width: 768px) {
    .profile-link {
      /* padding: 0px 20px; */
      .name {
        display: none;
      }
    }
    .ant-layout-header {
      padding: 10px;
    }
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 10px;
`;

const NotificationRow = styled.div`
  min-height: 60px;
  font-size: 12px;
  position: relative;
  border-bottom: 1px solid #eee;
  display: flex;
  flex-direction: row;

  .type {
    width: 30px;
    margin-right: 20px;
  }
  .tag {
    font-size: 10px;
    padding: 3px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-right: 5px;
  }
  .message {
    display: block;
    font-weight: bold;
    word-wrap: break-word;
    width: 280px;
    white-space: pre-wrap;
  }
  .date {
    display: block;
    font-size: 10px;
  }
  .read-icon {
    position: absolute;
    top: 0px;
    right: 5px;
  }

  .empty {
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .message {
      font-size: 20px;
    }

    .image {
      height: 260px;
    }
  }
`;

export const Header = observer(() => {
  const { userAccount, globalUI } = useMst();
  const history = useHistory();

  const notifications = userAccount.user?.notifications;

  const handleLogout = () => {
    userAccount.logout();
    history.push('/login');
  };

  const handleNotificationClicked = async (notification: NotificationType) => {
    await userAccount.markNotificationAsRead(notification);
    await userAccount.getUnreadNotificationsCount();
    history.push(notification.link);
  };

  const menu = (
    <Menu className="header-menu">
      <Menu.Item>
        {userAccount && userAccount.user ? (
          <span style={{ fontSize: 12, fontWeight: 'bold' }}>Role: {config.rolesLabel[userAccount.user.role]}</span>
        ) : null}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a onClick={handleLogout}>Logout</a>
      </Menu.Item>
    </Menu>
  );

  const notificationsMenu = (
    <Menu className="notifications-menu" style={{ height: 400, width: 400, overflowY: 'scroll' }}>
      {notifications?.length === 0 ? (
        <Menu.Item>
          <NotificationRow className="d-flex justify-content-center">
            <div className="empty">
              <p className="message">You have no notifications yet</p>
              <img
                className="image"
                src="https://staging-repairs-and-maintenance-public.s3-ap-southeast-1.amazonaws.com/static/images/notification.png"
              />
            </div>
          </NotificationRow>
        </Menu.Item>
      ) : null}
      {notifications?.map((notification: NotificationType) => (
        <Menu.Item onClick={() => handleNotificationClicked(notification)} key={notification.id}>
          <NotificationRow>
            <div className="type">
              <Avatar className="avatar">{userAccount.user && config.rolesLabel[userAccount.user.role]}</Avatar>
            </div>
            <div>
              <span className="message">{notification.message}</span>
              <span className="date">
                <span className="tag">{notification.type}</span>
                <TimeAgo datetime={notification.date} />
              </span>
            </div>

            {notification.read ? null : <Badge className="read-icon" status="processing" />}
          </NotificationRow>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Wrapper>
      <Layout.Header>
        <InnerWrapper>
          <div className="left">
            <a
              className="ant-dropdown-link"
              style={{ color: '#000' }}
              onClick={() => globalUI.setMenuDrawerVisible(true)}
            >
              <MenuOutlined />
            </a>
          </div>

          {config.stage === 'staging' ? (
            <h3 style={{ padding: 10, background: 'rgba(0,0,0,0.2)' }}>
              You are accessing R&M UAT. Please click <a href="https://repairs.my.hiflyer.ca">link</a> to go to the live
              version.
            </h3>
          ) : null}
          <div className="right">
            <Dropdown
              placement="bottomRight"
              trigger={['click']}
              overlay={notificationsMenu}
              overlayStyle={{ marginTop: 21 }}
            >
              <a className="notifications" onClick={e => e.preventDefault()}>
                <Badge count={userAccount.user?.unreadNotificationsCount}>
                  <BellOutlined style={{ fontSize: 24 }} />
                </Badge>
              </a>
            </Dropdown>
            <Dropdown trigger={['click']} overlay={menu} overlayStyle={{ marginTop: 20 }}>
              <a className="profile-link ant-dropdown-link" onClick={e => e.preventDefault()}>
                {/* <Avatar className="avatar">S1</Avatar> */}
                <span className="name">{userAccount.user?.email}</span>
                <CaretDownOutlined />
              </a>
            </Dropdown>
          </div>
        </InnerWrapper>
      </Layout.Header>
    </Wrapper>
  );
});
