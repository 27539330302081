import React, { useState } from 'react';
import styled from 'styled-components';
import { Tooltip, Button, Input } from 'antd';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FaTrademark, FaPlus, FaMoneyBillWaveAlt, FaToolbox, FaTag, FaBuilding } from 'react-icons/fa';
import { ListChildComponentProps } from 'react-window';
import { FixedSizeList as List } from 'react-window';

import StatusLabel from './StatusLabel';
import config from '../../config/config';
import { RowWrapper, ListWrapper, Wrapper } from './styles';
import { PropertyTransferRequestItem, AssetType } from '../../types';

const propertyTransferItemStatus = config.propertyTransferItemStatus;

interface PropertyTransferItemProps {
  items: PropertyTransferRequestItem[];
  readonly?: boolean;
  onChange?: (data: PropertyTransferRequestItem) => void;
}

const C: React.FC<PropertyTransferItemProps> = observer(({ items, readonly, onChange }) => {
  const Row: React.ComponentType<ListChildComponentProps> = ({ index, style }) => {
    const item = items[index];
    const asset = item.asset;

    return (
      <RowWrapper style={style}>
        <span className="row title-row">
          <Tooltip title="Asset No">
            <span>
              <span className="title">{asset.assetTaggingNo}</span>
              <StatusLabel status={item.status} />
            </span>
          </Tooltip>

          {!readonly && (
            <div className="actions">
              {(item.status === propertyTransferItemStatus.PENDING ||
                item.status === propertyTransferItemStatus.REJECTED) && (
                <Button
                  type="primary"
                  className="mr-2"
                  size="small"
                  onClick={() => onChange && onChange({ ...item, status: propertyTransferItemStatus.APPROVED })}
                >
                  Approve
                </Button>
              )}
              {(item.status === propertyTransferItemStatus.PENDING ||
                item.status === propertyTransferItemStatus.APPROVED) && (
                <Button
                  type="link"
                  danger
                  size="small"
                  onClick={() => onChange && onChange({ ...item, status: propertyTransferItemStatus.REJECTED })}
                >
                  Reject
                </Button>
              )}
            </div>
          )}
        </span>
        <p className="row">
          <Tooltip title="Classification">
            <span>
              <FaToolbox className="icon" />
              <span className="value">{asset.classification?.description}</span>
            </span>
          </Tooltip>
        </p>
        <p className="row">
          <Tooltip title="Description/Brand Name">
            <span>
              <FaTrademark className="icon" />
              <span className="value">{asset.assetDescription}</span>
            </span>
          </Tooltip>
          <Tooltip title="Brand">
            <span className="value">{asset.brand}</span>
          </Tooltip>
        </p>
        <p className="row">
          <Tooltip title="cost">
            <span>
              <FaMoneyBillWaveAlt className="icon" />
              <span className="value">N/A</span>
            </span>
          </Tooltip>
        </p>
      </RowWrapper>
    );
  };
  Row.propTypes = {
    index: PropTypes.any,
    style: PropTypes.any,
  };

  return (
    <Wrapper>
      {/* <label htmlFor="" className="items-title">
        {readonly ? (
          <span className="items-selected">
            <span className="label">Items to transfer: </span>
            <b className="mr-2">{items.length}</b>
          </span>
        ) : (
          <span className="items-selected">
            <span className="label">Items to transfer: </span>
            <b className="item-count">
              {items.filter(i => i.status === propertyTransferItemStatus.APPROVED).length}
            </b>/ {items.length}
          </span>
        )}
      </label> */}
      <ListWrapper>
        <List height={items.length > 1 ? 500 : 170} itemCount={items.length} itemSize={170} width="100%">
          {Row}
        </List>
      </ListWrapper>
    </Wrapper>
  );
});

C.propTypes = {
  items: PropTypes.any.isRequired,
  readonly: PropTypes.bool,
  onChange: PropTypes.func,
};

C.defaultProps = {
  readonly: true,
};

export const PropertyTransferItemList = C;
