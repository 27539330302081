import React from 'react';
import moment from 'moment';
import { Button, Table, Popconfirm } from 'antd';
import PropTypes from 'prop-types';

import { EmptyMessage } from '../../../components';
import { UserType, AssetType, MaintenanceRequestTimekeepingType } from '../../../types';

interface TimekeepingTableProps {
  data: MaintenanceRequestTimekeepingType[];
  readonly?: boolean;
  onCreateButtonClicked?: () => void;
  onEditTimekeepingClicked?: (timekeeping: MaintenanceRequestTimekeepingType) => void;
  onDeleteTimekeepingClicked?: (timekeeping: MaintenanceRequestTimekeepingType) => void;
}

const TimekeepingTable: React.FC<TimekeepingTableProps> = ({
  data,
  readonly,
  onCreateButtonClicked,
  onEditTimekeepingClicked,
  onDeleteTimekeepingClicked,
}) => {
  const columns = [
    {
      title: 'Asset',
      dataIndex: 'asset',
      render: (asset: AssetType | null) =>
        asset ? `${asset.assetTaggingNo} - ${asset.assetDescription}` : 'not specified',
    },
    {
      title: 'Technician',
      dataIndex: 'technician',
      render: (technician: UserType | null) => (technician ? `${technician.firstName} ${technician.lastName}` : ''),
    },
    {
      title: 'Time-out Office',
      dataIndex: 'timeOutOffice',
      render: (value: string) => moment(value).format('YYYY-MM-DD h:mm a'),
    },
    {
      title: 'Time-in Office',
      dataIndex: 'timeInOffice',
      render: (value: string) => moment(value).format('YYYY-MM-DD h:mm a'),
    },
    {
      title: 'Breaks (minutes)',
      dataIndex: 'breaktimeNumberOfMinutes',
      render: (value: number) => value,
    },
    {
      title: 'Work Done',
      dataIndex: 'workDone',
      render: (workDone: string) => workDone,
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render: function actions(id: string, timekeeping: MaintenanceRequestTimekeepingType) {
        return (
          <>
            <Button
              onClick={() => {
                onEditTimekeepingClicked && onEditTimekeepingClicked(timekeeping);
              }}
              type="link"
            >
              Edit
            </Button>
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => onDeleteTimekeepingClicked && onDeleteTimekeepingClicked(timekeeping)}
            >
              <Button type="link" danger>
                Delete
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  if (readonly) {
    columns.pop();
  }

  return data.length ? (
    <Table columns={columns} dataSource={data} size="small"></Table>
  ) : (
    <EmptyMessage>
      <img
        className="image"
        src="https://staging-repairs-and-maintenance-public.s3-ap-southeast-1.amazonaws.com/static/images/empty-items.png"
      />
      <span className="text"> You have no timekeeping yet.</span>
      {onCreateButtonClicked && !readonly ? (
        <Button onClick={() => onCreateButtonClicked()} className="button" type="link">
          Create timekeeping
        </Button>
      ) : null}
    </EmptyMessage>
  );
};

TimekeepingTable.propTypes = {
  data: PropTypes.any.isRequired,
  onCreateButtonClicked: PropTypes.func,
  onEditTimekeepingClicked: PropTypes.func,
  onDeleteTimekeepingClicked: PropTypes.func,
  readonly: PropTypes.bool,
};

TimekeepingTable.defaultProps = {
  readonly: true,
};

export default TimekeepingTable;
