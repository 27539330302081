import React, { useState, useEffect } from 'react';
import { Table, Skeleton, Select, notification, Button } from 'antd';
import styled from 'styled-components';
import { DatePicker, Space } from 'antd';

import DashboardLayout from '../../layouts/Dashboard';
import { Page, Card } from '../../components';
import { UserType, PropertyTransferRequestItem, PropertyTransferRequest } from '../../types';
import { useMst } from '../../store/RootStore';
import Axios from 'axios';

const { RangePicker } = DatePicker;

const columns = [
  {
    title: 'Store',
    dataIndex: 'store_number',
    render: (store: string) => store,
  },
  {
    title: 'MR Number',
    dataIndex: 'mr_number',
    render: (mrNumber: string) => mrNumber,
  },
  {
    title: 'Date',
    dataIndex: 'date',
    render: (date: string) => date,
  },
  {
    title: 'Asset Number',
    dataIndex: 'asset_number',
    render: (value: string) => value,
  },
  {
    title: 'Classification',
    dataIndex: 'classification',
    render: (value: string) => value,
  },
  {
    title: 'Total Repair Cost',
    dataIndex: 'total_repair_cost',
    render: (value: string) => value,
  },
];

const Wrapper = styled.div``;

const RepairSummary: React.FC = () => {
  const store = useMst();

  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);

  const [selectedStores, setSelectedStores] = useState<any[]>([]);
  const [from, setFrom] = useState<string | null>(null);
  const [to, setTo] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const token = store.userAccount.token;
      const response = await Axios.get('/api/references/stores/list', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setStores(response.data);

      if (response.data.length === 1) {
        setSelectedStores([response.data[0].id]);
      }
    })();
  }, []);

  const handleSelectStore = (values: any) => {
    console.log(values);
    setSelectedStores(values);
  };

  const handleDateRangeChange = (values: any, valuesStr: any) => {
    console.log(valuesStr);
    setFrom(valuesStr[0]);
    setTo(valuesStr[1]);
  };

  const handleSearch = async () => {
    const token = store.userAccount.token;
    const response = await Axios.get('/api/reports/repairs/summary', {
      params: { stores: selectedStores.join(','), from, to },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log('report', response);
    setData(response.data.data);
  };

  const handleExport = async () => {
    const token = store.userAccount.token;
    const response = await Axios.get('/api/reports/repairs/summary/export', {
      responseType: 'blob',
      params: { stores: selectedStores.join(','), from, to },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'report-summary.csv');
    document.body.appendChild(link);
    link.click();
  };

  return (
    <DashboardLayout>
      <Page>
        <Card style={{ zIndex: 2 }}>
          <div className="card-body">
            <Wrapper>
              <p className="card-title">Repair Summary</p>
              <div>
                <label className="form-title mr-5">Store:</label>
                <Select
                  style={{ minWidth: 300 }}
                  placeholder="Select store/s"
                  mode="multiple"
                  value={selectedStores}
                  onChange={handleSelectStore}
                >
                  {stores.map((store: any) => (
                    <Select.Option key={store.id} value={store.id}>
                      {store.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="mt-3">
                <label className="form-title mr-3">Date Range:</label>
                <RangePicker onChange={handleDateRangeChange} />
              </div>
              <div className="mt-3">
                <Button className="mr-5" type="primary" onClick={handleSearch}>
                  Search
                </Button>
                {data.length > 0 && (
                  <Button type="primary" onClick={handleExport}>
                    Export
                  </Button>
                )}
              </div>
            </Wrapper>
          </div>
        </Card>
        <Card style={{ zIndex: 2 }}>
          <div className="card-body">
            <Wrapper>
              {loading ? (
                <Skeleton />
              ) : (
                <Table columns={columns} rowKey={v => v.id} dataSource={data} size="small"></Table>
              )}
            </Wrapper>
          </div>
        </Card>
      </Page>
    </DashboardLayout>
  );
};

export default RepairSummary;
