import { types, flow, Instance, onSnapshot } from 'mobx-state-tree';
import { createContext, useContext } from 'react';
import { AxiosResponse } from 'axios';

import axios from '../utils/axios';
import { LoginResponse, LoginParams } from './../types';

import { UserAccount } from './models';
import { GlobalUI } from './models';
import { References } from './models/References';

export const RootModel = types
  .model({
    userAccount: UserAccount,
    globalUI: GlobalUI,
    references: References,
  })
  .actions(self => ({
    login: flow<AxiosResponse<LoginResponse>, any[]>(function*(
      data: LoginParams,
    ): Generator<Promise<AxiosResponse<LoginResponse>>> {
      const response = yield axios.post<LoginResponse>(`/api/login`, data);
      return response;
    }),
  }));

export const rootStore = RootModel.create({
  userAccount: UserAccount.create(),
  globalUI: GlobalUI.create(),
  references: References.create(),
});

onSnapshot(rootStore, snapshot => console.log('Snapshot: ', snapshot));

export type RootInstance = Instance<typeof RootModel>;
const RootStoreContext = createContext<null | RootInstance>(null);

export const Provider = RootStoreContext.Provider;

export function useMst() {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null');
  }
  return store;
}
