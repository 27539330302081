import { types, applySnapshot } from 'mobx-state-tree';
import { AssetType } from '../../types';

export const AssetClassification = types.model({
  id: types.number,
  code: types.string,
  description: types.string,
});

export const TrackingId = types.model({
  trackingId: types.string,
  assetReferenceNumber: types.string,
});

export const Asset = types
  .model({
    id: types.number,
    assetId: types.string,
    department: types.string,
    classification: types.maybeNull(AssetClassification),
    assetDescription: types.string,
    assetTaggingNo: types.string,
    brand: types.string,
    isGeneric: types.number,
  })
  .actions(self => ({
    save(data: AssetType) {
      applySnapshot(self, data);
    },
  }));
