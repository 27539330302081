import React from 'react';
import styled from 'styled-components';

export const EmptyMessage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  justify-content: center;
  align-items: center;
  font-size: 20px;

  .image {
    width: 100px;
  }

  .text {
    display: block;
    margin: 20px 0px;
    opacity: 0.7;
  }

  .button {
    width: 150px;
  }
`;
