import * as yup from 'yup';
import { observer } from 'mobx-react';
import mapValues from 'lodash/mapValues';
import React, { useState, useEffect, useCallback } from 'react';
import { Skeleton, Divider, Table, Modal, Input, Select, Button, Tooltip, Upload, notification } from 'antd';
import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import { FaTrademark, FaPlus, FaMoneyBillWaveAlt, FaToolbox, FaTag, FaBuilding } from 'react-icons/fa';

import { Controller, useForm, useFieldArray, FieldError } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { AssetsSection, Wrapper } from '../../MaintenanceRequest/styles';
import config from '../../../config/config';
import {
  UserType,
  AssetType,
  CreateMaintenanceRequestItemFormData,
  AdminCreateMaintenanceRequestFormData,
  MaintenanceRequestHeaderType,
} from '../../../types';
import { useMst } from '../../../store/RootStore';
import { RowWrapper as AssetItemRowWrapper } from '../../../components/AssetsSelect/styles';
import DashboardLayout from '../../../layouts/Dashboard';
import {
  EmptyMessage,
  Section,
  AssetsSelect,
  HorizontalLine,
  FormSection,
  FormRow,
  Page,
  Card,
} from '../../../components';

const schema = yup.object().shape({
  store: yup.string().required('Store is required'),
  requestorName: yup.string().required('Request name is required'),
  types: yup.array().of(
    yup.object().shape({
      type: yup.string().required('Type is required'),
      items: yup.lazy((obj: any) =>
        yup
          .object(
            mapValues(obj, () => {
              return yup.object({
                asset: yup.object().required('Asset is required'),
                description: yup.string().required('Description is required'),
                classification: yup
                  .number()
                  .oneOf([0, 1, 2], 'Please select a classification')
                  .required('Classification is required'),
                photo: yup
                  .mixed()
                  .required('Photo is required')
                  .test('fileSize', 'File is too large', value => value && value.size <= config.maxFileSize)
                  .test(
                    'fileFormat',
                    'Unsupported format',
                    value => value && config.supportedImageFormats.includes(value.type),
                  ),
              });
            }),
          )
          .test('items', 'Please select an item', value => value && Object.keys(value).length > 0),
      ),
    }),
  ),
});

const AdminCreateMaintenanceRequest: React.FC = observer(() => {
  const store = useMst();
  const references = store.references;
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [maintenanceRequest, setMaintenanceRequest] = useState<MaintenanceRequestHeaderType | null>(null);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [assets, setAssets] = useState<AssetType[]>([]);

  const [assetsModalVisible, setAssetsModalVisibility] = useState<boolean[]>([false]);
  const [assetsUploadImages, setAssetUploadImages] = useState<{
    [key: string]: { file: string | ArrayBuffer | null; loading: boolean };
  } | null>({});

  const { control, handleSubmit, errors } = useForm<AdminCreateMaintenanceRequestFormData>({
    validationSchema: schema,
    defaultValues: {
      types: [
        {
          type: 0,
          items: {},
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'types',
  });

  const fetchAssets = async (userId: number) => {
    setLoading(true);
    const response = await store.userAccount.getUserAssets(userId);
    setAssets(response.data.data);
    remove();
    append({
      type: 0,
      items: {},
    });
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  const onDisplayReview = async (values: AdminCreateMaintenanceRequestFormData) => {
    setSubmitLoading(true);
    const response = await store.userAccount.createMaintenanceRequest(values);
    setSubmitLoading(false);
    setMaintenanceRequest(response.data.data);
    setConfirmationModalVisible(true);
  };

  const setModalVisibility = (index: number, value: boolean) => {
    const assetsModalVisilibilityValues = assetsModalVisible;
    assetsModalVisilibilityValues[index] = value;
    setAssetsModalVisibility(assetsModalVisilibilityValues);
    forceUpdate();
  };

  const handleOpenAssetsModal = (index: number) => {
    setModalVisibility(index, true);
  };

  const handleAddNewType = () => {
    append({
      type: 0,
      items: {},
    });
    const tmp = [...assetsModalVisible];
    tmp.push(false);
    setAssetsModalVisibility(tmp);
  };

  const handleRemoveType = (index: number) => {
    remove(index);
    setAssetsModalVisibility(assetsModalVisible.filter((v, i) => index != i));
    setTimeout(() => forceUpdate());
  };

  const controlValues = control.getValues();
  let selectAssetIds: string[] = [];
  for (let i = 0; i < fields.length; i++) {
    const key = `types[${i}].items`;
    const items = controlValues[key];
    if (items) {
      selectAssetIds = [...selectAssetIds, ...Object.keys(controlValues[key]).map(key => key.split('_')[1])];
    } else {
      selectAssetIds = [...selectAssetIds];
    }
  }
  function getBase64(img: File, callback: (res: string | ArrayBuffer | null) => void) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  console.log(errors);

  return (
    <DashboardLayout>
      <Page>
        <Wrapper>
          <form onSubmit={handleSubmit(onDisplayReview)}>
            <>
              <Card>
                <div className="card-body">
                  <div className="card-title">Maintenance Request</div>
                  <FormSection>
                    <FormRow className={errors['store'] ? 'has-error' : ''}>
                      <label className="form-title">Store:</label>
                      <Controller
                        name="store"
                        control={control}
                        onChange={data => {
                          control.setValue('items', {});
                          fetchAssets(data[0]).then(() => {
                            console.log('Load new assets');
                          });
                          return data;
                        }}
                        as={
                          <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ minWidth: 300 }}
                            placeholder="Select store"
                          >
                            <Select.OptGroup label="Stores">
                              {references.storeUsers.map((user: UserType) => (
                                <Select.Option key={user.id} value={user.id}>
                                  {user.fullName}({user.email})
                                </Select.Option>
                              ))}
                            </Select.OptGroup>
                          </Select>
                        }
                      />
                      {errors['store'] ? <span className="error-message">{errors['store'].message}</span> : null}
                    </FormRow>
                    <FormRow className={errors['requestorName'] ? 'has-error' : ''}>
                      <label className="form-title">Requestor name:</label>
                      <Controller control={control} name="requestorName" as={<Input style={{ width: 300 }} />} />
                      {errors['requestorName'] ? (
                        <span className="error-message">{errors['requestorName'].message}</span>
                      ) : null}
                    </FormRow>
                  </FormSection>
                </div>
              </Card>
              {loading ? (
                <Card>
                  <div className="card-body"></div>
                </Card>
              ) : (
                <Card>
                  <div className="card-body">
                    {fields.map((field, index) => {
                      const items: { [key: string]: CreateMaintenanceRequestItemFormData } = {
                        ...controlValues[`types[${index}].items`],
                      };
                      return (
                        <Section
                          key={field.id}
                          className="mb-3"
                          style={{ paddingTop: 10, borderTop: '1px solid rgba(0,0,0,0.05' }}
                        >
                          <FormSection>
                            <FormRow
                              className={
                                errors['types'] && errors.types && errors['types'][index] && errors['types'][index].type
                                  ? 'has-error'
                                  : ''
                              }
                            >
                              <div className="d-flex flex-direction-row justify-content-between">
                                <div>
                                  <label className="form-title">Request type:</label>
                                  <Controller
                                    name={`types[${index}].type`}
                                    control={control}
                                    defaultValue="0"
                                    as={
                                      <Select style={{ minWidth: 300 }} placeholder="Select type">
                                        <Select.Option key="inhouse" value="0">
                                          In-house
                                        </Select.Option>
                                        <Select.Option key="vendor" value="1">
                                          Vendor
                                        </Select.Option>
                                      </Select>
                                    }
                                  />
                                  {errors['types'] && errors.types && errors['types'][index] ? (
                                    <span className="error-message mt-2">{errors['types'][index].type?.message}</span>
                                  ) : null}
                                </div>
                                {fields.length > 1 && index > 0 ? (
                                  <Button danger type="link" onClick={() => handleRemoveType(index)}>
                                    <MinusCircleFilled />
                                    Remove
                                  </Button>
                                ) : null}
                              </div>
                            </FormRow>
                          </FormSection>
                          <Controller
                            name={`types[${index}].items`}
                            control={control}
                            onChange={data => {
                              setModalVisibility(index, false);
                              const assets = data[0];
                              const newItems: { [key: string]: CreateMaintenanceRequestItemFormData } = {};
                              Object.keys(assets).forEach((key: string) => {
                                newItems[`asset_${key}`] = {
                                  asset: assets[key],
                                  description: '',
                                  classification: -1,
                                };
                              });
                              if (items && Object.keys(newItems).length) {
                                return { ...items, ...newItems };
                              } else {
                                return items;
                              }
                            }}
                            as={
                              <AssetsSelect
                                assets={
                                  items ? assets.filter(asset => !selectAssetIds.includes(asset.assetId)) : assets
                                }
                                visible={assetsModalVisible[index]}
                                onCancel={() => setModalVisibility(index, false)}
                              />
                            }
                          />
                          <AssetsSection>
                            <p className="title">
                              Your selected assets:
                              {Object.keys(items).length ? (
                                <Button type="default" size="small" onClick={() => handleOpenAssetsModal(index)}>
                                  Select assets
                                </Button>
                              ) : null}
                            </p>
                            <Divider />
                            {Object.keys(items).length ? (
                              <>
                                {Object.keys(items).map((key: string) => {
                                  const item = { ...items[key] };
                                  const asset = item.asset;
                                  const assetErrors =
                                    errors.types && errors.types[index] && errors.types[index].items
                                      ? (errors.types[index].items as any)[`asset_${asset.assetId}`]
                                      : null;
                                  return (
                                    <AssetItemRowWrapper
                                      key={key}
                                      style={{
                                        padding: 0,
                                        borderRadius: 5,
                                        borderBottom: '1px solid #ccc',
                                        marginBottom: 10,
                                      }}
                                    >
                                      <div className="d-flex" style={{ background: 'rgba(0,0,0,0.05)', padding: 20 }}>
                                        <div className="image-wrapper" style={{ width: 100, marginRight: 20 }}>
                                          <FormRow className={assetErrors && assetErrors['photo'] ? 'has-error' : ''}>
                                            <Upload
                                              listType="picture-card"
                                              showUploadList={false}
                                              onChange={info => {
                                                const image = info.fileList[info.fileList.length - 1]
                                                  .originFileObj as File;
                                                if (image) {
                                                  getBase64(image, imageUrl => {
                                                    const tmp = {
                                                      ...assetsUploadImages,
                                                      [asset.assetId]: {
                                                        file: imageUrl,
                                                        loading: false,
                                                      },
                                                    };
                                                    setAssetUploadImages(tmp);
                                                  });
                                                  const tmp = { ...control.getValues()[`types[${index}].items`] };
                                                  tmp[`asset_${asset.assetId}`].photo = image;
                                                  control.setValue(`types[${index}].items`, tmp);
                                                }
                                              }}
                                              beforeUpload={file => {
                                                return false;
                                              }}
                                            >
                                              {assetsUploadImages &&
                                              assetsUploadImages[asset.assetId] &&
                                              assetsUploadImages[asset.assetId].file ? (
                                                <img
                                                  src={assetsUploadImages[asset.assetId].file as string}
                                                  alt="avatar"
                                                  style={{ width: '100%' }}
                                                />
                                              ) : (
                                                <div className="ant-upload-text">Upload Asset Photo</div>
                                              )}
                                            </Upload>
                                            {assetErrors && assetErrors['photo'] ? (
                                              <span className="error-message mt-2">
                                                {assetErrors['photo']?.message}
                                              </span>
                                            ) : null}
                                          </FormRow>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                          <span className="asset-row title-row">
                                            <Tooltip title="Asset ID">
                                              <span className="title">{asset.assetId}</span>
                                            </Tooltip>
                                            <Button
                                              type="primary"
                                              danger
                                              size="small"
                                              onClick={() => {
                                                const tmp = { ...items };
                                                delete tmp[`asset_${asset.assetId}`];
                                                control.setValue(`types[${index}].items`, tmp);
                                                forceUpdate();
                                              }}
                                            >
                                              Remove
                                            </Button>
                                          </span>
                                          <p className="asset-row">
                                            <span className="value">
                                              <Tooltip title="Asset Tagging No.">
                                                <span>
                                                  <FaTag className="icon" />
                                                  {asset.assetTaggingNo}
                                                </span>
                                              </Tooltip>
                                            </span>

                                            <span className="value">
                                              <Tooltip title="Department">
                                                <span>
                                                  <FaBuilding className="icon" />
                                                  {asset.department}
                                                </span>
                                              </Tooltip>
                                            </span>
                                          </p>
                                          <p className="asset-row">
                                            <Tooltip title="Classification">
                                              <span>
                                                <FaToolbox className="icon" />
                                                <span className="value">{asset.classification?.description}</span>
                                              </span>
                                            </Tooltip>
                                          </p>
                                          <p className="asset-row">
                                            <Tooltip title="Description/Brand Name">
                                              <span>
                                                <FaTrademark className="icon" />
                                                <span className="value">{asset.assetDescription}</span>
                                              </span>
                                            </Tooltip>
                                            <Tooltip title="Brand">
                                              <span className="value">{asset.brand}</span>
                                            </Tooltip>
                                          </p>
                                          <p className="asset-row">
                                            <Tooltip title="cost">
                                              <span>
                                                <FaMoneyBillWaveAlt className="icon" />
                                                <span className="value">N/A</span>
                                              </span>
                                            </Tooltip>
                                          </p>
                                        </div>
                                      </div>
                                      <div style={{ padding: 20 }}>
                                        <p className="asset-row">Please fill up the following information:</p>
                                        <Divider />
                                        <FormRow
                                          className={assetErrors && assetErrors['classification'] ? 'has-error' : ''}
                                        >
                                          <label className="form-title">{'Repair type'}</label>
                                          <Select
                                            placeholder="Select..."
                                            style={{ width: 250 }}
                                            onChange={value => {
                                              const tmp = { ...control.getValues()[`types[${index}].items`] };
                                              tmp[`asset_${asset.assetId}`].classification = value;
                                              control.setValue(`types[${index}].items`, tmp);
                                            }}
                                          >
                                            <Select.Option value="0">Repair</Select.Option>
                                            <Select.Option value="1">Preventive Maintenance</Select.Option>
                                            <Select.Option value="2">Regular Maintenance</Select.Option>
                                          </Select>
                                          {assetErrors && assetErrors['classification'] ? (
                                            <span className="error-message mt-2">
                                              {assetErrors['classification']?.message}
                                            </span>
                                          ) : null}
                                        </FormRow>
                                        <FormRow
                                          className={assetErrors && assetErrors['description'] ? 'has-error' : ''}
                                        >
                                          <label className="form-title">Description</label>
                                          <Input.TextArea
                                            onChange={e => {
                                              const tmp = { ...control.getValues()[`types[${index}].items`] };
                                              tmp[`asset_${asset.assetId}`].description = e.target.value;
                                              control.setValue(`types[${index}].items`, tmp);
                                            }}
                                            placeholder="Describe the issue"
                                            rows={3}
                                          ></Input.TextArea>
                                          {assetErrors && assetErrors['description'] ? (
                                            <span className="error-message mt-2">
                                              {assetErrors['description']?.message}
                                            </span>
                                          ) : null}
                                        </FormRow>
                                      </div>
                                    </AssetItemRowWrapper>
                                  );
                                })}
                              </>
                            ) : (
                              <EmptyMessage>
                                <img
                                  className="image"
                                  src="https://staging-repairs-and-maintenance-public.s3-ap-southeast-1.amazonaws.com/static/images/empty-items.png"
                                />
                                <span className="text"> You have no items selected yet.</span>
                                <Button onClick={() => handleOpenAssetsModal(index)} className="button" type="link">
                                  Select assets
                                </Button>
                              </EmptyMessage>
                            )}
                          </AssetsSection>

                          {errors.types && errors.types[index] && errors.types[index]['items'] ? (
                            <span className="error-message mt-2">{errors.types[index].items?.message}</span>
                          ) : null}
                        </Section>
                      );
                    })}
                    <Button onClick={handleAddNewType} className="mt-3" type="link">
                      <PlusCircleFilled />
                      Add new service request
                    </Button>
                  </div>
                  <div className="card-footer d-flex justify-content-end">
                    <Button loading={submitLoading} htmlType="submit" type="primary">
                      Create request
                    </Button>
                  </div>
                </Card>
              )}
            </>
          </form>
        </Wrapper>
      </Page>
      <Modal
        title="Notification"
        visible={confirmationModalVisible}
        onOk={() => history.push(`/maintenance_requests/${maintenanceRequest?.uuid}`)}
        onCancel={() => false}
        cancelButtonProps={{ hidden: true }}
      >
        <p>
          Please call ENG MR Supervisor for EMERGENCY & Priority 1 Level based on date and time stamp (after 6pm on
          weekdays and any time during weekends)
        </p>
      </Modal>
    </DashboardLayout>
  );
});

export default AdminCreateMaintenanceRequest;
