import React, { useState, useEffect } from 'react';
import localforage from 'localforage';
import { AxiosResponse } from 'axios';
import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom';

import config from './config/config';
import AuthenticatedRoute from './hoc/AuthenticatedRoute';
import Loading from './pages/Loading';
import Assets from './pages/Assets';
import Login from './pages/Auth/Login';
import Dashboard from './pages/Dashboard';
import MyPropertyTransferRequests from './pages/PropertyTransfer/';
import CreatePropertyTransferRequest from './pages/PropertyTransfer/create';
import PropertyTransferRequestDetails from './pages/PropertyTransfer/show';
import PropertyTransferApprovals from './pages/Approvals/PropertyTransfer';
import MaintenanceRequestsApprovals from './pages/Approvals/MaintenanceRequest';
import WorkApprovals from './pages/Approvals/Work';
import CostsApproval from './pages/Approvals/Costs';
import MyMaintenanceRequests from './pages/MaintenanceRequest/index';
import CreateMaintenanceRequest from './pages/MaintenanceRequest/create';
import MaintenanceRequestDetails from './pages/MaintenanceRequest/show';
import WorkManagementInProgressTable from './pages/WorkManagement/WorkInProgressTable';
import WorkManagementForFinalCostingTable from './pages/WorkManagement/ForFinalCostingTable';
import WorkManagementInProgressDetails from './pages/WorkManagement/WorkDetails';
import AllPropertyTransferRequests from './pages/Admin/PropertyTransferRequest';
import AdminCreatePropertyTransferRequest from './pages/Admin/PropertyTransferRequest/create';
import AdminCreateMaintenanceRequest from './pages/Admin/MaintenanceRequest/create';

import { Provider, rootStore } from './store/RootStore';

import { UserAccountType, LoginResponse } from './types';
import AllMaintenanceRequests from './pages/Admin/MaintenanceRequest';
import AllMaintenanceRequestTypes from './pages/Admin/MaintenanceRequest/types';
import RepairSummary from './pages/Reports/RepairSummary';
import AssetHistorySummary from './pages/Reports/AssetHistorySummary';
import PropertyTransfer from './pages/Reports/PropertyTransfer';

const App: React.FC = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  useEffect(() => {
    localforage
      .getItem<UserAccountType>(config.authTokenKey)
      .then((data: UserAccountType) => {
        if (data && data.token) {
          return rootStore.userAccount.getUserAccountUsingToken(data.token);
        }
        throw new Error('No token saved');
      })
      .then((response: AxiosResponse<LoginResponse>) => {
        const data = response.data.data as UserAccountType;
        rootStore.userAccount.save(data);

        rootStore.userAccount.getNotifications();
        rootStore.references.initialize();

        setIsPageLoading(false);
      })
      .catch(err => {
        rootStore.userAccount.logout();
        setIsPageLoading(false);
      });
  }, []);

  if (isPageLoading) {
    return <Loading />;
  }

  return (
    <Provider value={rootStore}>
      <Router>
        <Switch>
          <AuthenticatedRoute exact path="/">
            <Dashboard />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/dashboard">
            <Dashboard />
          </AuthenticatedRoute>
          <Route exact path="/login">
            <Login />
          </Route>
          <AuthenticatedRoute exact path="/assets">
            <Assets />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/approvals/property_transfer_requests">
            <PropertyTransferApprovals />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/approvals/maintenance_requests/pending">
            <MaintenanceRequestsApprovals type="pending" />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/approvals/maintenance_requests/arl">
            <MaintenanceRequestsApprovals type="arl" />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/approvals/maintenance_requests/rdo">
            <MaintenanceRequestsApprovals type="rdo" />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/approvals/work_management">
            <WorkApprovals />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/approvals/costs">
            <CostsApproval />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/property_transfers">
            <MyPropertyTransferRequests />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/property_transfers/create">
            <CreatePropertyTransferRequest />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/property_transfers/:id">
            <PropertyTransferRequestDetails />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/maintenance_requests">
            <MyMaintenanceRequests />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/maintenance_requests/create">
            <CreateMaintenanceRequest />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/maintenance_requests/:id">
            <MaintenanceRequestDetails />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/work_management/in_progress">
            <WorkManagementInProgressTable />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/work_management/for_final_costing">
            <WorkManagementForFinalCostingTable />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/work_management/:id">
            <WorkManagementInProgressDetails />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/admin/property_transfer_requests">
            <AllPropertyTransferRequests />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/admin/property_transfer_requests/create">
            <AdminCreatePropertyTransferRequest />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/admin/maintenance_requests">
            <AllMaintenanceRequests />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/admin/maintenance_request_types">
            <AllMaintenanceRequestTypes />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/admin/maintenance_requests/create">
            <AdminCreateMaintenanceRequest />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/reports/repair_summary">
            <RepairSummary />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/reports/property_transfers">
            <PropertyTransfer />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/reports/asset_history">
            <AssetHistorySummary />
          </AuthenticatedRoute>
        </Switch>
      </Router>
    </Provider>
  );
};

export default App;
