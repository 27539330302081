import React, { useState, useEffect } from 'react';
import { Rate, List, Divider, Statistic, Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import accounting from 'accounting-js';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import DashboardLayout from '../../layouts/Dashboard';
import { Page, Card } from '../../components';
import { useMst } from '../../store/RootStore';
import { TotalRequestCountType, TotalRepairCostType, TechnicianRatingType } from '../../types';
import config from '../../config/config';

const Wrapper = styled.div``;

const Dashboard: React.FC = observer(() => {
  const store = useMst();

  const [pendingRequestLoading, setPendingRequestLoading] = useState(false);
  const [pendingRequestsCount, setPendingRequestsCount] = useState<TotalRequestCountType | null>();

  const [forApprovalCountLoading, setForApprovalCountLoading] = useState(false);
  const [forApprovalCount, setForApprovalCount] = useState<TotalRequestCountType | null>();

  const [totalRepairCostLoading, setTotalRepairCostLoading] = useState(false);
  const [totalRepairCost, setTotalRepairCost] = useState<TotalRepairCostType | null>();

  const [technicianRatingsLoading, setTechnicianRatingsLoading] = useState(false);
  const [technicianRatings, setTechnicianRatings] = useState<TechnicianRatingType[]>([]);

  useEffect(() => {
    setPendingRequestLoading(true);
    store.userAccount.getTotalPendingRequestsCount().then(response => {
      setPendingRequestsCount(response.data.data);
      setPendingRequestLoading(false);
    });
    setForApprovalCountLoading(true);
    store.userAccount.getTotalForApprovalCount().then(response => {
      setForApprovalCount(response.data.data);
      setForApprovalCountLoading(false);
    });
    setTotalRepairCostLoading(true);
    store.userAccount.getTotalRepairCosts().then(response => {
      setTotalRepairCost(response.data.data);
      setTotalRepairCostLoading(false);
    });
    setTechnicianRatingsLoading(true);
    store.userAccount.getTechnicianRatings().then(response => {
      setTechnicianRatings(response.data.data);
      setTechnicianRatingsLoading(false);
    });
  }, []);

  return (
    <DashboardLayout>
      <Page>
        <Card>
          <div className="card-body">
            {pendingRequestLoading ? (
              <Skeleton />
            ) : (
              <>
                <label className="card-title">Pending Requests:</label>
                <div className="d-flex flex-row">
                  <Statistic
                    style={{ flex: 1 }}
                    title="Property Transfers"
                    value={pendingRequestsCount?.propertyTransferRequests}
                  />
                  <Statistic
                    style={{ flex: 1 }}
                    title="Maintenance Requests"
                    value={pendingRequestsCount?.maintenanceRequests}
                  />
                </div>
              </>
            )}
          </div>
        </Card>

        <Card>
          <div className="card-body">
            {forApprovalCountLoading ? (
              <Skeleton />
            ) : (
              <>
                <label className="card-title">My Requests for approval:</label>
                <div className="d-flex flex-row">
                  <div style={{ flex: 1 }}>
                    <Link to="/approvals/property_transfer_requests">
                      <Statistic title="Property Transfers" value={forApprovalCount?.propertyTransferRequests} />
                    </Link>
                  </div>
                  <div style={{ flex: 1 }}>
                    <Link to="/approvals/maintenance_requests">
                      <Statistic title="Maintenance Requests" value={forApprovalCount?.maintenanceRequests} />
                    </Link>
                  </div>
                  {store.userAccount.user?.role === config.roles.ENGINEERING_ADMIN ? (
                    <div style={{ flex: 1 }}>
                      <Link to="/approvals/costs">
                        <Statistic title="Cost" value={forApprovalCount?.cost} />
                      </Link>
                    </div>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </Card>
        {store.userAccount.user?.role === config.roles.ENGINEERING_ADMIN ? (
          <Card>
            <div className="card-body">
              {technicianRatingsLoading ? (
                <Skeleton />
              ) : (
                <>
                  <label className="card-title">Technicians:</label>
                  <List
                    itemLayout="horizontal"
                    dataSource={technicianRatings}
                    renderItem={item => (
                      <List.Item>
                        {item.userAccount.user?.firstName} {item.userAccount.user?.lastName}{' '}
                        <Rate className="ml-3" value={item.rating} />
                      </List.Item>
                    )}
                  />
                </>
              )}
            </div>
          </Card>
        ) : null}

        {store.userAccount.user?.role === config.roles.STORES ? (
          <Card>
            <div className="card-body">
              {totalRepairCostLoading ? (
                <Skeleton />
              ) : (
                <>
                  <label className="card-title">Repair Costs for this month:</label>
                  <div className="d-flex flex-column">
                    <div style={{ flex: 1 }} className="">
                      <Statistic
                        style={{ flex: 1 }}
                        title="Total"
                        value={accounting.formatMoney(totalRepairCost?.total)}
                      />
                    </div>
                    <Divider />
                    <div style={{ flex: 1 }} className="">
                      {totalRepairCost && Object.keys(totalRepairCost.byAsset).length > 0 ? (
                        <>
                          <label style={{ fontSize: 14, fontWeight: 'bold' }} className="d-block mb-3">
                            By Assets:
                          </label>
                          <List>
                            {Object.keys(totalRepairCost.byAsset).map((key: string) => (
                              <List.Item key={totalRepairCost.byAsset[key].asset.id}>
                                <span>
                                  {totalRepairCost.byAsset[key].asset.assetTaggingNo} -{' '}
                                  {totalRepairCost.byAsset[key].asset.assetDescription}
                                </span>
                                <span>{accounting.formatMoney(totalRepairCost.byAsset[key].total)}</span>
                              </List.Item>
                            ))}
                          </List>
                        </>
                      ) : null}
                    </div>
                    <Divider />
                    <div style={{ flex: 1 }} className="">
                      {totalRepairCost && Object.keys(totalRepairCost.byClassification).length > 0 ? (
                        <>
                          <label style={{ fontSize: 14, fontWeight: 'bold' }} className="d-block mb-3">
                            By Classification:
                          </label>
                          <List>
                            {Object.keys(totalRepairCost.byClassification).map((key: string) => (
                              <List.Item key={totalRepairCost.byClassification[key].classification.id}>
                                <span>{totalRepairCost.byClassification[key].classification.description}</span>
                                <span>{accounting.formatMoney(totalRepairCost.byClassification[key].total)}</span>
                              </List.Item>
                            ))}
                          </List>
                        </>
                      ) : null}
                    </div>
                  </div>
                </>
              )}
            </div>
          </Card>
        ) : null}
      </Page>
    </DashboardLayout>
  );
});

export default Dashboard;
