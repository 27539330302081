import React, { useState, useEffect } from 'react';
import { List, Skeleton, Drawer } from 'antd';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { Wrapper } from './styles';
import { AssetDrawerProps, AssetRepairHistoryType } from '../../types';
import { useMst } from '../../store/RootStore';

export const AssetRepairHistory: React.FC<AssetDrawerProps> = observer(({ assetId, visible, onClose }) => {
  const store = useMst();

  const [loading, setLoading] = useState(false);
  const [repairHistory, setRepairHistory] = useState<AssetRepairHistoryType[]>([]);

  useEffect(() => {
    if (assetId === -1) {
      return;
    }
    setLoading(true);
    store.userAccount.getAssetRepairHistory(assetId).then(response => {
      setRepairHistory(response.data.data);
      setLoading(false);
    });
  }, [assetId]);

  return (
    <Drawer width={450} visible={visible} onClose={onClose} placement="right">
      <Wrapper>
        <p className="title">Asset Repair History</p>

        {loading ? (
          <>
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          <List>
            {repairHistory.map(h => (
              <List.Item key={h.asset.assetTaggingNo} className="list-item">
                <div dangerouslySetInnerHTML={{ __html: h.summary }}></div>
              </List.Item>
            ))}
          </List>
        )}
      </Wrapper>
    </Drawer>
  );
});

AssetRepairHistory.propTypes = {
  assetId: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.any.isRequired,
};
