import React, { useState, useEffect } from 'react';
import { Skeleton, Table } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { Link } from 'react-router-dom';

import Links from './Links';
import DashboardLayout from '../../layouts/Dashboard';
import { Page, Card } from '../../components';
import { UserType, PropertyTransferRequestItem, PropertyTransferRequest } from '../../types';
import { useMst } from '../../store/RootStore';
import config from '../../config/config';
import { observer } from 'mobx-react';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

const columns = [
  {
    title: 'Owner',
    dataIndex: 'user',
    render: (user: UserType) => `${user.firstName} ${user.lastName}`,
  },
  {
    title: 'Recipient',
    dataIndex: 'recipient',
    render: (user: UserType) => `${user.firstName} ${user.lastName}`,
  },
  {
    title: 'Transfer type',
    dataIndex: 'transfertype',
    render: (type: string) => config.ptafTypes[type],
  },
  {
    title: 'Date applied',
    dataIndex: 'dateApplied',
    key: 'dateApplied',
    render: (date: string) => moment(date).format('YYYY-MM-DD'),
    responsive: ['md'] as Breakpoint[],
  },
  {
    title: 'No. of items',
    dataIndex: 'items',
    key: 'items',
    render: (value: PropertyTransferRequestItem[]) => value.length,
    responsive: ['md'] as Breakpoint[],
  },
  {
    title: 'Actions',
    dataIndex: 'uuid',
    render: (value: string) => <Link to={`/property_transfers/${value}`}>View</Link>, // eslint-disable-line
  },
];

const Wrapper = styled.div``;

const PropertyTransferApprovals: React.FC = observer(() => {
  const store = useMst();

  const [loading, setLoading] = useState(false);
  const [propertyTransferForApprovals, setPropertyTransferForApprovals] = useState<PropertyTransferRequest[]>([]);

  useEffect(() => {
    setLoading(true);
    store.userAccount
      .getPropertyTransferRequestForApprovals()
      .then(response => {
        setPropertyTransferForApprovals(response.data.data);
      })
      .catch(e => console.log(e))
      .then(() => setLoading(false));
  }, []);

  return (
    <DashboardLayout>
      <Page>
        <Links active="property_transfers" access={store.userAccount.access} />
        {loading ? (
          <Card>
            <div className="card-body">
              <Skeleton />
            </div>
          </Card>
        ) : (
          <Card style={{ zIndex: 2 }}>
            <div className="card-body">
              <Wrapper>
                <p className="card-title">Approvals - Property Transfers</p>
                <Table columns={columns} dataSource={propertyTransferForApprovals} size="small"></Table>
              </Wrapper>
            </div>
          </Card>
        )}
      </Page>
    </DashboardLayout>
  );
});

export default PropertyTransferApprovals;
