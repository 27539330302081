import React, { useState, useEffect } from 'react';
import TimeAgo from 'timeago-react';
import * as yup from 'yup';
import FileSaver from 'file-saver';
import { cast } from 'mobx-state-tree';
import { observer } from 'mobx-react';
import moment from 'moment';
import {
  Select,
  Input,
  InputNumber,
  Popconfirm,
  Tag,
  Tooltip,
  Skeleton,
  Button,
  Breadcrumb,
  notification,
  List,
  Modal,
} from 'antd';
import { FaTrademark, FaPlus, FaMoneyBillWaveAlt, FaToolbox, FaTag, FaBuilding } from 'react-icons/fa';
import { Link, useParams, useHistory } from 'react-router-dom';

import {
  MaintenanceRequestTypeModelType,
  MaintenanceRequestMaterialType,
  TimekeepingFormData,
  MaintenanceRequestTimekeepingType,
  MaterialUsedFormData,
  StoreApproveWorkFormData,
  StoreApproveThirdPartyWorkFormData,
  MaintenanceRequestMileageType,
  MileageFormData,
  StoreRemarksFormData,
  AssetType,
} from '../../types';

import { useMst } from '../../store/RootStore';
import config from '../../config/config';
import DashboardLayout from '../../layouts/Dashboard';
import { RowWrapper as AssetItemRowWrapper } from '../../components/AssetsSelect/styles';
import { EmptyMessage, FormSection, FormRow, Page, Card } from '../../components';

import TimekeepingTable from './components/TimekeepingTable';
import TimekeepingModal from './components/TimekeepingModal';

import MaterialsTable from './components/MaterialsTable';
import MaterialUsedModal from './components/MaterialModal';

import MileageTable from './components/MileageTable';
import MileageModal from './components/MileageModal';

import StoreApproveWorkModal from './components/StoreApproveWorkModal';
import StoreApproveThirdPartyWorkModal from './components/StoreApproveThirdPartyWorkModal';

import StoreRemarksModal from './components/StoreRemarksModal';

import ComputationBreakdown from './components/ComputationBreakdown';
import TimekeepingBreakdownModal from './components/TimekeepingBreakdownModal';

interface WorkManagementPageRole {
  canTimekeeping: boolean;
  canMaterials: boolean;
  canMileage: boolean;
  canSubmitWork: boolean;
  canStoreApproveWork: boolean;
  canSubmitFinalCosting: boolean;
  canViewComputation: boolean;
  canInputThirdPartyDetails: boolean;
  canApproveFinalCosting: boolean;
}

const thirdPartySchema = yup.object().shape({
  cost: yup
    .number()
    .required('Please enter the total work cost')
    .test('cost', 'Please enter the total work cost', value => value > 0),
  workDone: yup.string().required('Please describe the work done'),
});

const show: React.FC = observer(() => {
  const store = useMst();
  const history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [pageRole, setPageRole] = useState<WorkManagementPageRole>({
    canTimekeeping: false,
    canMileage: false,
    canMaterials: false,
    canSubmitWork: false,
    canStoreApproveWork: false,
    canSubmitFinalCosting: false,
    canViewComputation: false,
    canInputThirdPartyDetails: false,
    canApproveFinalCosting: false,
  });

  const [computationRefreshCounter, setComputationRefreshCounter] = useState(0);

  const [maintenanceRequest, setMaintenanceRequest] = useState<null | MaintenanceRequestTypeModelType>(null);

  const [timekeepingModalVisible, setTimekeepingModalVisible] = useState(false);
  const [selectedTimekeeping, setSelectedTimekeeping] = useState<null | MaintenanceRequestTimekeepingType>(null);

  const [materialUsedModalVisible, setMaterialUsedModalVisible] = useState(false);
  const [selectedMaterialUsed, setSelectedMaterialUsed] = useState<null | MaintenanceRequestMaterialType>(null);

  const [mileageModalVisible, setMileageModalVisible] = useState(false);
  const [selectedMileage, setSelectedMileage] = useState<null | MaintenanceRequestMileageType>(null);

  const [timekeepingBreakdownModalVisible, setTimekeepingBreakdownModalVisible] = useState(false);

  const [storeWorkRemarksVisible, setStoreWorkRemarksVisible] = useState(false);

  const [storeApproveWorkModalVisible, setStoreApproveWorkModalVisible] = useState(false);

  const [submitFormLoading, setSubmitFormLoading] = useState(false);

  const [storeAssets, setStoreAssets] = useState<AssetType[]>([]);
  const [selectedAsset, setSelectedAsset] = useState<AssetType | null>(null);
  const [newAsset, setNewAsset] = useState<number | null>(null);
  const [changeAssetModalVisible, setChangeAssetModalVisible] = useState<boolean>(false);

  const [thirdPartyErrors, setThirdPartyErrors] = useState<{ [key: string]: string }>({});
  const [thirdPartyDetails, setThirdPartyDetails] = useState<{ cost: number; workDone: string }>({
    cost: 0,
    workDone: '',
  });

  console.log(thirdPartyErrors);

  useEffect(() => {
    setLoading(true);
    // load request details here
    store.userAccount
      .getMaintenanceRequestTypeByReferenceNumber(id)
      .then(response => {
        const data = { ...response.data.data };
        if (store.userAccount.user) {
          const isInhouse = data.requestType === config.maintenanceRequestTypes.INHOUSE;
          // if store and creator of request and type is for store approval
          if (
            store.userAccount.user.role === config.roles.STORES &&
            store.userAccount.user.id === data.header.ownerId &&
            data.status === config.maintenanceRequestTypeStatuses.FOR_STORE_APPROVAL
          ) {
            setPageRole({
              ...pageRole,
              canStoreApproveWork: true,
            });
          }

          // if engineering admin and type is for final costing approval
          if (
            store.userAccount.user.role === config.roles.ENGINEERING_ADMIN &&
            data.status === config.maintenanceRequestTypeStatuses.FOR_ENGINEERING_ADMIN_COST_APPROVAL
          ) {
            setPageRole({
              ...pageRole,
              canApproveFinalCosting: true,
              canTimekeeping: true && isInhouse,
              canMileage: true && isInhouse,
              canMaterials: true && isInhouse,
              canViewComputation: isInhouse,
              canInputThirdPartyDetails: !isInhouse,
            });
          }
        }
        setMaintenanceRequest(data);
        setLoading(false);

        if (data.status === config.maintenanceRequestTypeStatuses.FOR_ENGINEERING_ADMIN_COST_APPROVAL) {
          store.userAccount.getUserAssets(data.userApproverMap?.user.id).then(response => {
            const assets = response.data.data;
            setStoreAssets(assets);
          });
        }
      })
      .catch(error => {
        notification['error']({
          message: 'Work Management',
          description: 'Request does not exist',
        });
        history.push('/work_management/in_progress');
      });
  }, []);

  const handleSubmitStoreWorkRemarks = async (values: StoreRemarksFormData) => {
    try {
      const response = await store.userAccount.submitStoreWorkRemarks(id, values);

      if (maintenanceRequest) {
        const mr = { ...maintenanceRequest };
        mr.storeRejectRemarks = cast([...mr.storeRejectRemarks, response.data.data]);
        setMaintenanceRequest(mr);
      }

      notification['success']({
        message: 'Work Remarks',
        description: 'Successfully notified supervisor about remarks',
      });
    } catch (e) {
      notification['error']({
        message: 'Work Remarks',
        description: 'An error occurred please try again later',
      });
    }

    setStoreWorkRemarksVisible(false);
  };

  const handleTimekeepingModalOk = async (values: TimekeepingFormData) => {
    try {
      if (selectedTimekeeping) {
        const response = await store.userAccount.updateTimekeeping(id, selectedTimekeeping.id, values);
        if (maintenanceRequest) {
          const timekeeping = response.data.data;
          const mr: MaintenanceRequestTypeModelType = cast({ ...maintenanceRequest });
          mr.timekeeping = cast(mr.timekeeping.map(t => (timekeeping.id === t.id ? timekeeping : t)));
          setMaintenanceRequest(mr);
          setComputationRefreshCounter(computationRefreshCounter + 1);
          notification['success']({
            message: 'Timekeeping',
            description: 'Successfully updated your timekeeping record',
          });
        }
      } else {
        const response = await store.userAccount.createTimekeeping(id, values);
        if (maintenanceRequest) {
          const timekeeping = response.data.data;
          const mr: MaintenanceRequestTypeModelType = cast({ ...maintenanceRequest });
          mr.timekeeping = cast([...mr.timekeeping, timekeeping]);
          setComputationRefreshCounter(computationRefreshCounter + 1);
          setMaintenanceRequest(mr);
        }
        notification['success']({
          message: 'Timekeeping',
          description: 'Successfully created your timekeeping record',
        });
      }
    } catch (e) {
      notification['error']({
        message: 'Timekeeping',
        description: 'An error occurred while creating your timekeeping record, please try again later.',
      });
    }
    setTimekeepingModalVisible(false);
  };

  const handleTimekeepingModalCancel = () => {
    setTimekeepingModalVisible(false);
  };

  const handleMileageModalOk = async (values: MileageFormData) => {
    try {
      if (selectedMileage) {
        const response = await store.userAccount.updateMileage(id, selectedMileage.id, values);
        if (maintenanceRequest) {
          const mileage = response.data.data;
          const mr: MaintenanceRequestTypeModelType = cast({ ...maintenanceRequest });
          mr.mileages = cast(mr.mileages.map(t => (mileage.id === t.id ? mileage : t)));
          setMaintenanceRequest(mr);
          setComputationRefreshCounter(computationRefreshCounter + 1);
          notification['success']({
            message: 'Mileage',
            description: 'Successfully updated your mileage record',
          });
        }
      } else {
        const response = await store.userAccount.addMileage(id, values);
        if (maintenanceRequest) {
          const mileage = response.data.data;
          const mr: MaintenanceRequestTypeModelType = cast({ ...maintenanceRequest });
          mr.mileages = cast([...mr.mileages, mileage]);
          setComputationRefreshCounter(computationRefreshCounter + 1);
          setMaintenanceRequest(mr);
        }
        notification['success']({
          message: 'Mileage',
          description: 'Successfully created your mileage record',
        });
      }
    } catch (e) {
      debugger;
      notification['error']({
        message: 'Timekeeping',
        description: 'An error occurred while creating your timekeeping record, please try again later.',
      });
    }
    setMileageModalVisible(false);
  };

  const handleMileageModalCancel = () => {
    setMileageModalVisible(false);
  };

  const handleEditMileage = (mileage: MaintenanceRequestMileageType) => {
    setSelectedMileage(mileage);
    setTimeout(() => {
      setMileageModalVisible(true);
    });
  };

  const handleDeleteMileage = async (timekeeping: MaintenanceRequestMileageType) => {
    try {
      await store.userAccount.deleteMileage(id, timekeeping.id);
      if (maintenanceRequest) {
        const mr: MaintenanceRequestTypeModelType = cast({ ...maintenanceRequest });
        mr.mileages = cast(mr.mileages.filter(t => timekeeping.id !== t.id));
        setMaintenanceRequest(mr);
        setComputationRefreshCounter(computationRefreshCounter + 1);
        notification['success']({
          message: 'Mileage',
          description: 'Successfully deleted your mileage record',
        });
      }
    } catch (e) {
      notification['error']({
        message: 'Mileage',
        description: 'An error occurred while deleting your mileage record, please try again later.',
      });
    }
  };

  const handleEditTimekeeping = (timekeeping: MaintenanceRequestTimekeepingType) => {
    setSelectedTimekeeping(timekeeping);
    setTimeout(() => {
      setTimekeepingModalVisible(true);
    });
  };

  const handleDeleteTimekeeping = async (timekeeping: MaintenanceRequestTimekeepingType) => {
    try {
      await store.userAccount.deleteTimekeeping(id, timekeeping.id);
      if (maintenanceRequest) {
        const mr: MaintenanceRequestTypeModelType = cast({ ...maintenanceRequest });
        mr.timekeeping = cast(mr.timekeeping.filter(t => timekeeping.id !== t.id));
        setMaintenanceRequest(mr);
        setComputationRefreshCounter(computationRefreshCounter + 1);
        notification['success']({
          message: 'Timekeeping',
          description: 'Successfully deleted your timekeeping record',
        });
      }
    } catch (e) {
      notification['error']({
        message: 'Timekeeping',
        description: 'An error occurred while deleting your timekeeping record, please try again later.',
      });
    }
  };

  const handleMaterialUsedModalOk = async (values: MaterialUsedFormData) => {
    try {
      if (selectedMaterialUsed) {
        const response = await store.userAccount.updateMaterialUsed(id, selectedMaterialUsed.id, values);
        if (maintenanceRequest) {
          const material = response.data.data;
          const mr: MaintenanceRequestTypeModelType = cast({ ...maintenanceRequest });
          mr.materials = cast(mr.materials.map(m => (material.id === m.id ? material : m)));
          setMaintenanceRequest(mr);
          setComputationRefreshCounter(computationRefreshCounter + 1);
          notification['success']({
            message: 'Material',
            description: 'Successfully updated your material record',
          });
        }
      } else {
        const response = await store.userAccount.addMaterialUsed(id, values);
        if (maintenanceRequest) {
          const material = response.data.data;
          const mr: MaintenanceRequestTypeModelType = cast({ ...maintenanceRequest });
          mr.materials = cast([...mr.materials, material]);
          setComputationRefreshCounter(computationRefreshCounter + 1);
          setMaintenanceRequest(mr);
        }
        notification['success']({
          message: 'Material',
          description: 'Successfully created your material record',
        });
      }
    } catch (e) {
      debugger;
      notification['error']({
        message: 'Material',
        description: 'An error occurred while creating your timekeeping record, please try again later.',
      });
    }
    setMaterialUsedModalVisible(false);
  };

  const handleEditMaterialUsed = (materialUsed: MaintenanceRequestMaterialType) => {
    setSelectedMaterialUsed(materialUsed);
    setTimeout(() => {
      setMaterialUsedModalVisible(true);
    });
  };

  const handleDeleteMaterialUsed = async (materialUsed: MaintenanceRequestMaterialType) => {
    try {
      await store.userAccount.deleteMaterialUsed(id, materialUsed.id);
      if (maintenanceRequest) {
        const mr: MaintenanceRequestTypeModelType = cast({ ...maintenanceRequest });
        mr.materials = cast(mr.materials.filter(t => materialUsed.id !== t.id));
        setMaintenanceRequest(mr);
        setComputationRefreshCounter(computationRefreshCounter + 1);
        notification['success']({
          message: 'Materials',
          description: 'Successfully deleted your material record',
        });
      }
    } catch (e) {
      notification['error']({
        message: 'Materials',
        description: 'An error occurred while deleting your material record, please try again later.',
      });
    }
  };

  const handleInitiateChangeAsset = (asset: AssetType): void => {
    setSelectedAsset(asset);
    setChangeAssetModalVisible(true);
  };

  const handleChangeAsset = async (): Promise<void> => {
    if (selectedAsset) {
      const response = await store.userAccount.changeAsset(
        maintenanceRequest?.referenceNumber,
        selectedAsset.id,
        newAsset,
      );
      const mr = response.data.data;
      setMaintenanceRequest(mr);
    }
    setChangeAssetModalVisible(false);
    setSelectedAsset(null);
  };

  const handleMaterialUsedModalCancel = (): void => {
    setMaterialUsedModalVisible(false);
  };

  const handleStoreApproveWork = async (data: StoreApproveWorkFormData | StoreApproveThirdPartyWorkFormData) => {
    setSubmitFormLoading(true);
    try {
      await store.userAccount.storeApproveWork(id, data);
      notification['success']({
        message: 'Store Work Approve',
        description: 'Successfully approved work',
      });
      history.push('/approvals/work_management');
    } catch (e) {
      notification['error']({
        message: 'Store Work Approve',
        description: 'An error occurred while processing this record, please try again later',
      });
    }
    setSubmitFormLoading(false);
  };

  const handleApproveCost = async () => {
    if (pageRole.canInputThirdPartyDetails) {
      try {
        const thirdPartyResult = await thirdPartySchema.validate(thirdPartyDetails, { abortEarly: false });
        console.log(thirdPartyResult);
      } catch (e) {
        console.log(e);
        const errors: { [key: string]: string } = {};
        e.inner.forEach((err: yup.ValidationError) => {
          errors[err.path] = err.message;
        });
        setThirdPartyErrors(errors);
        return;
      }
    }

    try {
      await store.userAccount.adminApproveCost(id, thirdPartyDetails);
      notification['success']({
        message: 'Engineering Admin Cost Approval',
        description: 'Successfully approved cost',
      });
      history.push('/approvals/costs');
    } catch (e) {
      notification['error']({
        message: 'Engineering Admin Cost Approval',
        description: 'An error occurred while processing this record, please try again later',
      });
    }
    setSubmitFormLoading(false);
  };

  return (
    <DashboardLayout>
      <Page>
        <form>
          <Breadcrumb className="mb-4">
            <Breadcrumb.Item>
              <Link to="/work_management">Work Management</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Item</Breadcrumb.Item>
          </Breadcrumb>
          {loading ? (
            <>
              <Card>
                <div className="card-body">
                  <Skeleton active />
                  <Skeleton active />
                </div>
              </Card>
            </>
          ) : (
            <div>
              <Card>
                <div className="card-body">
                  <div className="card-title d-flex justify-content-between">
                    <span>Service Request Details</span>

                    {maintenanceRequest &&
                    maintenanceRequest?.status >= config.maintenanceRequestTypeStatuses.FOR_STORE_APPROVAL ? (
                      <Button
                        size="small"
                        onClick={async () => {
                          if (maintenanceRequest) {
                            const response = await store.userAccount.downloadMRForm(
                              maintenanceRequest?.referenceNumber,
                            );
                            const blob = new Blob([response.data], {
                              type: 'application/pdf',
                            });
                            FileSaver.saveAs(blob, `service-request-${maintenanceRequest.referenceNumber}.pdf`);
                          }
                        }}
                        type="link"
                      >
                        Download Form
                      </Button>
                    ) : null}
                  </div>
                  <FormSection>
                    <FormRow marginBottom="10">
                      <div className="controls --inline">
                        <label className="label">Requestor name:</label>
                        <span className="value">{maintenanceRequest?.header.requestorName}</span>
                      </div>
                    </FormRow>
                    <FormRow marginBottom="10">
                      <div className="controls --inline">
                        <label className="label">Reference Number:</label>
                        <span className="value">{maintenanceRequest?.referenceNumber}</span>
                      </div>
                    </FormRow>
                    <FormRow marginBottom="10">
                      <div className="controls --inline">
                        <label className="label">Date applied:</label>
                        <span className="value">
                          {maintenanceRequest?.header.createdAt
                            ? moment(maintenanceRequest?.header.createdAt).format('YYYY-MM-DD')
                            : ''}
                        </span>
                      </div>
                    </FormRow>
                    <FormRow marginBottom="10">
                      <div className="controls --inline">
                        <label className="label">SLA:</label>
                        <span className="value">Priority {maintenanceRequest?.slaType}</span>
                      </div>
                    </FormRow>
                    <FormRow marginBottom="10">
                      <div className="controls --inline">
                        <label className="label">Status:</label>
                        <span className="value">
                          {maintenanceRequest ? (
                            <Tag color={config.maintenanceRequestTypeStatusLabels[maintenanceRequest?.status].color}>
                              {config.maintenanceRequestTypeStatusLabels[maintenanceRequest?.status].label}
                            </Tag>
                          ) : null}
                        </span>
                      </div>
                    </FormRow>
                    {maintenanceRequest && maintenanceRequest?.header.dateProcessedByApprover1 ? (
                      <FormRow marginBottom="10">
                        <div className="controls --inline">
                          <label className="label">Date Processed by ARL:</label>
                          <span className="value">
                            {moment(maintenanceRequest?.header.dateProcessedByApprover1).format('LLL')}
                          </span>
                        </div>
                      </FormRow>
                    ) : null}
                    {maintenanceRequest && maintenanceRequest?.header.dateProcessedByApprover2 ? (
                      <FormRow marginBottom="10">
                        <div className="controls --inline">
                          <label className="label">Date Processed by RDO:</label>
                          <span className="value">
                            {moment(maintenanceRequest?.header.dateProcessedByApprover2).format('LLL')}
                          </span>
                        </div>
                      </FormRow>
                    ) : null}
                    {maintenanceRequest && maintenanceRequest?.header.dateProcessedByApprover3 ? (
                      <FormRow marginBottom="10">
                        <div className="controls --inline">
                          <label className="label">Date Processed by Eng Supervisor:</label>
                          <span className="value">
                            {moment(maintenanceRequest?.header.dateProcessedByApprover3).format('LLL')}
                          </span>
                        </div>
                      </FormRow>
                    ) : null}
                    <FormRow marginBottom="10">
                      <div className="controls --inline">
                        <label className="label">Date of Service:</label>
                        <span className="value">
                          {maintenanceRequest?.dateOfService
                            ? moment(maintenanceRequest?.dateOfService).format('YYYY-MM-DD h:mm a')
                            : ''}
                        </span>
                      </div>
                    </FormRow>
                    <FormRow marginBottom="10">
                      <div className="controls">
                        <label className="label">Assigned Technician/s:</label>
                        {maintenanceRequest?.requestType === config.maintenanceRequestTypes.INHOUSE ? (
                          <ul className="value">
                            {maintenanceRequest?.technicians.map(technician => {
                              return (
                                <li key={technician.id}>
                                  {technician.firstName} {technician.lastName}
                                </li>
                              );
                            })}
                          </ul>
                        ) : null}
                        {maintenanceRequest?.requestType === config.maintenanceRequestTypes.VENDOR ? (
                          <ul className="value">
                            <li>{maintenanceRequest?.thirdPartyTechnician?.vendor.name}</li>
                          </ul>
                        ) : null}
                      </div>
                    </FormRow>
                  </FormSection>
                </div>
              </Card>

              <Card>
                <div className="card-body">
                  <div className="card-title">Assets</div>
                  {maintenanceRequest?.items.map((item, itemIndex) => {
                    const asset = item.asset;
                    return (
                      <AssetItemRowWrapper
                        key={asset.assetId}
                        style={{
                          padding: 0,
                          borderRadius: 5,
                          borderBottom: '1px solid #ccc',
                          marginBottom: 10,
                        }}
                      >
                        <div className="d-flex " style={{ background: 'rgba(0,0,0,0.05)', padding: 20 }}>
                          <div className="image-wrapper" style={{ width: 100, marginRight: 20 }}>
                            <img src={item.photo} style={{ width: '100%' }} />
                          </div>
                          <div style={{ flex: 1 }}>
                            <span className="asset-row title-row">
                              <div className="d-flex justify-content-between">
                                {asset.isGeneric ? (
                                  <Tooltip title="Asset Description">
                                    <span className="title">{asset.assetDescription}</span>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Asset No">
                                    <span className="title">{asset.assetTaggingNo}</span>
                                  </Tooltip>
                                )}
                                <Button onClick={() => handleInitiateChangeAsset(asset)} size="small" type="primary">
                                  Change asset
                                </Button>
                              </div>
                            </span>
                            <p className="asset-row">
                              <Tooltip title="Classification">
                                <span>
                                  <FaToolbox className="icon" />
                                  <span className="value">{asset.classification?.description}</span>
                                </span>
                              </Tooltip>
                            </p>
                            {!asset.isGeneric ? (
                              <p className="asset-row">
                                <Tooltip title="Description">
                                  <span>
                                    <FaTrademark className="icon" />
                                    <span className="value">{asset.assetDescription}</span>
                                  </span>
                                </Tooltip>
                                <Tooltip title="Brand">
                                  <span className="value">{asset.brand}</span>
                                </Tooltip>
                              </p>
                            ) : null}
                            <p className="asset-row">
                              <Tooltip title="cost">
                                <span>
                                  <FaMoneyBillWaveAlt className="icon" />
                                  <span className="value">N/A</span>
                                </span>
                              </Tooltip>
                            </p>
                          </div>
                        </div>
                        <FormSection className="p-4">
                          <FormRow marginBottom="10">
                            <div className="controls --inline">
                              <label className="label">Classification:</label>
                              <span className="value">
                                {config.mrServiceRequestItemClassificationLabels[item.classification]}
                              </span>
                            </div>
                          </FormRow>
                          <FormRow marginBottom="10">
                            <div className="controls">
                              <label className="label">Description:</label>
                              <p className="">{item.description}</p>
                            </div>
                          </FormRow>
                        </FormSection>
                      </AssetItemRowWrapper>
                    );
                  })}
                </div>
              </Card>

              <Card>
                <div className="card-body">
                  <div className="card-title d-flex flex-row justify-content-between">
                    <span>Store Work Remarks</span>
                    <Button onClick={() => setStoreWorkRemarksVisible(true)} type="link" size="small">
                      Add new remarks
                    </Button>
                  </div>
                  <p className="mb-4">
                    {`If you have issues or comments with regards to the assigned technician's work, please add it here
                    and we'll notify the Engineering Supervisor.`}
                  </p>
                  <div className="list">
                    <List size="small">
                      {maintenanceRequest?.storeRejectRemarks.length ? (
                        maintenanceRequest?.storeRejectRemarks.map(item => (
                          <List.Item key={item.id}>
                            <span>{item.remarks}</span>
                            <TimeAgo datetime={item.createdAt} />
                          </List.Item>
                        ))
                      ) : (
                        <EmptyMessage>
                          <span className="text"> No work remarks yet.</span>
                          <Button onClick={() => setStoreWorkRemarksVisible(true)} type="link" size="small">
                            Add new remarks
                          </Button>
                        </EmptyMessage>
                      )}
                    </List>
                  </div>
                </div>
              </Card>

              {pageRole.canTimekeeping ? (
                <Card>
                  <div className="card-body">
                    <div className="card-title">
                      <div className="d-flex justify-content-between">
                        <span>
                          <b className="mr-2"> Timekeeping</b>
                          <Button size="small" type="link" onClick={() => setTimekeepingBreakdownModalVisible(true)}>
                            View breakdown
                          </Button>
                        </span>
                        {pageRole.canTimekeeping ? (
                          maintenanceRequest?.timekeeping.length ? (
                            <Button
                              size="small"
                              type="link"
                              onClick={() => {
                                setSelectedTimekeeping(null);
                                setTimekeepingModalVisible(true);
                              }}
                            >
                              Create timekeeping
                            </Button>
                          ) : null
                        ) : null}
                      </div>
                    </div>
                    <TimekeepingTable
                      data={maintenanceRequest ? maintenanceRequest.timekeeping : []}
                      onCreateButtonClicked={() => setTimekeepingModalVisible(true)}
                      onEditTimekeepingClicked={handleEditTimekeeping}
                      onDeleteTimekeepingClicked={handleDeleteTimekeeping}
                      readonly={!pageRole.canTimekeeping}
                    />
                  </div>
                </Card>
              ) : null}

              {pageRole.canMileage ? (
                <Card>
                  <div className="card-body">
                    <div className="card-title">
                      <div className="d-flex justify-content-between">
                        <span>Mileage</span>
                        {maintenanceRequest?.mileages.length && pageRole.canMaterials ? (
                          <Button
                            size="small"
                            type="link"
                            onClick={() => {
                              setSelectedMileage(null);
                              setMileageModalVisible(true);
                            }}
                          >
                            Add mileage
                          </Button>
                        ) : null}
                      </div>
                    </div>
                    <MileageTable
                      data={maintenanceRequest ? maintenanceRequest.mileages : []}
                      onCreateButtonClicked={() => setMileageModalVisible(true)}
                      onEditMileageClicked={handleEditMileage}
                      onDeleteMileageClicked={handleDeleteMileage}
                      readonly={!pageRole.canMileage}
                    />
                  </div>
                </Card>
              ) : null}

              {pageRole.canMaterials ? (
                <Card>
                  <div className="card-body">
                    <div className="card-title">
                      <div className="d-flex justify-content-between">
                        <span>Materials Used</span>
                        {maintenanceRequest?.materials.length && pageRole.canMaterials ? (
                          <Button
                            size="small"
                            type="link"
                            onClick={() => {
                              setSelectedMaterialUsed(null);
                              setMaterialUsedModalVisible(true);
                            }}
                          >
                            Add material
                          </Button>
                        ) : null}
                      </div>
                    </div>
                    <MaterialsTable
                      data={maintenanceRequest ? maintenanceRequest.materials : []}
                      onCreateButtonClicked={() => setMaterialUsedModalVisible(true)}
                      onEditMaterialClicked={handleEditMaterialUsed}
                      onDeleteMaterialClicked={handleDeleteMaterialUsed}
                      readonly={!pageRole.canMaterials}
                    />
                  </div>
                </Card>
              ) : null}
            </div>
          )}
        </form>

        {pageRole.canApproveFinalCosting && typeof id !== 'undefined' ? (
          <>
            {pageRole.canViewComputation && (
              <ComputationBreakdown refreshCounter={computationRefreshCounter} referenceNumber={id} />
            )}
            {pageRole.canInputThirdPartyDetails && (
              <Card>
                <div className="card-body">
                  <div className="card-title">Third Party Vendor Details</div>
                  <FormSection>
                    <FormRow>
                      <label className="form-title">Total Cost</label>
                      <div className="controls --inline">
                        <InputNumber
                          value={thirdPartyDetails.cost}
                          onChange={(value: number | undefined) => {
                            setThirdPartyDetails({
                              ...thirdPartyDetails,
                              cost: value ?? 0,
                            });
                          }}
                        />
                      </div>
                      {thirdPartyErrors['cost'] ? (
                        <span className="error-message mt-2">{thirdPartyErrors['cost']}</span>
                      ) : null}
                    </FormRow>
                    <FormRow>
                      <label className="form-title">Work Done</label>
                      <div className="controls ">
                        <Input.TextArea
                          value={thirdPartyDetails.workDone}
                          rows={3}
                          onChange={e => {
                            setThirdPartyDetails({
                              ...thirdPartyDetails,
                              workDone: e.target.value,
                            });
                          }}
                        />
                      </div>
                      {thirdPartyErrors['workDone'] ? (
                        <span className="error-message mt-2">{thirdPartyErrors['workDone']}</span>
                      ) : null}
                    </FormRow>
                  </FormSection>
                </div>
              </Card>
            )}

            <Card>
              <div className="card-footer d-flex justify-content-end">
                <Popconfirm
                  placement="top"
                  title="Are you sure you want to approve this request"
                  onConfirm={() => handleApproveCost()}
                >
                  <Button loading={submitFormLoading} htmlType="submit" type="primary">
                    Approve and close request
                  </Button>
                </Popconfirm>
              </div>
            </Card>
          </>
        ) : null}

        {pageRole.canStoreApproveWork ? (
          <Card>
            <div className="card-footer d-flex justify-content-end">
              <Button loading={submitFormLoading} onClick={() => setStoreApproveWorkModalVisible(true)} type="primary">
                Approve work
              </Button>
            </div>
          </Card>
        ) : null}
      </Page>
      <TimekeepingBreakdownModal
        visible={timekeepingBreakdownModalVisible}
        referenceNumber={id}
        onCancel={() => setTimekeepingBreakdownModalVisible(false)}
      />
      <TimekeepingModal
        timekeeping={selectedTimekeeping}
        visible={timekeepingModalVisible}
        items={maintenanceRequest ? maintenanceRequest.items : []}
        technicians={maintenanceRequest ? maintenanceRequest.technicians : []}
        onSubmit={handleTimekeepingModalOk}
        onCancel={handleTimekeepingModalCancel}
      />
      <MileageModal
        mileage={selectedMileage}
        visible={mileageModalVisible}
        items={maintenanceRequest ? maintenanceRequest.items : []}
        onSubmit={handleMileageModalOk}
        onCancel={handleMileageModalCancel}
      />
      <MaterialUsedModal
        material={selectedMaterialUsed}
        materials={store.references.materials}
        items={maintenanceRequest ? maintenanceRequest.items : []}
        visible={materialUsedModalVisible}
        onSubmit={handleMaterialUsedModalOk}
        onCancel={handleMaterialUsedModalCancel}
      />
      <StoreRemarksModal
        visible={storeWorkRemarksVisible}
        onSubmit={values => handleSubmitStoreWorkRemarks(values)}
        onCancel={() => setStoreWorkRemarksVisible(false)}
      />
      <StoreApproveWorkModal
        technicians={maintenanceRequest ? maintenanceRequest.technicians : []}
        visible={
          maintenanceRequest
            ? maintenanceRequest.requestType === config.maintenanceRequestTypes.INHOUSE && storeApproveWorkModalVisible
            : false
        }
        onSubmit={values => handleStoreApproveWork(values)}
        onCancel={() => setStoreApproveWorkModalVisible(false)}
      />
      <StoreApproveThirdPartyWorkModal
        visible={
          maintenanceRequest
            ? maintenanceRequest.requestType === config.maintenanceRequestTypes.VENDOR && storeApproveWorkModalVisible
            : false
        }
        onSubmit={values => handleStoreApproveWork(values)}
        onCancel={() => setStoreApproveWorkModalVisible(false)}
      />

      <Modal
        title="Change Asset"
        visible={changeAssetModalVisible}
        onOk={handleChangeAsset}
        onCancel={(): void => setChangeAssetModalVisible(false)}
      >
        <Select
          style={{ width: 380 }}
          showSearch
          optionFilterProp="children"
          onChange={(value: string): void => setNewAsset(parseInt(value))}
        >
          {storeAssets.map(asset => {
            return (
              <Select.Option key={asset.id} value={asset.id}>
                {asset.isGeneric ? asset.assetDescription : `${asset.assetTaggingNo} - ${asset.assetDescription}`}
              </Select.Option>
            );
          })}
        </Select>
      </Modal>
    </DashboardLayout>
  );
});

export default show;
