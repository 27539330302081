import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import moment from 'moment';
import { Input, InputNumber, Modal } from 'antd';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';

import { FormSection, FormRow } from '../../../components';

import { StoreRemarksFormData } from '../../../types';

const schema = yup.object().shape({
  remarks: yup.string().required('Remarks is required'),
});

export interface StoreRemarksModalProps {
  visible: boolean;
  onSubmit?: (values: StoreRemarksFormData) => void;
  onCancel?: () => void;
}

const StoreRemarksModal: React.FC<StoreRemarksModalProps> = ({ visible, onSubmit, onCancel }) => {
  const { control, handleSubmit, errors, reset } = useForm<StoreRemarksFormData>({
    validationSchema: schema,
  });

  const [submitLoading, setSubmitLoading] = useState(false);

  const onInnerSubmit = (values: StoreRemarksFormData) => {
    reset();
    onSubmit && onSubmit(values);
  };

  const handleSubmitHof = () => {
    const h = handleSubmit(onInnerSubmit);
    return async () => {
      setSubmitLoading(true);
      setTimeout(async () => {
        await h();
        setSubmitLoading(false);
      }, 200);
    };
  };

  return (
    <Modal
      title="Work Remarks"
      okButtonProps={{ loading: submitLoading }}
      cancelButtonProps={{ loading: submitLoading }}
      onOk={handleSubmitHof()}
      onCancel={() => onCancel && onCancel()}
      visible={visible}
    >
      <form autoComplete="off">
        <FormSection>
          <FormRow className={errors['remarks'] ? 'has-error' : ''}>
            <label className="form-title">Remarks:</label>
            <Controller name="remarks" control={control} as={<Input.TextArea />} />
            {errors['remarks'] ? <span className="error-message mt-2">{errors['remarks'].message}</span> : null}
          </FormRow>
        </FormSection>
      </form>
    </Modal>
  );
};

StoreRemarksModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default StoreRemarksModal;
