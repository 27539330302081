import React from 'react';
import { Button, Table, Popconfirm } from 'antd';
import accounting from 'accounting-js';
import PropTypes from 'prop-types';

import { EmptyMessage } from '../../../components';
import { MaterialType, AssetType, MaintenanceRequestMaterialType } from '../../../types';

interface MaterialsTableProps {
  data: MaintenanceRequestMaterialType[];
  onCreateButtonClicked?: () => void;
  onEditMaterialClicked?: (materialUsed: MaintenanceRequestMaterialType) => void;
  onDeleteMaterialClicked?: (materialUsed: MaintenanceRequestMaterialType) => void;
  readonly?: boolean;
}

const MaterialsTable: React.FC<MaterialsTableProps> = ({
  data,
  readonly,
  onCreateButtonClicked,
  onEditMaterialClicked,
  onDeleteMaterialClicked,
}) => {
  const columns = [
    {
      title: 'Asset',
      dataIndex: 'asset',
      render: (asset: AssetType | null) =>
        asset ? `${asset.assetTaggingNo} - ${asset.assetDescription}` : 'not specified',
    },
    {
      title: 'Material',
      dataIndex: 'materialName',
      render: (materialName: string) => materialName,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (value: number) => value,
    },
    {
      title: 'Price per item',
      dataIndex: 'pricePerItem',
      render: (value: number) => accounting.formatMoney(value),
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render: function actions(id: string, materialUsed: MaintenanceRequestMaterialType) {
        return (
          <>
            <Button
              onClick={() => {
                onEditMaterialClicked && onEditMaterialClicked(materialUsed);
              }}
              type="link"
            >
              Edit
            </Button>
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => onDeleteMaterialClicked && onDeleteMaterialClicked(materialUsed)}
            >
              <Button type="link" danger>
                Delete
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  if (readonly) {
    columns.pop();
  }

  return data.length ? (
    <Table columns={columns} dataSource={data} size="small"></Table>
  ) : (
    <EmptyMessage>
      <img
        className="image"
        src="https://staging-repairs-and-maintenance-public.s3-ap-southeast-1.amazonaws.com/static/images/empty-items.png"
      />
      <span className="text"> You have no materials yet.</span>
      {onCreateButtonClicked && !readonly ? (
        <Button onClick={() => onCreateButtonClicked()} className="button" type="link">
          Add material
        </Button>
      ) : null}
    </EmptyMessage>
  );
};

MaterialsTable.propTypes = {
  data: PropTypes.any.isRequired,
  onCreateButtonClicked: PropTypes.func,
  onEditMaterialClicked: PropTypes.func,
  onDeleteMaterialClicked: PropTypes.func,
  readonly: PropTypes.bool,
};

MaterialsTable.defaultProps = {
  readonly: true,
};

export default MaterialsTable;
