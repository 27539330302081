import React from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const AssetsSection = styled.div`
  background: white;
  padding: 10px;

  .title {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .list {
    padding: 10px;
  }
`;
