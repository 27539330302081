import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 30px 0px;

  hr {
    opacity: 0.3;
  }
`;

export const HorizontalLine = () => (
  <Wrapper>
    <hr />
  </Wrapper>
);
