import styled, { css } from 'styled-components';

export const FormSection = styled.section<{ inline?: boolean }>`
  ${props =>
    props.inline &&
    css`
      display: flex;
      flex-direction: row;
    `};

  .title {
    font-size: 24px;
    /* font-weight: bold; */
    margin-bottom: 25px;
  }

  .description {
    display: block;
    margin-bottom: 30px;
    font-size: 12px;
  }
`;
