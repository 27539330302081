import styled from 'styled-components';

export const Page = styled.div`
  .page-title {
    display: block;
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: bold;

    .subtitle {
      margin-left: 10px;
      font-size: 14px;
    }
  }

  > .subtitle {
    font-size: 14px;
  }
`;
